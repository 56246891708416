import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import actions from "../../../ducks/actions";
import selectors from "../../../ducks/selectors";
import api from "../../../api";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Grid from "@material-ui/core/Grid";
import StepItem from "../StepItem";
import LinkedTourtleListItem from "../../TourtleListScreen/LinkedTourtleListItem";
import AddStepItems from "../AddStepItems";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, style) => {
  // https://github.com/atlassian/react-beautiful-dnd/issues/1927
  const overrideStyles = {};

  if (style.position === "fixed") {
    let yTransform = isDragging ? document.documentElement.scrollTop : 0;

    const currentTransformMatch =
      style &&
      style.transform &&
      style.transform.match(/translate\((-?[\d.]+)px,\s+(-?[\d.]+)px\)/);
    if (currentTransformMatch) {
      yTransform = yTransform + parseFloat(currentTransformMatch[2]);
    }

    overrideStyles.transform = `translate(0, ${yTransform}px)`;
  }

  return {
    ...style,
    ...overrideStyles,
  };
};

const getListStyle = isDraggingOver => ({});

const styles = theme => ({
  addIcon: {
    color: "#00cc99",
    float: "left",
  },
  addText: {
    fontWeight: 700,
  },
  addIconContainer: {
    border: "2px solid #DCDCDC",
    borderRadius: "10px",
  },
  addIconContainerDense: {
    border: "2px solid #DCDCDC",
    borderRadius: "10px",
    paddingTop: "3px",
    paddingBottom: "3px",
  },
  placeholder: {
    marginBottom: 7.5,
    "&:focus": {
      outline: "none",
    },
  },
  draggableItem: {
    marginBottom: "-7.5px",
  },
  outline: {
    "&:focus": {
      outline: "none",
    },
  },
});

class DraggableStepList extends Component {
  constructor(props) {
    super(props);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.state = {
      items: null,
      scroll: "",
      isDragging: false,
    };
  }

  componentDidMount() {
    this.setState({ items: this.props.tourtleSteps });
    window.addEventListener("scroll", this.handleScroll);
  }

  onBeforeDragStart() {
    window.getSelection().removeAllRanges();
  }

  onDragStart() {
    this.setState({ isDragging: true });

    if (window.navigator.vibrate) {
      window.navigator.vibrate(200);
    }
  }

  async onDragEnd(result) {
    const {
      onDragStep,
      tourtleItem,
      onMoveStepSuccess,
      onMoveStepFailure,
      finishMoveStep,
    } = this.props;

    this.setState({ isDragging: false });

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    await this.setState({
      items,
    });

    try {
      onDragStep(result.draggableId, result.destination.index);
      const object = { position: result.destination.index };
      const response = await api.moveStep(
        tourtleItem.id,
        result.draggableId,
        object
      );
      await onMoveStepSuccess(response);
      finishMoveStep(result.destination.index);
    } catch (error) {
      onMoveStepFailure(error);
    }
  }

  componentDidUpdate() {
    if (!_.isEqual(this.state.items, this.props.tourtleSteps)) {
      this.setState({ items: this.props.tourtleSteps });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (this.state.isDragging) {
      this.setState({ scroll: window.pageYOffset });
    }
  };

  render() {
    const { classes, isStatic, ...props } = this.props;

    return (
      <div
        className={
          props.tourtleSteps.length < 11 && props.tourtleSteps.length > 0
            ? "tourtle-extra-margin"
            : ""
        }
      >
        <Grid container spacing={8}>
          <Grid item xs={12}>
            {props.tourtleSteps.length > 1 &&
              (props.isMine || props.isCollaborator) && (
                <div className="tourtle-add-step-front">
                  <AddStepItems
                    inListPosition="top"
                    stepCount={props.tourtleSteps.length}
                    remainingStepCount={1}
                    addItem={() => this.props.addItem(true)}
                    addMultipleImages={() => this.props.addMultipleImages(true)}
                    addTourtleLink={() => this.props.addTourtleLink(true)}
                  />
                </div>
              )}
          </Grid>
        </Grid>
        {this.state.items && (
          <div className="tourtle-steps-container">
            <DragDropContext
              onDragStart={this.onDragStart}
              onDragEnd={this.onDragEnd}
              onBeforeDragStart={this.onBeforeDragStart}
            >
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    className={classes.outline}
                  >
                    {props.tourtleSteps.map((stepItem, index) => (
                      <Draggable
                        key={stepItem.id}
                        draggableId={stepItem.id}
                        index={index}
                        isDragDisabled={isStatic || false}
                        className={classes.outline}
                      >
                        {(provided, snapshot) => (
                          <div
                            className={classes.placeholder}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                            scroll={this.state.scroll}
                          >
                            <div className="tourtle-steps-container"></div>
                            {stepItem.type === "TOURTLE" ? (
                              <LinkedTourtleListItem
                                id={stepItem.id}
                                key={stepItem.id}
                                tourtle={stepItem}
                                favoriteTourtles={props.favoriteTourtles}
                                index={index}
                                onCoverPage={true}
                                handleGotoStep={() =>
                                  props.handleStepClick(stepItem.id)
                                }
                                isMine={props.isMine}
                                isCollaborator={props.isCollaborator}
                              />
                            ) : (
                              <StepItem
                                key={stepItem.id}
                                ref={props.refProp}
                                anchor={stepItem.title}
                                id={stepItem.id}
                                tourtleId={props.tourtleItem.id}
                                title={stepItem.title}
                                description={stepItem.description}
                                image={stepItem.image}
                                audio={stepItem.audio ? true : false}
                                video={stepItem.video ? true : false}
                                index={index}
                                isMine={props.isMine}
                                isSelected={stepItem.checkbox}
                                attachments={stepItem.attachments}
                                isCollaborator={props.isCollaborator}
                                handleGotoStep={() =>
                                  props.handleStepClick(stepItem.id)
                                }
                                handleSelectTourtle={() =>
                                  this.props.handleSelectTourtle
                                }
                              />
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  tourtleSteps: selectors.getTourtleSteps(state),
});

const mapDispatchToProps = {
  onDragStep: actions.onDragStep,
  onMoveStepFailure: actions.moveStepFailure,
  onMoveStepSuccess: actions.moveStepSuccess,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DraggableStepList))
);
