import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

// Material UI
import { Card, Grid, Typography, Tooltip } from "@material-ui/core";

import ListAltOutlined from "@material-ui/icons/ListAltOutlined";
import BurstModeOutlined from "@material-ui/icons/BurstModeOutlined";
import LinkOutlined from "@material-ui/icons/LinkOutlined";

import "./tourtle.css";

const styles = theme => ({
  addIcon: {
    color: "#00cc99",
    float: "left",
  },
  addText: {
    fontWeight: 700,
  },
  addIconContainer: {
    border: "2px solid #DCDCDC",
    borderRadius: "10px",
  },
});

class AddStepItems extends Component {
  render() {
    const { remainingStepCount, stepCount, inListPosition } = this.props;
    return (
      <div
        className={
          inListPosition === "bottom"
            ? "tourtle-add-step-container pt-0"
            : "tourtle-add-step-container-top pt-0"
        }
        id={inListPosition === "bottom" ? "new-step-card" : ""}
      >
        {/* {stepCount === 0 ? (
          <div className="tourtle-add-first-step">
            <ListItem
              button
              onClick={props.handleCreateStepOpening}
              classes={{
                root: classes.addIconContainer,
              }}
            >
              <ListItemIcon>
                <AddCircleIcon className={classes.addIcon} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.addText }}
                primary="Add your first item/step to this tourtle"
              />
            </ListItem>
          </div>
        ) : remainingStepCount === 0 ? null : (
          <div>
            <ListItem
              button
              onClick={props.onAddStepClick}
              classes={{
                root: classes.addIconContainer,
              }}
            >
              <ListItemIcon>
                <AddCircleIcon className={classes.addIcon} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.addText }}
                primary="Add new item"
              />
            </ListItem>
          </div>
        )} */}

        {remainingStepCount > 0 && (
          <div
            className={
              "tourtle-add-step-front px-0 " +
              (inListPosition === "top" ? "add-step-bottom" : "")
            }
          >
            {inListPosition === "bottom" && (
              <Typography className="floating-title">
                Add at bottom of list
              </Typography>
            )}
            {inListPosition === "top" && (
              <Typography className="floating-title">
                Add at top of list
              </Typography>
            )}
            <Card className="list-btns-wrap">
              <Grid container>
                <Tooltip title="Add a single new item" placement="top">
                  <Grid
                    item
                    xs={4}
                    className="list-btn"
                    onClick={() => this.props.addItem(true)}
                  >
                    <ListAltOutlined className="list-btn-icon" />
                    <Typography
                      className="list-btn-text"
                      color="textSecondary"
                      gutterBottom
                    >
                      {stepCount > 0 && "Add item"}
                      {Number(stepCount) === 0 && "Add 1st item"}
                    </Typography>
                  </Grid>
                </Tooltip>
                <Tooltip
                  title="Add multiple images as new items"
                  placement="top"
                >
                  <Grid
                    item
                    xs={4}
                    className="list-btn"
                    onClick={() => this.props.addMultipleImages(false)}
                  >
                    <BurstModeOutlined className="list-btn-icon" />
                    <Typography
                      className="list-btn-text"
                      color="textSecondary"
                      gutterBottom
                    >
                      Add images
                    </Typography>
                  </Grid>
                </Tooltip>
                <Tooltip
                  title="Add a link to a different tourtle"
                  placement="top"
                >
                  <Grid
                    item
                    xs={4}
                    className="list-btn"
                    onClick={() => this.props.addTourtleLink(false)}
                  >
                    <LinkOutlined className="list-btn-icon" />
                    <Typography
                      className="list-btn-text"
                      color="textSecondary"
                      gutterBottom
                    >
                      Link a tourtle
                    </Typography>
                  </Grid>
                </Tooltip>
              </Grid>
            </Card>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(AddStepItems);
