/* cSpell:ignore SIGNOUT, ctoken, UNFAVORITE, SNACKBARS */

import selectors from "./selectors";
import api from "../api";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

export const actionTypes = {
  // Left Nav
  LEFT_NAV_OPENING: "LEFT_NAV_OPENING",
  LEFT_NAV_CLOSING: "LEFT_NAV_CLOSING",

  // Get Home List
  HOME_LIST_FAILURE: "HOME_LIST_FAILURE",
  HOME_LIST_REQUEST: "HOME_LIST_REQUEST",
  HOME_LIST_SUCCESS: "HOME_LIST_SUCCESS",
  HOME_LIST_CLEAR: "HOME_LIST_CLEAR",

  // Get Trending List
  TRENDING_LIST_REQUEST: "TRENDING_LIST_REQUEST",
  TRENDING_LIST_SUCCESS: "TRENDING_LIST_SUCCESS",
  TRENDING_LIST_FAILURE: "TRENDING_LIST_FAILURE",

  // Get Subscriptions Tourtles List
  SUBS_TOURTLE_LIST_FAILURE: "SUBS_TOURTLE_LIST_FAILURE",
  SUBS_TOURTLE_LIST_REQUEST: "SUBS_TOURTLE_LIST_REQUEST",
  SUBS_TOURTLE_LIST_SUCCESS: "SUBS_TOURTLE_LIST_SUCCESS",

  // Get Subscriptions
  FETCH_SUBSCRIPTIONS_REQUEST: "FETCH_SUBSCRIPTIONS_REQUEST",
  FETCH_SUBSCRIPTIONS_SUCCESS: "FETCH_SUBSCRIPTIONS_SUCCESS",
  FETCH_SUBSCRIPTIONS_FAILURE: "FETCH_SUBSCRIPTIONS_FAILURE",

  // Get Author
  FETCH_AUTHOR_REQUEST: "FETCH_AUTHOR_REQUEST",
  FETCH_AUTHOR_SUCCESS: "FETCH_AUTHOR_SUCCESS",
  FETCH_AUTHOR_FAILURE: "FETCH_AUTHOR_FAILURE",

  // Subscribe to Author
  SUBSCRIBE_REQUEST: "SUBSCRIBE_REQUEST",
  SUBSCRIBE_SUCCESS: "SUBSCRIBE_SUCCESS",
  SUBSCRIBE_FAILURE: "SUBSCRIBE_FAILURE",

  // Unsubscribe to Author
  UNSUBSCRIBE_REQUEST: "UNSUBSCRIBE_REQUEST",
  UNSUBSCRIBE_SUCCESS: "UNSUBSCRIBE_SUCCESS",
  UNSUBSCRIBE_FAILURE: "UNSUBSCRIBE_FAILURE",

  // Get Author Tourtle List
  AUTHOR_TOURTLE_LIST_REQUEST: "AUTHOR_TOURTLE_LIST_REQUEST",
  AUTHOR_TOURTLE_LIST_SUCCESS: "AUTHOR_TOURTLE_LIST_SUCCESS",
  AUTHOR_TOURTLE_LIST_FAILURE: "AUTHOR_TOURTLE_LIST_FAILURE",

  // Clear Author Tourtle List
  CLEAR_AUTHOR_TOURTLE_LIST: "CLEAR_AUTHOR_TOURTLE_LIST",

  // Clear Author
  CLEAR_AUTHOR: "CLEAR_AUTHOR",

  // Get Current User Tourtle List
  CURRENT_USER_TOURTLE_LIST_REQUEST: "CURRENT_USER_TOURTLE_LIST_REQUEST",
  CURRENT_USER_TOURTLE_LIST_SUCCESS: "CURRENT_USER_TOURTLE_LIST_SUCCESS",
  CURRENT_USER_TOURTLE_LIST_FAILURE: "CURRENT_USER_TOURTLE_LIST_FAILURE",

  // Clear current user tourtle list
  CLEAR_CURRENT_USER_TOURTLE_LIST: "CLEAR_CURRENT_USER_TOURTLE_LIST",

  // Get Recently Visited Tourtle List
  RECENTLY_VISITED_TOURTLE_LIST_REQUEST:
    "RECENTLY_VISITED_TOURTLE_LIST_REQUEST",
  RECENTLY_VISITED_TOURTLE_LIST_SUCCESS:
    "RECENTLY_VISITED_TOURTLE_LIST_SUCCESS",
  RECENTLY_VISITED_TOURTLE_LIST_FAILURE:
    "RECENTLY_VISITED_TOURTLE_LIST_FAILURE",

  // Get Current User Collection List
  CURRENT_USER_COLLECTION_LIST_REQUEST: "CURRENT_USER_COLLECTION_LIST_REQUEST",
  CURRENT_USER_COLLECTION_LIST_SUCCESS: "CURRENT_USER_COLLECTION_LIST_SUCCESS",
  CURRENT_USER_COLLECTION_LIST_FAILURE: "CURRENT_USER_COLLECTION_LIST_FAILURE",

  // Favorite Unfavorite Tourtle
  FAVORITE_TOURTLE_REQUEST: "FAVORITE_TOURTLE_REQUEST",
  FAVORITE_TOURTLE_FAILURE: "FAVORITE_TOURTLE_FAILURE",
  FAVORITE_TOURTLE_SUCCESS: "FAVORITE_TOURTLE_SUCCESS",

  // React on tourtles
  REACT_ON_TOURTLE_REQUEST: "REACT_ON_TOURTLE_REQUEST",
  REACT_ON_TOURTLE_SUCCESS: "REACT_ON_TOURTLE_SUCCESS",
  REACT_ON_TOURTLE_FAILURE: "REACT_ON_TOURTLE_FAILURE",

  // Remove react on tourtle
  REMOVE_REACTION_ON_TOURTLE_REQUEST: "REMOVE_REACTION_ON_TOURTLE_REQUEST",
  REMOVE_REACTION_ON_TOURTLE_SUCCESS: "REMOVE_REACTION_ON_TOURTLE_SUCCESS",
  REMOVE_REACTION_ON_TOURTLE_FAILURE: "REMOVE_REACTION_ON_TOURTLE_FAILURE",

  // Get Current User Favorite List
  CURRENT_USER_FAVORITE_LIST_REQUEST: "CURRENT_USER_FAVORITE_LIST_REQUEST",
  CURRENT_USER_FAVORITE_LIST_FAILURE: "CURRENT_USER_FAVORITE_LIST_FAILURE",
  CURRENT_USER_FAVORITE_LIST_SUCCESS: "CURRENT_USER_FAVORITE_LIST_SUCCESS",

  // Delete Tourtle Dialog
  DELETE_TOURTLE_DIALOG_OPENING: "DELETE_TOURTLE_DIALOG_OPENING",
  DELETE_TOURTLE_DIALOG_CLOSING: "DELETE_TOURTLE_DIALOG_CLOSING",

  // Report Dialog
  REPORT_DIALOG_CLOSING: "REPORT_DIALOG_CLOSING",
  REPORT_DIALOG_OPENING: "REPORT_DIALOG_OPENING",

  // Report
  REPORT_REQUEST: "REPORT_REQUEST",
  REPORT_SUCCESS: "REPORT_SUCCESS",
  REPORT_FAILURE: "REPORT_FAILURE",

  // Snackbar opening/closing
  SNACKBARS_OPENING: "SNACKBARS_OPENING",
  SNACKBARS_CLOSING: "SNACKBARS_CLOSING",

  LOGIN_LINK_REQUEST_UNVERIFIED: "LOGIN_LINK_REQUEST_UNVERIFIED",

  // Add tourtle to groups
  ADD_TOURTLE_TO_GROUPS_REQUEST: "ADD_TOURTLE_TO_GROUPS_REQUEST",
  ADD_TOURTLE_TO_GROUPS_SUCCESS: "ADD_TOURTLE_TO_GROUPS_SUCCESS",
  ADD_TOURTLE_TO_GROUPS_FAILURE: "ADD_TOURTLE_TO_GROUPS_FAILURE",

  // Add tourtle to collections
  ADD_TOURTLE_TO_COLLECTIONS_REQUEST: "ADD_TOURTLE_TO_COLLECTIONS_REQUEST",
  ADD_TOURTLE_TO_COLLECTIONS_SUCCESS: "ADD_TOURTLE_TO_COLLECTIONS_SUCCESS",
  ADD_TOURTLE_TO_COLLECTIONS_FAILURE: "ADD_TOURTLE_TO_COLLECTIONS_FAILURE",

  // Search groups
  SEARCH_GROUP_LIST_ITEMS_REQUEST: "SEARCH_GROUP_LIST_ITEMS_REQUEST",
  SEARCH_GROUP_LIST_ITEMS_SUCCESS: "SEARCH_GROUP_LIST_ITEMS_SUCCESS",
  SEARCH_GROUP_LIST_ITEMS_FAILURE: "SEARCH_GROUP_LIST_ITEMS_FAILURE",
  CLEAR_GROUP_LIST_ITEMS_CTOKEN: "CLEAR_GROUP_LIST_ITEMS_CTOKEN",

  // Clear group list
  CLEAR_GROUP_LIST_ITEMS: "CLEAR_GROUP_LIST_ITEMS",

  // Open and close login dialog
  LOGIN_DIALOG_CLOSE: "LOGIN_DIALOG_CLOSE",
  LOGIN_DIALOG_OPEN: "LOGIN_DIALOG_OPEN",

  // Open and close login dialog
  SIGN_IN_UP_DIALOG_CLOSE: "SIGN_IN_UP_DIALOG_CLOSE",
  SIGN_IN_UP_DIALOG_OPEN: "SIGN_IN_UP_DIALOG_OPEN",

  // Open and close sign out dialog
  SIGNOUT_DIALOG_CLOSING: "SIGNOUT_DIALOG_CLOSING",
  SIGNOUT_DIALOG_OPENING: "SIGNOUT_DIALOG_OPENING",

  // Open and close add tourtle to collection dialog
  ADD_TOURTLE_TO_COLLECTION_DIALOG_CLOSING:
    "ADD_TOURTLE_TO_COLLECTION_DIALOG_CLOSING",
  ADD_TOURTLE_TO_COLLECTION_DIALOG_OPENING:
    "ADD_TOURTLE_TO_COLLECTION_DIALOG_OPENING",

  ADD_TOURTLE_TO_GROUP_DIALOG_CONFIRMATION_OPENING:
    "ADD_TOURTLE_TO_GROUP_DIALOG_CONFIRMATION_OPENING",
  ADD_TOURTLE_TO_GROUP_DIALOG_CONFIRMATION_CLOSING:
    "ADD_TOURTLE_TO_GROUP_DIALOG_CONFIRMATION_CLOSING",

  // Open and close add tourtle to group dialog
  ADD_TOURTLE_TO_GROUP_DIALOG_CLOSING: "ADD_TOURTLE_TO_GROUP_DIALOG_CLOSING",
  ADD_TOURTLE_TO_GROUP_DIALOG_OPENING: "ADD_TOURTLE_TO_GROUP_DIALOG_OPENING",
  ADD_TOURTLE_TO_GROUP_DIALOG_HIDING: "ADD_TOURTLE_TO_GROUP_DIALOG_HIDING",
  ADD_TOURTLE_TO_GROUP_DIALOG_SHOWING: "ADD_TOURTLE_TO_GROUP_DIALOG_SHOWING",

  // Open and close cookie dialog
  COOKIE_DIALOG_CLOSING: "COOKIE_DIALOG_CLOSING",
  COOKIE_DIALOG_OPENING: "COOKIE_DIALOG_OPENING",

  // Show and hide welcome tourtle
  SHOW_WELCOME_TOURTLE: "SHOW_WELCOME_TOURTLE",
  HIDE_WELCOME_TOURTLE: "HIDE_WELCOME_TOURTLE",

  // Dispatch interests
  DISPATCH_INITIAL_INTERESTS: "DISPATCH_INITIAL_INTERESTS",
  DISPATCH_USER_INTEREST: "DISPATCH_USER_INTEREST",
  DISPATCH_FILTER_INTEREST: "DISPATCH_FILTER_INTEREST",

  // Update user profile
  UPDATE_PROFILE_REQUEST: "UPDATE_PROFILE_REQUEST",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILURE: "UPDATE_PROFILE_FAILURE",

  // Add and remove all user interests
  SELECT_ALL_USER_INTERESTS: "SELECT_ALL_USER_INTERESTS",
  REMOVE_ALL_USER_INTERESTS: "REMOVE_ALL_USER_INTERESTS",

  // Add and remove all filter interests
  SELECT_ALL_INTERESTS: "SELECT_ALL_INTERESTS",
  REMOVE_ALL_INTERESTS: "REMOVE_ALL_INTERESTS",

  // Open and close warning dialog
  WARNING_DIALOG_OPENING: "WARNING_DIALOG_OPENING",
  WARNING_DIALOG_CLOSING: "WARNING_DIALOG_CLOSING",

  // Open and close warning dialog
  UNSAVED_CHANGES_DIALOG_OPENING: "UNSAVED_CHANGES_DIALOG_OPENING",
  UNSAVED_CHANGES_DIALOG_CLOSING: "UNSAVED_CHANGES_DIALOG_CLOSING",

  // Open and close location dialog
  LOCATION_SERVICE_DIALOG_CLOSING: "LOCATION_SERVICE_DIALOG_CLOSING",
  LOCATION_SERVICE_DIALOG_OPENING: "LOCATION_SERVICE_DIALOG_OPENING",

  // Update notification settings
  UPDATE_NOTIFICATIONS_REQUEST: "UPDATE_NOTIFICATIONS_REQUEST",
  UPDATE_NOTIFICATIONS_SUCCESS: "UPDATE_NOTIFICATIONS_SUCCESS",
  UPDATE_NOTIFICATIONS_FAILURE: "UPDATE_NOTIFICATIONS_FAILURE",

  // Update auth expiration date
  UPDATE_AUTH_EXPIRATION_REQUEST: "UPDATE_AUTH_EXPIRATION_REQUEST",
  UPDATE_AUTH_EXPIRATION_SUCCESS: "UPDATE_AUTH_EXPIRATION_SUCCESS",
  UPDATE_AUTH_EXPIRATION_FAILURE: "UPDATE_AUTH_EXPIRATION_FAILURE",

  // Update show landing page
  UPDATE_SHOW_LANDING_PAGE_REQUEST: "UPDATE_SHOW_LANDING_PAGE_REQUEST",
  UPDATE_SHOW_LANDING_PAGE_SUCCESS: "UPDATE_SHOW_LANDING_PAGE_SUCCESS",
  UPDATE_SHOW_LANDING_PAGE_FAILURE: "UPDATE_SHOW_LANDING_PAGE_FAILURE",

  // Set verification preferences
  SET_PREFERENCE_EMAIL: "SET_PREFERENCE_EMAIL",
  SET_PREFERENCE_PHONE: "SET_PREFERENCE_PHONE",

  // Forget me
  FORGET_ME_FAILURE: "FORGET_ME_FAILURE",
  FORGET_ME_REQUEST: "FORGET_ME_REQUEST",

  // Fetch interests list
  INTERESTS_LIST_REQUEST: "INTERESTS_LIST_REQUEST",
  INTERESTS_LIST_FAILURE: "INTERESTS_LIST_FAILURE",
  INTERESTS_LIST_SUCCESS: "INTERESTS_LIST_SUCCESS",

  // Fetch interests author list
  INTERESTS_AUTHOR_LIST_REQUEST: "INTERESTS_AUTHOR_LIST_REQUEST",
  INTERESTS_AUTHOR_LIST_FAILURE: "INTERESTS_AUTHOR_LIST_FAILURE",
  INTERESTS_AUTHOR_LIST_SUCCESS: "INTERESTS_AUTHOR_LIST_SUCCESS",

  // Clear interest lists
  CLEAR_INTERESTS_LIST: "CLEAR_INTERESTS_LIST",
  CLEAR_INTERESTS_AUTHOR_LIST: "CLEAR_INTERESTS_AUTHOR_LIST",

  // Fetch tourtle item
  TOURTLE_ITEM_REQUEST: "TOURTLE_ITEM_REQUEST",
  TOURTLE_ITEM_SUCCESS: "TOURTLE_ITEM_SUCCESS",
  TOURTLE_ITEM_FAILURE: "TOURTLE_ITEM_FAILURE",

  // Clear tourtle item
  TOURTLE_ITEM_CLEAR: "TOURTLE_ITEM_CLEAR",

  // Open and close jump to step dialog
  JUMP_TO_STEP_DIALOG_OPENING: "JUMP_TO_STEP_DIALOG_OPENING",
  JUMP_TO_STEP_DIALOG_CLOSING: "JUMP_TO_STEP_DIALOG_CLOSING",

  // Open and close image gallery
  IMAGE_GALLERY_OPENING: "IMAGE_GALLERY_OPENING",
  IMAGE_GALLERY_CLOSING: "IMAGE_GALLERY_CLOSING",

  // Get comments for tourtle
  COMMENTS_FAILURE: "COMMENTS_FAILURE",
  COMMENTS_REQUEST: "COMMENTS_REQUEST",
  COMMENTS_SUCCESS: "COMMENTS_SUCCESS",

  // Add and remove linked index
  ADD_LINKED_TOURTLE_INDEX: "ADD_LINKED_TOURTLE_INDEX",
  ADD_LINKED_TOURTLE_INDEX_COVER: "ADD_LINKED_TOURTLE_INDEX_COVER",
  REMOVE_LINKED_TOURTLE_INDEX: "REMOVE_LINKED_TOURTLE_INDEX",

  // Open and close add step dialog
  ADD_STEP_DIALOG_CLOSING: "ADD_STEP_DIALOG_CLOSING",
  ADD_STEP_DIALOG_OPENING: "ADD_STEP_DIALOG_OPENING",

  // Open and close add member menu
  ADD_MEMBER_MENU_CLOSING: "ADD_MEMBER_MENU_CLOSING",
  ADD_MEMBER_MENU_OPENING: "ADD_MEMBER_MENU_OPENING",

  ADD_VIEWERS_OPENING: "ADD_VIEWERS_OPENING",
  ADD_VIEWERS_CLOSING: "ADD_VIEWERS_CLOSING",

  // Set current step index
  SET_CURRENT_STEP_INDEX: "SET_CURRENT_STEP_INDEX",
  FINISH_STEP_ADD: "FINISH_STEP_ADD",
  RESET_CURRENT_STEP_INDEX: "RESET_CURRENT_STEP_INDEX",

  // Store cover page top offset
  SET_COVER_PAGE_TOP_OFFSET: "SET_COVER_PAGE_TOP_OFFSET",

  // Set active tourtle id
  SET_ACTIVE_TOURTLE_ID: "SET_ACTIVE_TOURTLE_ID",

  // Logout current user
  LOGOUT_CURRENT_USER_REQUEST: "LOGOUT_CURRENT_USER_REQUEST",
  LOGOUT_CURRENT_USER_SUCCESS: "LOGOUT_CURRENT_USER_SUCCESS",
  LOGOUT_CURRENT_USER_FAILURE: "LOGOUT_CURRENT_USER_FAILURE",

  // Add step from cover page
  ADD_STEP_INSIDE_TOURTLE_ON: "ADD_STEP_INSIDE_TOURTLE_ON",
  ADD_STEP_INSIDE_TOURTLE_OFF: "ADD_STEP_INSIDE_TOURTLE_OFF",

  EDIT_STEP_INSIDE_TOURTLE_ON: "EDIT_STEP_INSIDE_TOURTLE_ON",
  EDIT_STEP_INSIDE_TOURTLE_OFF: "EDIT_STEP_INSIDE_TOURTLE_OFF",

  // Add ref item
  ADD_REFERENCE: "ADD_REFERENCE",
  REMOVE_REFERENCE: "REMOVE_REFERENCE",

  // Cancel adding step
  CANCEL_ADDING_STEP: "CANCEL_ADDING_STEP",

  // Clear step counter
  CLEAR_STEP_COUNTER: "CLEAR_STEP_COUNTER",

  // Open and close delete step dialog
  DELETE_STEP_DIALOG_OPENING: "DELETE_STEP_DIALOG_OPENING",
  DELETE_STEP_DIALOG_CLOSING: "DELETE_STEP_DIALOG_CLOSING",

  // Open and close duplicate step dialog
  DUPLICATE_STEP_DIALOG_OPENING: "DUPLICATE_STEP_DIALOG_OPENING",
  DUPLICATE_STEP_DIALOG_CLOSING: "DUPLICATE_STEP_DIALOG_CLOSING",

  // Duplicate step
  DUPLICATE_STEP_FAILURE: "DUPLICATE_STEP_FAILURE",
  DUPLICATE_STEP_REQUEST: "DUPLICATE_STEP_REQUEST",
  DUPLICATE_STEP_SUCCESS: "DUPLICATE_STEP_SUCCESS",

  // Open and close move step
  MOVE_STEP_DIALOG_OPENING: "MOVE_STEP_DIALOG_OPENING",
  MOVE_STEP_DIALOG_CLOSING: "MOVE_STEP_DIALOG_CLOSING",

  // Move step
  DRAG_STEP: "DRAG_STEP",
  MOVE_STEP_FAILURE: "MOVE_STEP_FAILURE",
  MOVE_STEP_REQUEST: "MOVE_STEP_REQUEST",
  MOVE_STEP_SUCCESS: "MOVE_STEP_SUCCESS",

  // Update tourtle
  UPDATE_TOURTLE_FAILURE: "UPDATE_TOURTLE_FAILURE",
  UPDATE_TOURTLE_REQUEST: "UPDATE_TOURTLE_REQUEST",
  UPDATE_TOURTLE_SUCCESS: "UPDATE_TOURTLE_SUCCESS",

  // Add tourtle
  CREATE_TOURTLE_FAILURE: "CREATE_TOURTLE_FAILURE",
  CREATE_TOURTLE_REQUEST: "CREATE_TOURTLE_REQUEST",
  CREATE_TOURTLE_SUCCESS: "CREATE_TOURTLE_SUCCESS",

  // Add step
  CREATE_STEP_FAILURE: "CREATE_STEP_FAILURE",
  CREATE_STEP_REQUEST: "CREATE_STEP_REQUEST",
  CREATE_STEP_AFTER_SUCCESS: "CREATE_STEP_AFTER_SUCCESS",

  // Open and close content moderation dialog
  OPEN_CONTENT_MODERATION_DIALOG: "OPEN_CONTENT_MODERATION_DIALOG",
  CLOSE_CONTENT_MODERATION_DIALOG: "CLOSE_CONTENT_MODERATION_DIALOG",

  // Update step
  UPDATE_STEP_REQUEST: "UPDATE_STEP_REQUEST",
  UPDATE_STEP_SUCCESS: "UPDATE_STEP_SUCCESS",
  UPDATE_STEP_FAILURE: "UPDATE_STEP_FAILURE",
  UPDATE_STEP_IS_TEAPOT: "UPDATE_STEP_IS_TEAPOT",

  // Set tabbed list scroll position
  SET_TABBED_LIST_SCROLL_POSITION: "SET_TABBED_LIST_SCROLL_POSITION",
  RESET_TABBED_LIST_SCROLL_POSITION: "RESET_TABBED_LIST_SCROLL_POSITION",

  // Fetch welcome tourtle
  FETCH_WELCOME_TOURTLE: "FETCH_WELCOME_TOURTLE",
  FETCH_WELCOME_TOURTLE_REQUEST: "FETCH_WELCOME_TOURTLE_REQUEST",
  FETCH_WELCOME_TOURTLE_FAILURE: "FETCH_WELCOME_TOURTLE_FAILURE",

  // Open and close search dialog
  SEARCH_DIALOG_CLOSING: "SEARCH_DIALOG_CLOSING",
  SEARCH_DIALOG_OPENING: "SEARCH_DIALOG_OPENING",

  // Fetch search list
  SEARCH_LIST_FAILURE: "SEARCH_LIST_FAILURE",
  SEARCH_LIST_REQUEST: "SEARCH_LIST_REQUEST",
  SEARCH_LIST_SUCCESS: "SEARCH_LIST_SUCCESS",

  // Open and close filter dialog
  FILTER_DIALOG_CLOSING: "FILTER_DIALOG_CLOSING",
  FILTER_DIALOG_OPENING: "FILTER_DIALOG_OPENING",

  // Reset search filters
  RESET_SEARCH_FILTERS: "RESET_SEARCH_FILTERS",

  // Fetch group item
  GROUP_ITEM_FAILURE: "GROUP_ITEM_FAILURE",
  GROUP_ITEM_REQUEST: "GROUP_ITEM_REQUEST",
  GROUP_ITEM_SUCCESS: "GROUP_ITEM_SUCCESS",

  // Create group
  CREATE_GROUP_FAILURE: "CREATE_GROUP_FAILURE",
  CREATE_GROUP_REQUEST: "CREATE_GROUP_REQUEST",
  CREATE_GROUP_SUCCESS: "CREATE_GROUP_SUCCESS",

  // Update group
  UPDATE_GROUP_FAILURE: "UPDATE_GROUP_FAILURE",
  UPDATE_GROUP_REQUEST: "UPDATE_GROUP_REQUEST",
  UPDATE_GROUP_SUCCESS: "UPDATE_GROUP_SUCCESS",

  // Update group visibility
  UPDATE_GROUP_VISIBILITY: "UPDATE_GROUP_VISIBILITY",
  CLOSE_GROUP_VISIBILITY: "CLOSE_GROUP_VISIBILITY",

  // Fetch group tourtle list
  GROUP_TOURTLE_LIST_FAILURE: "GROUP_TOURTLE_LIST_FAILURE",
  GROUP_TOURTLE_LIST_REQUEST: "GROUP_TOURTLE_LIST_REQUEST",
  GROUP_TOURTLE_LIST_SUCCESS: "GROUP_TOURTLE_LIST_SUCCESS",

  // Position change
  CHANGE_GROUP_TOURTLE_POSITION_REQUEST:
    "CHANGE_GROUP_TOURTLE_POSITION_REQUEST",
  CHANGE_GROUP_TOURTLE_POSITION_SUCCESS:
    "CHANGE_GROUP_TOURTLE_POSITION_SUCCESS",
  CHANGE_GROUP_TOURTLE_POSITION_FAILURE:
    "CHANGE_GROUP_TOURTLE_POSITION_FAILURE",

  // Group list query
  SET_GROUP_TOURTLE_LIST_QUERY: "SET_GROUP_TOURTLE_LIST_QUERY",
  CLEAR_GROUP_TOURTLE_LIST_QUERY: "CLEAR_GROUP_TOURTLE_LIST_QUERY",

  // Join group
  JOIN_GROUP_FAILURE: "JOIN_GROUP_FAILURE",
  JOIN_GROUP_REQUEST: "JOIN_GROUP_REQUEST",
  JOIN_GROUP_SUCCESS: "JOIN_GROUP_SUCCESS",

  // Leave group
  LEAVE_GROUP_FAILURE: "LEAVE_GROUP_FAILURE",
  LEAVE_GROUP_REQUEST: "LEAVE_GROUP_REQUEST",
  LEAVE_GROUP_SUCCESS: "LEAVE_GROUP_SUCCESS",

  // Favorite group
  FAVORITE_GROUP_FAILURE: "FAVORITE_GROUP_FAILURE",
  FAVORITE_GROUP_REQUEST: "FAVORITE_GROUP_REQUEST",
  FAVORITE_GROUP_SUCCESS: "FAVORITE_GROUP_SUCCESS",

  // Unfavorite group
  UNFAVORITE_GROUP_FAILURE: "UNFAVORITE_GROUP_FAILURE",
  UNFAVORITE_GROUP_REQUEST: "UNFAVORITE_GROUP_REQUEST",
  UNFAVORITE_GROUP_SUCCESS: "UNFAVORITE_GROUP_SUCCESS",

  // Toggle group notifications
  TOGGLE_GROUP_NOTIFICATIONS: "TOGGLE_GROUP_NOTIFICATIONS",

  // Open and close invitation dialog
  INVITATION_DIALOG_OPENING: "INVITATION_DIALOG_OPENING",
  INVITATION_DIALOG_CLOSING: "INVITATION_DIALOG_CLOSING",

  // Open and close delete group dialog
  DELETE_GROUP_DIALOG_OPENING: "DELETE_GROUP_DIALOG_OPENING",
  DELETE_GROUP_DIALOG_CLOSING: "DELETE_GROUP_DIALOG_CLOSING",

  // Open and close last admin dialog
  LAST_ADMIN_DIALOG_CLOSING: "LAST_ADMIN_DIALOG_CLOSING",
  LAST_ADMIN_DIALOG_OPENING: "LAST_ADMIN_DIALOG_OPENING",

  // Open and close leave group dialog
  LEAVE_GROUP_DIALOG_OPENING: "LEAVE_GROUP_DIALOG_OPENING",
  LEAVE_GROUP_DIALOG_CLOSING: "LEAVE_GROUP_DIALOG_CLOSING",

  // Open and close remove tourtle from group dialog
  REMOVE_TOURTLE_FROM_GROUP_DIALOG_CLOSING:
    "REMOVE_TOURTLE_FROM_GROUP_DIALOG_CLOSING",
  REMOVE_TOURTLE_FROM_GROUP_DIALOG_OPENING:
    "REMOVE_TOURTLE_FROM_GROUP_DIALOG_OPENING",

  // Fetch group members
  FETCH_GROUP_MEMBER_FAILURE: "FETCH_GROUP_MEMBER_FAILURE",
  FETCH_GROUP_MEMBER_REQUEST: "FETCH_GROUP_MEMBER_REQUEST",
  FETCH_GROUP_MEMBER_SUCCESS: "FETCH_GROUP_MEMBER_SUCCESS",

  // Remove group member
  REMOVE_MEMBER_FAILURE: "REMOVE_MEMBER_FAILURE",
  REMOVE_MEMBER_REQUEST: "REMOVE_MEMBER_REQUEST",
  REMOVE_MEMBER_SUCCESS: "REMOVE_MEMBER_SUCCESS",

  // Change group member
  CHANGE_GROUP_MEMBER_FAILURE: "CHANGE_GROUP_MEMBER_FAILURE",
  CHANGE_GROUP_MEMBER_REQUEST: "CHANGE_GROUP_MEMBER_REQUEST",
  CHANGE_GROUP_MEMBER_SUCCESS: "CHANGE_GROUP_MEMBER_SUCCESS",

  // Set active group id
  SET_ACTIVE_GROUP_ID: "SET_ACTIVE_GROUP_ID",

  // Open and close revoke invitation dialog
  REVOKE_INVITATION_DIALOG_OPENING: "REVOKE_INVITATION_DIALOG_OPENING",
  REVOKE_INVITATION_DIALOG_CLOSING: "REVOKE_INVITATION_DIALOG_CLOSING",

  // Fetch group invitations
  FETCH_GROUP_INVITATIONS_FAILURE: "FETCH_GROUP_INVITATIONS_FAILURE",
  FETCH_GROUP_INVITATIONS_REQUEST: "FETCH_GROUP_INVITATIONS_REQUEST",
  FETCH_GROUP_INVITATIONS_SUCCESS: "FETCH_GROUP_INVITATIONS_SUCCESS",

  // Accept request
  ACCEPT_REQUEST_FAILURE: "ACCEPT_REQUEST_FAILURE",
  ACCEPT_REQUEST_REQUEST: "ACCEPT_REQUEST_REQUEST",
  ACCEPT_REQUEST_SUCCESS: "ACCEPT_REQUEST_SUCCESS",

  // Reject request
  REJECT_REQUEST_FAILURE: "REJECT_REQUEST_FAILURE",
  REJECT_REQUEST_REQUEST: "REJECT_REQUEST_REQUEST",
  REJECT_REQUEST_SUCCESS: "REJECT_REQUEST_SUCCESS",

  // Accept tourtle
  ACCEPT_TOURTLE_REQUEST_FAILURE: "ACCEPT_TOURTLE_REQUEST_FAILURE",
  ACCEPT_TOURTLE_REQUEST_REQUEST: "ACCEPT_TOURTLE_REQUEST_REQUEST",
  ACCEPT_TOURTLE_REQUEST_SUCCESS: "ACCEPT_TOURTLE_REQUEST_SUCCESS",

  // Reject tourtle
  REJECT_TOURTLE_REQUEST_FAILURE: "REJECT_TOURTLE_REQUEST_FAILURE",
  REJECT_TOURTLE_REQUEST_REQUEST: "REJECT_TOURTLE_REQUEST_REQUEST",
  REJECT_TOURTLE_REQUEST_SUCCESS: "REJECT_TOURTLE_REQUEST_SUCCESS",

  // Fetch pending tourtles
  FETCH_PENDING_TOURTLE_REQUEST: "FETCH_PENDING_TOURTLE_REQUEST",
  FETCH_PENDING_TOURTLE_FAILURE: "FETCH_PENDING_TOURTLE_FAILURE",
  FETCH_PENDING_TOURTLE_SUCCESS: "FETCH_PENDING_TOURTLE_SUCCESS",

  // Move tourtle dialog
  MOVE_TOURTLE_DIALOG_CLOSING: "MOVE_TOURTLE_DIALOG_CLOSING",
  MOVE_TOURTLE_DIALOG_OPENING: "MOVE_TOURTLE_DIALOG_OPENING",

  // Open and close delete collection
  DELETE_COLLECTION_DIALOG_CLOSING: "DELETE_COLLECTION_DIALOG_CLOSING",
  DELETE_COLLECTION_DIALOG_OPENING: "DELETE_COLLECTION_DIALOG_OPENING",

  // Delete collection
  DELETE_COLLECTION_FAILURE: "DELETE_COLLECTION_FAILURE",
  DELETE_COLLECTION_REQUEST: "DELETE_COLLECTION_REQUEST",
  DELETE_COLLECTION_SUCCESS: "DELETE_COLLECTION_SUCCESS",

  // Fetch collection item
  COLLECTION_ITEM_REQUEST: "COLLECTION_ITEM_REQUEST",
  COLLECTION_ITEM_SUCCESS: "COLLECTION_ITEM_SUCCESS",
  COLLECTION_ITEM_FAILURE: "COLLECTION_ITEM_FAILURE",

  // Fetch tourtles for collection
  TOURTLES_FOR_COLLECTION_FAILURE: "TOURTLES_FOR_COLLECTION_FAILURE",
  TOURTLES_FOR_COLLECTION_REQUEST: "TOURTLES_FOR_COLLECTION_REQUEST",
  TOURTLES_FOR_COLLECTION_SUCCESS: "TOURTLES_FOR_COLLECTION_SUCCESS",

  // Update collection list item
  UPDATE_COLLECTION_AFTER_SUCCESS: "UPDATE_COLLECTION_AFTER_SUCCESS",

  // Position change
  CHANGE_TOURTLE_POSITION_REQUEST: "CHANGE_TOURTLE_POSITION_REQUEST",
  CHANGE_TOURTLE_POSITION_SUCCESS: "CHANGE_TOURTLE_POSITION_SUCCESS",
  CHANGE_TOURTLE_POSITION_FAILURE: "CHANGE_TOURTLE_POSITION_FAILURE",

  // Clear collection state
  CLEAR_COLLECTION_STATE: "CLEAR_COLLECTION_STATE",

  // Add tourtles to collection
  ADD_TOURTLE_TO_COLLECTION_REQUEST: "ADD_TOURTLE_TO_COLLECTION_REQUEST",
  ADD_TOURTLE_TO_COLLECTION_SUCCESS: "ADD_TOURTLE_TO_COLLECTION_SUCCESS",
  ADD_TOURTLE_TO_COLLECTION_FAILURE: "ADD_TOURTLE_TO_COLLECTION_FAILURE",

  // Remove tourtles from collection
  REMOVE_TOURTLE_TO_COLLECTION_REQUEST: "REMOVE_TOURTLE_TO_COLLECTION_REQUEST",
  REMOVE_TOURTLE_TO_COLLECTION_SUCCESS: "REMOVE_TOURTLE_TO_COLLECTION_SUCCESS",
  REMOVE_TOURTLE_TO_COLLECTION_FAILURE: "REMOVE_TOURTLE_TO_COLLECTION_FAILURE",

  // Update collection
  UPDATE_COLLECTION_FAILURE: "UPDATE_COLLECTION_FAILURE",
  UPDATE_COLLECTION_REQUEST: "UPDATE_COLLECTION_REQUEST",
  UPDATE_COLLECTION_SUCCESS: "UPDATE_COLLECTION_SUCCESS",

  // Position collection
  CHANGE_COLLECTION_POSITION_REQUEST: "CHANGE_COLLECTION_POSITION_REQUEST",
  CHANGE_COLLECTION_POSITION_SUCCESS: "CHANGE_COLLECTION_POSITION_SUCCESS",
  CHANGE_COLLECTION_POSITION_FAILURE: "CHANGE_COLLECTION_POSITION_FAILURE",
  REORDER_COLLECTION_SUCCESS: "REORDER_COLLECTION_SUCCESS",

  // Clear list search
  SEARCH_CLEAR_LIST: "SEARCH_CLEAR_LIST",

  // Set search query
  SET_SEARCH_QUERY: "SET_SEARCH_QUERY",
  SET_SEARCH_QUERY_GROUPS: "SET_SEARCH_QUERY_GROUPS",
  // Initiate clear collection state
  INITIATE_CLEAR_COLLECTION_STATE: "INITIATE_CLEAR_COLLECTION_STATE",

  // Clear selected tourtle list
  CLEAR_SELECTED_TOURTLE_LIST: "CLEAR_SELECTED_TOURTLE_LIST",

  // Fetch collaborators
  FETCH_COLLABORATORS_FAILURE: "FETCH_COLLABORATORS_FAILURE",
  FETCH_COLLABORATORS_REQUEST: "FETCH_COLLABORATORS_REQUEST",
  FETCH_COLLABORATORS_SUCCESS: "FETCH_COLLABORATORS_SUCCESS",

  // Remove collaborators
  REMOVE_COLLABORATOR_FAILURE: "REMOVE_COLLABORATOR_FAILURE",
  REMOVE_COLLABORATOR_REQUEST: "REMOVE_COLLABORATOR_REQUEST",
  REMOVE_COLLABORATOR_SUCCESS: "REMOVE_COLLABORATOR_SUCCESS",

  // Link tourtle as step
  LINK_TOURTLE_TO_TOURTLE_REQUEST: "LINK_TOURTLE_TO_TOURTLE_REQUEST",
  LINK_TOURTLE_TO_TOURTLE_SUCCESS: "LINK_TOURTLE_TO_TOURTLE_SUCCESS",
  LINK_TOURTLE_TO_TOURTLE_FAILURE: "LINK_TOURTLE_TO_TOURTLE_FAILURE",

  // Upload Image
  UPLOAD_IMAGE_FAILURE: "UPLOAD_IMAGE_FAILURE",
  UPLOAD_IMAGE_REQUEST: "UPLOAD_IMAGE_REQUEST",
  UPLOAD_IMAGE_SUCCESS: "UPLOAD_IMAGE_SUCCESS",

  // Increase used storage
  INCREASE_USED_STORAGE: "INCREASE_USED_STORAGE",

  // Share dialog
  SHARE_DIALOG_CLOSE: "SHARE_DIALOG_CLOSE",
  SHARE_DIALOG_OPEN: "SHARE_DIALOG_OPEN",

  STEP_OPENING: "STEP_OPENING",

  DELETE_IMAGE_DIALOG_OPENING: "DELETE_IMAGE_DIALOG_OPENING",
  DELETE_IMAGE_DIALOG_CLOSING: "DELETE_IMAGE_DIALOG_CLOSING",

  DELETE_AUDIO_DIALOG_OPENING: "DELETE_AUDIO_DIALOG_OPENING",
  DELETE_AUDIO_DIALOG_CLOSING: "DELETE_AUDIO_DIALOG_CLOSING",

  DELETE_VIDEO_DIALOG_OPENING: "DELETE_VIDEO_DIALOG_OPENING",
  DELETE_VIDEO_DIALOG_CLOSING: "DELETE_VIDEO_DIALOG_CLOSING",

  REMOVE_LINKED_TOURTLE_DIALOG_OPENING: "REMOVE_LINKED_TOURTLE_DIALOG_OPENING",
  REMOVE_LINKED_TOURTLE_DIALOG_CLOSING: "REMOVE_LINKED_TOURTLE_DIALOG_CLOSING",

  LOGIN_CHANNEL_ADDRESS_CHANGED: "LOGIN_CHANNEL_ADDRESS_CHANGED",
  LOGIN_EMAIL_CHANGED: "LOGIN_EMAIL_CHANGED",
  LOGIN_LINK_REQUEST_FAILURE: "LOGIN_LINK_REQUEST_FAILURE",
  LOGIN_LINK_REQUEST_INVALID: "LOGIN_LINK_REQUEST_INVALID",
  LOGIN_LINK_REQUEST_UNAUTHORIZED: "LOGIN_LINK_REQUEST_UNAUTHORIZED",
  LOGIN_LINK_REQUEST_REQUEST: "LOGIN_LINK_REQUEST_REQUEST",
  LOGIN_LINK_REQUEST_SUCCESS: "LOGIN_LINK_REQUEST_SUCCESS",

  SIGN_UP_CHANNEL_SELECTED: "SIGN_UP_CHANNEL_SELECTED",
  SIGN_UP_EMAIL_CHANGED: "SIGN_UP_EMAIL_CHANGED",
  SIGN_UP_PHONE_CHANGED: "SIGN_UP_PHONE_CHANGED",
  SIGN_UP_FAILURE: "SIGN_UP_FAILURE",
  SIGN_UP_REQUEST: "SIGN_UP_REQUEST",
  SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
  SIGN_UP_FIRST_NAME_CHANGED: "SIGN_UP_FIRST_NAME_CHANGED",
  SIGN_UP_LAST_NAME_CHANGED: "SIGN_UP_LAST_NAME_CHANGED",
  SIGN_UP_COMPANY_CHANGED: "SIGN_UP_COMPANY_CHANGED",
  SIGN_UP_FIRST_NAME_CHECK_BAD_INPUT: "SIGN_UP_FIRST_NAME_CHECK_BAD_INPUT",
  SIGN_UP_LAST_NAME_CHECK_BAD_INPUT: "SIGN_UP_LAST_NAME_CHECK_BAD_INPUT",
  SIGN_UP_COMPANY_CHECK_BAD_INPUT: "SIGN_UP_COMPANY_CHECK_BAD_INPUT",
  SIGN_UP_CHECK_INCOMPLETE_INPUT: "SIGN_UP_CHECK_INCOMPLETE_INPUT",
  SIGN_UP_USERNAME_CHANGED: "SIGN_UP_USERNAME_CHANGED",
  SIGN_UP_USERNAME_CHECK_REQUEST: "SIGN_UP_USERNAME_CHECK_REQUEST",
  SIGN_UP_USERNAME_CHECK_SUCCESS: "SIGN_UP_USERNAME_CHECK_SUCCESS",
  SIGN_UP_USERNAME_CHECK_BAD_INPUT: "SIGN_UP_USERNAME_CHECK_BAD_INPUT",
  SIGN_UP_USERNAME_CHECK_FAILURE: "SIGN_UP_USERNAME_CHECK_FAILURE",
  SIGN_UP_USERNAME_CHECK_FORBIDDEN_INPUT:
    "SIGN_UP_USERNAME_CHECK_FORBIDDEN_INPUT",
  SIGN_UP_ADDRESS_TAKEN_DIALOG_OPENING: "SIGN_UP_ADDRESS_TAKEN_DIALOG_OPENING",
  SIGN_UP_ADDRESS_TAKEN_DIALOG_CLOSING: "SIGN_UP_ADDRESS_TAKEN_DIALOG_CLOSING",

  ON_START_COMMENTING: "ON_START_COMMENTING",
  ON_END_COMMENTING: "ON_END_COMMENTING",

  UPLOAD_AUDIO_FAILURE: "UPLOAD_AUDIO_FAILURE",
  UPLOAD_AUDIO_REQUEST: "UPLOAD_AUDIO_REQUEST",
  UPLOAD_AUDIO_SUCCESS: "UPLOAD_AUDIO_SUCCESS",

  UPLOAD_VIDEO_FAILURE: "UPLOAD_VIDEO_FAILURE",
  UPLOAD_VIDEO_REQUEST: "UPLOAD_VIDEO_REQUEST",
  UPLOAD_VIDEO_SUCCESS: "UPLOAD_VIDEO_SUCCESS",

  CLEAR_LIST: "CLEAR_LIST",
  CLEAR_LIST_AUTHOR: "CLEAR_LIST_AUTHOR",

  ADD_COLLABORATORS_FAILURE: "ADD_COLLABORATORS_FAILURE",
  ADD_COLLABORATORS_REQUEST: "ADD_COLLABORATORS_REQUEST",
  ADD_COLLABORATORS_SUCCESS: "ADD_COLLABORATORS_SUCCESS",

  UPDATE_COLLABORATOR_FAILURE: "UPDATE_COLLABORATOR_FAILURE",
  UPDATE_COLLABORATOR_REQUEST: "UPDATE_COLLABORATOR_REQUEST",
  UPDATE_COLLABORATOR_SUCCESS: "UPDATE_COLLABORATOR_SUCCESS",

  SWITCH_COLLABORATOR_TYPE_FAILURE: "SWITCH_COLLABORATOR_TYPE_FAILURE",
  SWITCH_COLLABORATOR_TYPE_REQUEST: "SWITCH_COLLABORATOR_TYPE_REQUEST",
  SWITCH_COLLABORATOR_TYPE_SUCCESS: "SWITCH_COLLABORATOR_TYPE_SUCCESS",

  OPEN_UPDATE_STEP_SCREEN: "OPEN_UPDATE_STEP_SCREEN",
  CLOSE_UPDATE_STEP_SCREEN: "CLOSE_UPDATE_STEP_SCREEN",

  DELETE_STEP_FAILURE: "DELETE_STEP_FAILURE",
  DELETE_STEP_REQUEST: "DELETE_STEP_REQUEST",
  DELETE_STEP_SUCCESS: "DELETE_STEP_SUCCESS",

  DELETE_GROUP_FAILURE: "DELETE_GROUP_FAILURE",
  DELETE_GROUP_REQUEST: "DELETE_GROUP_REQUEST",
  DELETE_GROUP_SUCCESS: "DELETE_GROUP_SUCCESS",

  INVITATION_DECLINE_FAILURE: "INVITATION_DECLINE_FAILURE",
  INVITATION_DECLINE_REQUEST: "INVITATION_DECLINE_REQUEST",
  INVITATION_DECLINE_SUCCESS: "INVITATION_DECLINE_SUCCESS",

  INVITATION_ACCEPT_FAILURE: "INVITATION_ACCEPT_FAILURE",
  INVITATION_ACCEPT_REQUEST: "INVITATION_ACCEPT_REQUEST",
  INVITATION_ACCEPT_SUCCESS: "INVITATION_ACCEPT_SUCCESS",

  GROUP_LIST_FAILURE: "GROUP_LIST_FAILURE",
  GROUP_LIST_REQUEST: "GROUP_LIST_REQUEST",
  GROUP_LIST_SUCCESS: "GROUP_LIST_SUCCESS",

  INVITE_USERS_FAILURE: "INVITE_USERS_FAILURE",
  INVITE_USERS_REQUEST: "INVITE_USERS_REQUEST",
  INVITE_USERS_SUCCESS: "INVITE_USERS_SUCCESS",

  REVOKE_INVITATION_FAILURE: "REVOKE_INVITATION_FAILURE",
  REVOKE_INVITATION_REQUEST: "REVOKE_INVITATION_REQUEST",
  REVOKE_INVITATION_SUCCESS: "REVOKE_INVITATION_SUCCESS",

  GROUP_ITEM_CLEAR: "GROUP_ITEM_CLEAR",
  SET_SHOW_INITIAL_GROUPS: "SET_SHOW_INITIAL_GROUPS",

  CREATE_COMMENT_FAILURE: "CREATE_COMMENT_FAILURE",
  CREATE_COMMENT_REQUEST: "CREATE_COMMENT_REQUEST",
  CREATE_COMMENT_SUCCESS: "CREATE_COMMENT_SUCCESS",

  UPDATE_COMMENT_FAILURE: "UPDATE_COMMENT_FAILURE",
  UPDATE_COMMENT_REQUEST: "UPDATE_COMMENT_REQUEST",
  UPDATE_COMMENT_SUCCESS: "UPDATE_COMMENT_SUCCESS",

  DELETE_COMMENT_FAILURE: "DELETE_COMMENT_FAILURE",
  DELETE_COMMENT_REQUEST: "DELETE_COMMENT_REQUEST",
  DELETE_COMMENT_SUCCESS: "DELETE_COMMENT_SUCCESS",

  CREATE_REPLY_FAILURE: "CREATE_REPLY_FAILURE",
  CREATE_REPLY_REQUEST: "CREATE_REPLY_REQUEST",
  CREATE_REPLY_SUCCESS: "CREATE_REPLY_SUCCESS",

  UPDATE_REPLY_FAILURE: "UPDATE_REPLY_FAILURE",
  UPDATE_REPLY_REQUEST: "UPDATE_REPLY_REQUEST",
  UPDATE_REPLY_SUCCESS: "UPDATE_REPLY_SUCCESS",

  DELETE_REPLY_FAILURE: "DELETE_REPLY_FAILURE",
  DELETE_REPLY_REQUEST: "DELETE_REPLY_REQUEST",
  DELETE_REPLY_SUCCESS: "DELETE_REPLY_SUCCESS",

  CREATE_RATING_FAILURE: "CREATE_RATING_FAILURE",
  CREATE_RATING_REQUEST: "CREATE_RATING_REQUEST",
  CREATE_RATING_SUCCESS: "CREATE_RATING_SUCCESS",

  UPDATE_RATING_FAILURE: "UPDATE_RATING_FAILURE",
  UPDATE_RATING_REQUEST: "UPDATE_RATING_REQUEST",
  UPDATE_RATING_SUCCESS: "UPDATE_RATING_SUCCESS",

  DELETE_RATING_FAILURE: "DELETE_RATING_FAILURE",
  DELETE_RATING_REQUEST: "DELETE_RATING_REQUEST",
  DELETE_RATING_SUCCESS: "DELETE_RATING_SUCCESS",

  SET_ACTIVE_STEP_ID: "SET_ACTIVE_STEP_ID",

  FETCH_USER_DATA_REQUEST: "FETCH_USER_DATA_REQUEST",
  FETCH_USER_DATA_SUCCESS: "FETCH_USER_DATA_SUCCESS",
  FETCH_USER_DATA_FAILURE: "FETCH_USER_DATA_FAILURE",

  UPDATE_TOURTLE_OPENING: "UPDATE_TOURTLE_OPENING",
  UPDATE_TOURTLE_CLOSING: "UPDATE_TOURTLE_CLOSING",

  SEARCH_AUTHOR_LIST_FAILURE: "SEARCH_AUTHOR_LIST_FAILURE",
  SEARCH_AUTHOR_LIST_REQUEST: "SEARCH_AUTHOR_LIST_REQUEST",
  SEARCH_AUTHOR_LIST_SUCCESS: "SEARCH_AUTHOR_LIST_SUCCESS",

  TRANSFER_SEARCH_PATTERN_CHANGED: "TRANSFER_SEARCH_PATTERN_CHANGED",

  ADMIN_TRANSFER_USER_SELECTED: "ADMIN_TRANSFER_USER_SELECTED",

  ABOUT_AUTHOR_OPENING: "ABOUT_AUTHOR_OPENING",

  ABOUT_AUTHOR_MENU_OPENING: "ABOUT_AUTHOR_MENU_OPENING",
  ABOUT_AUTHOR_MENU_CLOSING: "ABOUT_AUTHOR_MENU_CLOSING",

  TAB_BAR_SEARCH_STATE: "TAB_BAR_SEARCH_STATE",

  START_MEDIA_SLIDER: "START_MEDIA_SLIDER",
  END_MEDIA_SLIDER: "END_MEDIA_SLIDER",

  FILTER_DIALOG_UPDATE: "FILTER_DIALOG_UPDATE",
  FILTER_DIALOG_IS_OPEN: "FILTER_DIALOG_IS_OPEN",

  REMOVE_TOURTLE_FROM_GROUP_FAILURE: "REMOVE_TOURTLE_FROM_GROUP_FAILURE",
  REMOVE_TOURTLE_FROM_GROUP_REQUEST: "REMOVE_TOURTLE_FROM_GROUP_REQUEST",
  REMOVE_TOURTLE_FROM_GROUP_SUCCESS: "REMOVE_TOURTLE_FROM_GROUP_SUCCESS",

  TOGGLE_SUB_NOTIFICATION_FAILURE: "TOGGLE_SUB_NOTIFICATION_FAILURE",
  TOGGLE_SUB_NOTIFICATION_REQUEST: "TOGGLE_SUB_NOTIFICATION_REQUEST",
  TOGGLE_SUB_NOTIFICATION_SUCCESS: "TOGGLE_SUB_NOTIFICATION_SUCCESS",
  TOURTLE_SUBS_CLEAR: "TOURTLE_SUBS_CLEAR",

  COLLECTION_OPENING: "COLLECTION_OPENING",

  ADD_SELECTED_TOURTLE_TO_ARRAY: "ADD_SELECTED_TOURTLE_TO_ARRAY",
  REMOVE_SELECTED_TOURTLE_FROM_ARRAY: "REMOVE_SELECTED_TOURTLE_FROM_ARRAY",

  DISCARD_CHANGES_DIALOG_CLOSING: "DISCARD_CHANGES_DIALOG_CLOSING",
  DISCARD_CHANGES_DIALOG_OPENING: "DISCARD_CHANGES_DIALOG_OPENING",

  DISPATCH_SELECTED_TOURTLES: "DISPATCH_SELECTED_TOURTLES",

  FETCH_SUGGESTED_TOURTLES_REQUEST: "FETCH_SUGGESTED_TOURTLES_REQUEST",
  FETCH_SUGGESTED_TOURTLES_SUCCESS: "FETCH_SUGGESTED_TOURTLES_SUCCESS",
  FETCH_SUGGESTED_TOURTLES_FAILURE: "FETCH_SUGGESTED_TOURTLES_FAILURE",

  REMOVE_LINKED_TOURTLE_REQUEST: "REMOVE_LINKED_TOURTLE_REQUEST",
  REMOVE_LINKED_TOURTLE_SUCCESS: "REMOVE_LINKED_TOURTLE_SUCCESS",
  REMOVE_LINKED_TOURTLE_FAILURE: "REMOVE_LINKED_TOURTLE_FAILURE",
  REORDER_TOURTLES_IN_COLLECTION_SUCCESS:
    "REORDER_TOURTLES_IN_COLLECTION_SUCCESS",
  SEARCH_WITH_EMPTY_QUERY: "SEARCH_WITH_EMPTY_QUERY",
  EXIT_SEARCH_WITH_EMPTY_QUERY: "EXIT_SEARCH_WITH_EMPTY_QUERY",
  SET_ORIGIN_OF_ROUTE: "SET_ORIGIN_OF_ROUTE",
  CLEAR_ORIGIN_OF_ROUTE: "CLEAR_ORIGIN_OF_ROUTE",
  CLEAR_RESULT_VALUES: "CLEAR_RESULT_VALUES",
  PREVENT_CLEAR_COLLECTION_STATE: "PREVENT_CLEAR_COLLECTION_STATE",
  HOME_CLEAR_LIST: "HOME_CLEAR_LIST",
  SET_FIRST_USE: "SET_FIRST_USE",

  GET_BLACK_WHITE_LIST_REQUEST: "GET_BLACK_WHITE_LIST_REQUEST",
  GET_BLACK_WHITE_LIST_SUCCESS: "GET_BLACK_WHITE_LIST_SUCCESS",
  GET_BLACK_WHITE_LIST_FAILURE: "GET_BLACK_WHITE_LIST_FAILURE",
  MANAGE_BLACK_WHITE_LIST_REQUEST: "MANAGE_BLACK_WHITE_LIST_REQUEST",
  MANAGE_BLACK_WHITE_LIST_ADD_SUCCESS: "MANAGE_BLACK_WHITE_LIST_ADD_SUCCESS",
  MANAGE_BLACK_WHITE_LIST_REMOVE_SUCCESS:
    "MANAGE_BLACK_WHITE_LIST_REMOVE_SUCCESS",
  MANAGE_BLACK_WHITE_LIST_FINISH: "MANAGE_BLACK_WHITE_LIST_FINISH",
  MANAGE_BLACK_WHITE_LIST_FAILURE: "MANAGE_BLACK_WHITE_LIST_FAILURE",
  OPEN_ADD_WORDS_DIALOG: "OPEN_ADD_WORDS_DIALOG",
  CLOSE_ADD_WORDS_DIALOG: "CLOSE_ADD_WORDS_DIALOG",
  OPEN_REMOVE_WORDS_DIALOG: "OPEN_REMOVE_WORDS_DIALOG",
  CLOSE_REMOVE_WORDS_DIALOG: "CLOSE_REMOVE_WORDS_DIALOG",

  ADMIN_REFRESH_STATS_FAILURE: "ADMIN_REFRESH_STATS_FAILURE",
  ADMIN_REFRESH_STATS_REQUEST: "ADMIN_REFRESH_STATS_REQUEST",
  ADMIN_REFRESH_STATS_SUCCESS: "ADMIN_REFRESH_STATS_SUCCESS",

  ADMIN_UPDATE_FLAGS_FAILURE: "ADMIN_UPDATE_FLAGS_FAILURE",
  ADMIN_UPDATE_FLAGS_REQUEST: "ADMIN_UPDATE_FLAGS_REQUEST",
  ADMIN_UPDATE_FLAGS_SUCCESS: "ADMIN_UPDATE_FLAGS_SUCCESS",

  ADMIN_UPDATE_CATEGORIES_FAILURE: "ADMIN_UPDATE_CATEGORIES_FAILURE",
  ADMIN_UPDATE_CATEGORIES_REQUEST: "ADMIN_UPDATE_CATEGORIES_REQUEST",
  ADMIN_UPDATE_CATEGORIES_SUCCESS: "ADMIN_UPDATE_CATEGORIES_SUCCESS",

  ADMIN_FETCH_REPORT_FAILURE: "ADMIN_FETCH_REPORT_FAILURE",
  ADMIN_FETCH_REPORT_REQUEST: "ADMIN_FETCH_REPORT_REQUEST",
  ADMIN_FETCH_REPORT_SUCCESS: "ADMIN_FETCH_REPORT_SUCCESS",

  ADMIN_USER_SEARCH_FAILURE: "ADMIN_USER_SEARCH_FAILURE",
  ADMIN_USER_SEARCH_REQUEST: "ADMIN_USER_SEARCH_REQUEST",
  ADMIN_USER_SEARCH_SUCCESS: "ADMIN_USER_SEARCH_SUCCESS",

  ADMIN_DELETE_USER_FAILURE: "ADMIN_DELETE_USER_FAILURE",
  ADMIN_DELETE_USER_REQUEST: "ADMIN_DELETE_USER_REQUEST",
  ADMIN_DELETE_USER_SUCCESS: "ADMIN_DELETE_USER_SUCCESS",

  ADMIN_TRANSFER_TOURTLE_FAILURE: "ADMIN_TRANSFER_TOURTLE_FAILURE",
  ADMIN_TRANSFER_TOURTLE_REQUEST: "ADMIN_TRANSFER_TOURTLE_REQUEST",
  ADMIN_TRANSFER_TOURTLE_SUCCESS: "ADMIN_TRANSFER_TOURTLE_SUCCESS",

  LOAD_TRANSFEREE_FAILURE: "LOAD_TRANSFEREE_FAILURE",
  LOAD_TRANSFEREE_REQUEST: "LOAD_TRANSFEREE_REQUEST",
  LOAD_TRANSFEREE_SUCCESS: "LOAD_TRANSFEREE_SUCCESS",

  // Check email availability before register
  SIGN_UP_EMAIL_CHECK_REQUEST: "SIGN_UP_EMAIL_CHECK_REQUEST",
  SIGN_UP_EMAIL_CHECK_SUCCESS: "SIGN_UP_EMAIL_CHECK_SUCCESS",
  SIGN_UP_EMAIL_CHECK_FALSE: "SIGN_UP_EMAIL_CHECK_FALSE",

  // TODO: possibly delete the following 6 events
  LOGIN_CURRENT_USER_FAILURE: "LOGIN_CURRENT_USER_FAILURE",
  LOGIN_CURRENT_USER_REQUEST: "LOGIN_CURRENT_USER_REQUEST",
  LOGIN_CURRENT_USER_SUCCESS: "LOGIN_CURRENT_USER_SUCCESS",

  DELETE_TOURTLE_FAILURE: "DELETE_TOURTLE_FAILURE",
  DELETE_TOURTLE_REQUEST: "DELETE_TOURTLE_REQUEST",
  DELETE_TOURTLE_SUCCESS: "DELETE_TOURTLE_SUCCESS",

  SET_PRIVATE_LINK_INFORMATION_STEP: "SET_PRIVATE_LINK_INFORMATION_STEP",
  UNSET_PRIVATE_LINK_INFORMATION_STEP: "UNSET_PRIVATE_LINK_INFORMATION_STEP",

  CREATE_COLLECTION_FAILURE: "CREATE_COLLECTION_FAILURE",
  CREATE_COLLECTION_REQUEST: "CREATE_COLLECTION_REQUEST",
  CREATE_COLLECTION_SUCCESS: "CREATE_COLLECTION_SUCCESS",

  UPDATE_USER_GROUPS_REQUEST: "UPDATE_USER_GROUPS_REQUEST",
  UPDATE_USER_GROUPS_SUCCESS: "UPDATE_USER_GROUPS_SUCCESS",
  UPDATE_USER_GROUPS_FAILURE: "UPDATE_USER_GROUPS_FAILURE",

  DISPATCH_RESULT_VALUES: "DISPATCH_RESULT_VALUES",

  RESET_NEW_TOURTLE_COUNT_SUBSCRIBER: "RESET_NEW_TOURTLE_COUNT_SUBSCRIBER",
  GET_USER_INFO_SECTION_REQUEST: "GET_USER_INFO_SECTION_REQUEST:",
  GET_USER_INFO_SECTION_SUCCESS: "GET_USER_INFO_SECTION_SUCCESS:",
  GET_USER_INFO_SECTION_FAILURE: "GET_USER_INFO_SECTION_FAILURE:",

  DISPATCH_COGNITO_USER: "DISPATCH_COGNITO_USER",
  CLEAR_COGNITO_LOGIN: "CLEAR_COGNITO_LOGIN",

  DISPATCH_LANDING_PAGE_SETTINGS: "DISPATCH_LANDING_PAGE_SETTINGS",

  CLEAR_SIGN_UP: "CLEAR_SIGN_UP",

  GROUP_DISCOVERY_LIST_REQUEST: "GROUP_DISCOVERY_LIST_REQUEST",
  GROUP_DISCOVERY_LIST_SUCCESS: "GROUP_DISCOVERY_LIST_SUCCESS",
  GROUP_DISCOVERY_LIST_FAILURE: "GROUP_DISCOVERY_LIST_FAILURE",
  GROUP_DISCOVERY_LIST_CLEAR: "GROUP_DISCOVERY_LIST_CLEAR",

  SET_GROUP_TAP_VALUE: "SET_GROUP_TAP_VALUE",

  SET_ADD_TOURTLE_TO_GROUP_DIALOG_OPEN: "SET_ADD_TOURTLE_TO_GROUP_DIALOG_OPEN",
  SET_ADD_TOURTLE_TO_GROUP_DIALOG_CLOSE:
    "SET_ADD_TOURTLE_TO_GROUP_DIALOG_CLOSE",

  ADD_TOURTLES_TO_GROUPS_REQUEST: "ADD_TOURTLES_TO_GROUPS_REQUEST",
  ADD_TOURTLES_TO_GROUPS_SUCCESS: "ADD_TOURTLES_TO_GROUPS_SUCCESS",
  ADD_TOURTLES_TO_GROUPS_FAILURE: "ADD_TOURTLES_TO_GROUPS_FAILURE",

  STORE_NEW_EMAIL_ADDRESS: "STORE_NEW_EMAIL_ADDRESS",

  UPLOAD_FILE_FAILURE: "UPLOAD_FILE_FAILURE",
  UPLOAD_FILE_REQUEST: "UPLOAD_FILE_REQUEST",
  UPLOAD_FILE_SUCCESS: "UPLOAD_FILE_SUCCESS",

  SET_SELECTED_ATTACHMENT: "SET_SELECTED_ATTACHMENT",

  OPEN_SELECTED_ATTACHMENT: "OPEN_SELECTED_ATTACHMENT",
  CLOSE_SELECTED_ATTACHMENT: "CLOSE_SELECTED_ATTACHMENT",

  CLOSE_SEND_EMAIL_TO_GROUP_MEMBERS_DIALOG:
    "CLOSE_SEND_EMAIL_TO_GROUP_MEMBERS_DIALOG",
  OPEN_SEND_EMAIL_TO_GROUP_MEMBERS_DIALOG:
    "OPEN_SEND_EMAIL_TO_GROUP_MEMBERS_DIALOG",

  REQUEST_SEND_EMAIL_TO_MEMBERS: "REQUEST_SEND_EMAIL_TO_MEMBERS",
  SUCCESS_SEND_EMAIL_TO_MEMBERS: "SUCCESS_SEND_EMAIL_TO_MEMBERS",
  REJECT_SEND_EMAIL_TO_MEMBERS: "REJECT_SEND_EMAIL_TO_MEMBERS",
  UPDATE_STEP_CHECK: "UPDATE_STEP_CHECK",
  UPDATE_STEP_CHECK_SUCCESS: "UPDATE_STEP_CHECK_SUCCESS",
  UPDATE_STEP_CHECK_FAILURE: "UPDATE_STEP_CHECK_FAILURE",

  SAVE_SUBSCRIPTION: "SAVE_SUBSCRIPTION",
  UPDATE_STORAGE_USED: "UPDATE_STORAGE_USED",
};

const fireLeftNavOpening = () => ({
  type: actionTypes.LEFT_NAV_OPENING,
  payload: null,
});

const fireLeftNavClosing = () => ({
  type: actionTypes.LEFT_NAV_CLOSING,
  payload: null,
});
//? GET GROUP DISCOVERY ITEMS

const groupDiscoveryListRequest = () => ({
  type: actionTypes.GROUP_DISCOVERY_LIST_REQUEST,
  payload: null,
});

const groupDiscoveryListSuccess = response => ({
  type: actionTypes.GROUP_DISCOVERY_LIST_SUCCESS,
  payload: response,
});

const groupDiscoveryListFailure = err => ({
  type: actionTypes.GROUP_DISCOVERY_LIST_FAILURE,
  payload: { error: err.stack },
});

const groupDiscoveryListClear = () => ({
  type: actionTypes.GROUP_DISCOVERY_LIST_CLEAR,
  payload: null,
});

// Get Home List

const homeListRequest = () => ({
  type: actionTypes.HOME_LIST_REQUEST,
  payload: null,
});

const homeListSuccess = response => ({
  type: actionTypes.HOME_LIST_SUCCESS,
  payload: response,
});

const homeListFailure = err => ({
  type: actionTypes.HOME_LIST_FAILURE,
  payload: { error: err.stack },
});

const homeListClear = () => ({
  type: actionTypes.HOME_LIST_CLEAR,
  payload: null,
});

// Get Trending List
const trendingListRequest = () => ({
  type: actionTypes.TRENDING_LIST_REQUEST,
  payload: null,
});

const trendingListSuccess = response => ({
  type: actionTypes.TRENDING_LIST_SUCCESS,
  payload: response,
});

const trendingListFailure = err => ({
  type: actionTypes.TRENDING_LIST_FAILURE,
  payload: { error: err.stack },
});

// Get Subs Tourtle List
const subsTourtleListRequest = () => ({
  type: actionTypes.SUBS_TOURTLE_LIST_REQUEST,
  payload: null,
});

const subsTourtleListSuccess = response => ({
  type: actionTypes.SUBS_TOURTLE_LIST_SUCCESS,
  payload: response,
});

const subsTourtleListFailure = err => ({
  type: actionTypes.SUBS_TOURTLE_LIST_FAILURE,
  payload: { error: err.stack },
});

// Fetch Subscriptions
const subscriptionsRequest = () => ({
  type: actionTypes.FETCH_SUBSCRIPTIONS_REQUEST,
  payload: null,
});

const subscriptionsSuccess = response => ({
  type: actionTypes.FETCH_SUBSCRIPTIONS_SUCCESS,
  payload: response,
});

const subscriptionsFailure = err => ({
  type: actionTypes.FETCH_SUBSCRIPTIONS_FAILURE,
  payload: { error: err.stack },
});

// Fetch Author
const fetchAuthorRequest = () => ({
  type: actionTypes.FETCH_AUTHOR_REQUEST,
  payload: null,
});

const fetchAuthorSuccess = response => ({
  type: actionTypes.FETCH_AUTHOR_SUCCESS,
  payload: response,
});

const fetchAuthorFailure = err => ({
  type: actionTypes.FETCH_AUTHOR_FAILURE,
  payload: { error: err.stack },
});

// Subscribe to author
const subscribeRequest = () => ({
  type: actionTypes.SUBSCRIBE_REQUEST,
  payload: null,
});

const subscribeSuccess = response => ({
  type: actionTypes.SUBSCRIBE_SUCCESS,
  payload: response,
});

const subscribeFailure = err => ({
  type: actionTypes.SUBSCRIBE_FAILURE,
  payload: { error: err.stack },
});

// Unsubscribe to author
const unSubscribeRequest = () => ({
  type: actionTypes.UNSUBSCRIBE_REQUEST,
  payload: null,
});

const unSubscribeSuccess = response => ({
  type: actionTypes.UNSUBSCRIBE_SUCCESS,
  payload: response,
});

const unSubscribeFailure = err => ({
  type: actionTypes.UNSUBSCRIBE_FAILURE,
  payload: { error: err.stack },
});

// Get Author Tourtle List
const authorTourtleListRequest = () => ({
  type: actionTypes.AUTHOR_TOURTLE_LIST_REQUEST,
  payload: null,
});

const authorTourtleListSuccess = response => ({
  type: actionTypes.AUTHOR_TOURTLE_LIST_SUCCESS,
  payload: response,
});

const authorTourtleListFailure = err => ({
  type: actionTypes.AUTHOR_TOURTLE_LIST_FAILURE,
  payload: { error: err.stack },
});

// Clear Author Tourtle List
const clearAuthorTourtleList = () => ({
  type: actionTypes.CLEAR_AUTHOR_TOURTLE_LIST,
  payload: null,
});

// Clear Author
const clearAuthor = () => ({
  type: actionTypes.CLEAR_AUTHOR,
  payload: null,
});

// Get Current User Tourtle List
const currentUserTourtleListRequest = () => ({
  type: actionTypes.CURRENT_USER_TOURTLE_LIST_REQUEST,
  payload: null,
});

const currentUserTourtleListSuccess = response => ({
  type: actionTypes.CURRENT_USER_TOURTLE_LIST_SUCCESS,
  payload: response,
});

const currentUserTourtleListFailure = err => ({
  type: actionTypes.CURRENT_USER_TOURTLE_LIST_FAILURE,
  payload: { error: err.stack },
});

// Clear current user tourtle list
const clearCurrentUserTourtleList = () => ({
  type: actionTypes.CLEAR_CURRENT_USER_TOURTLE_LIST,
  payload: null,
});

// Get Recently Visited Tourtle List
const recentlyVisitedTourtleListRequest = () => ({
  type: actionTypes.RECENTLY_VISITED_TOURTLE_LIST_REQUEST,
  payload: null,
});

const recentlyVisitedTourtleListSuccess = response => ({
  type: actionTypes.RECENTLY_VISITED_TOURTLE_LIST_SUCCESS,
  payload: response,
});

const recentlyVisitedTourtleListFailure = err => ({
  type: actionTypes.RECENTLY_VISITED_TOURTLE_LIST_FAILURE,
  payload: { error: err.stack },
});

// Get Current User Collection List
const currentUserCollectionListRequest = () => ({
  type: actionTypes.CURRENT_USER_COLLECTION_LIST_REQUEST,
  payload: null,
});

const currentUserCollectionListSuccess = response => ({
  type: actionTypes.CURRENT_USER_COLLECTION_LIST_SUCCESS,
  payload: response,
});

const currentUserCollectionListFailure = err => ({
  type: actionTypes.CURRENT_USER_COLLECTION_LIST_FAILURE,
  payload: { error: err.stack },
});

// Favorite Unfavorite Tourtle
const favoriteTourtleRequest = () => ({
  type: actionTypes.FAVORITE_TOURTLE_REQUEST,
  payload: null,
});

const favoriteTourtleSuccess = (tourtle, value) => (dispatch, getState) => {
  const state = getState();
  const favorites = selectors.getCurrentUserFavoriteItems(state);
  dispatch(handleFavoriteTourtleSuccess(tourtle, value, favorites));
};

const handleFavoriteTourtleSuccess = (tourtle, value, favorites) => ({
  type: actionTypes.FAVORITE_TOURTLE_SUCCESS,
  payload: { tourtle: tourtle, value: value, favorites: favorites },
});

const favoriteTourtleFailure = err => ({
  type: actionTypes.FAVORITE_TOURTLE_FAILURE,
  payload: { error: err.stack },
});

// Like Tourtle
const reactOnTourtleRequest = () => ({
  type: actionTypes.REACT_ON_TOURTLE_REQUEST,
  payload: null,
});

const reactOnTourtleSuccess = tourtleId => ({
  type: actionTypes.REACT_ON_TOURTLE_SUCCESS,
  payload: { tourtleId: tourtleId },
});

const reactOnTourtleFailure = err => ({
  type: actionTypes.REACT_ON_TOURTLE_FAILURE,
  payload: { error: err.stack },
});

// Remove like on Tourtle
const removeReactionOnTourtleRequest = () => ({
  type: actionTypes.REMOVE_REACTION_ON_TOURTLE_REQUEST,
  payload: null,
});

const removeReactionOnTourtleSuccess = tourtleId => ({
  type: actionTypes.REMOVE_REACTION_ON_TOURTLE_SUCCESS,
  payload: { tourtleId: tourtleId },
});

const removeReactionOnTourtleFailure = err => ({
  type: actionTypes.REMOVE_REACTION_ON_TOURTLE_FAILURE,
  payload: { error: err.stack },
});

// Get Current User Favorite List
const currentUserFavoritesListRequest = () => ({
  type: actionTypes.CURRENT_USER_FAVORITE_LIST_REQUEST,
  payload: null,
});

const currentUserFavoritesListSuccess = res => ({
  type: actionTypes.CURRENT_USER_FAVORITE_LIST_SUCCESS,
  payload: res,
});

const currentUserFavoritesListFailure = () => ({
  type: actionTypes.CURRENT_USER_FAVORITE_LIST_FAILURE,
  payload: null,
});

// Delete Tourtle Dialog
const fireDeleteTourtleDialogClosing = () => ({
  type: actionTypes.DELETE_TOURTLE_DIALOG_CLOSING,
  payload: null,
});

const fireDeleteTourtleDialogOpening = tourtle => ({
  type: actionTypes.DELETE_TOURTLE_DIALOG_OPENING,
  payload: {
    tourtle,
  },
});

// Delete Tourtle
const deleteTourtleRequest = () => ({
  type: actionTypes.DELETE_TOURTLE_REQUEST,
  payload: null,
});

const deleteTourtleSuccess = response => ({
  type: actionTypes.DELETE_TOURTLE_SUCCESS,
  payload: response.id,
});

const deleteTourtleFailure = err => ({
  type: actionTypes.DELETE_TOURTLE_FAILURE,
  payload: { error: err.stack },
});

// Report Dialog
const fireReportDialogClosing = () => ({
  type: actionTypes.REPORT_DIALOG_CLOSING,
  payload: null,
});

const fireReportDialogOpening = (type, item, parentTourtle) => ({
  type: actionTypes.REPORT_DIALOG_OPENING,
  payload: { type, item, parentTourtle },
});

// Report Tourtle
const reportRequest = () => ({
  type: actionTypes.REPORT_REQUEST,
  payload: null,
});

const reportSuccess = () => ({
  type: actionTypes.REPORT_SUCCESS,
  payload: null,
});

const reportFailure = err => ({
  type: actionTypes.REPORT_FAILURE,
  payload: { error: err.stack },
});

// Snackbar opening/closing
const fireSnackbarClosing = () => ({
  type: actionTypes.SNACKBARS_CLOSING,
  payload: null,
});

const fireSnackbarOpening = (type, message, autoHide = true) => ({
  type: actionTypes.SNACKBARS_OPENING,
  payload: { type, message, autoHide },
});

const openAddTourtleToGroupsConfirmationDialog = (tourtle, groups) => ({
  type: actionTypes.ADD_TOURTLE_TO_GROUP_DIALOG_CONFIRMATION_OPENING,
  payload: { tourtle, groups },
});

const closeAddTourtleToGroupsConfirmationDialog = () => ({
  type: actionTypes.ADD_TOURTLE_TO_GROUP_DIALOG_CONFIRMATION_CLOSING,
  payload: null,
});

// Add tourtle to group Dialog
const fireAddToGroupDialogClosing = () => ({
  type: actionTypes.ADD_TOURTLE_TO_GROUP_DIALOG_CLOSING,
  payload: null,
});

const fireAddToGroupDialogOpening = tourtle => ({
  type: actionTypes.ADD_TOURTLE_TO_GROUP_DIALOG_OPENING,
  payload: { tourtle },
});

const fireAddToGroupDialogHiding = () => ({
  type: actionTypes.ADD_TOURTLE_TO_GROUP_DIALOG_HIDING,
  payload: null,
});

const fireAddToGroupDialogShowing = () => ({
  type: actionTypes.ADD_TOURTLE_TO_GROUP_DIALOG_SHOWING,
  payload: null,
});

// Set private link information step
const setPrivateLinkInformationStep = step => ({
  type: actionTypes.SET_PRIVATE_LINK_INFORMATION_STEP,
  payload: { step },
});

const unsetPrivateLinkInformationStep = () => ({
  type: actionTypes.UNSET_PRIVATE_LINK_INFORMATION_STEP,
  payload: null,
});

// Add tourtle to groups
const addTourtleToGroupsRequest = () => ({
  type: actionTypes.ADD_TOURTLE_TO_GROUPS_REQUEST,
  payload: null,
});

const addTourtleToGroupsSuccess = (res, id) => ({
  type: actionTypes.ADD_TOURTLE_TO_GROUPS_SUCCESS,
  payload: { res: res, id: id },
});

const addTourtleToGroupsFailure = err => ({
  type: actionTypes.ADD_TOURTLE_TO_GROUPS_FAILURE,
  payload: { error: err.stack },
});

// Add tourtle to collection dialog
const fireAddToCollectionDialogOpening = tourtle => ({
  type: actionTypes.ADD_TOURTLE_TO_COLLECTION_DIALOG_OPENING,
  payload: tourtle,
});

const fireAddToCollectionDialogClosing = () => ({
  type: actionTypes.ADD_TOURTLE_TO_COLLECTION_DIALOG_CLOSING,
  payload: null,
});

// Add tourtle to collection
const addTourtleToCollectionsRequest = () => ({
  type: actionTypes.ADD_TOURTLE_TO_COLLECTIONS_REQUEST,
  payload: null,
});

const addTourtleToCollectionsSuccess = (tourtle, selectedCollectionIds) => ({
  type: actionTypes.ADD_TOURTLE_TO_COLLECTIONS_SUCCESS,
  payload: { tourtle, selectedCollectionIds },
});

const addTourtleToCollectionsFailure = err => ({
  type: actionTypes.ADD_TOURTLE_TO_COLLECTIONS_FAILURE,
  payload: { error: err.stack },
});

// Search groups
const searchGroupListItems = () => ({
  type: actionTypes.SEARCH_GROUP_LIST_ITEMS_REQUEST,
  payload: null,
});

const searchGroupListItemsSuccess = response => ({
  type: actionTypes.SEARCH_GROUP_LIST_ITEMS_SUCCESS,
  payload: response,
});

const searchGroupListItemsFailure = err => ({
  type: actionTypes.SEARCH_GROUP_LIST_ITEMS_FAILURE,
  payload: { error: err.stack },
});

const clearGroupListItemsCToken = () => ({
  type: actionTypes.CLEAR_GROUP_LIST_ITEMS_CTOKEN,
  payload: null,
});

// Clear group list items
const clearGroupListItems = () => ({
  type: actionTypes.CLEAR_GROUP_LIST_ITEMS,
  payload: null,
});

// Open and close login dialog
const fireLoginDialogClosing = () => ({
  type: actionTypes.LOGIN_DIALOG_CLOSE,
  payload: null,
});

const fireLoginDialogOpening = referer => ({
  type: actionTypes.LOGIN_DIALOG_OPEN,
  payload: referer,
});

// Open and close sign in up dialog
const fireSignInUpDialogClosing = () => ({
  type: actionTypes.SIGN_IN_UP_DIALOG_CLOSE,
  payload: null,
});

const fireSignInUpDialogOpening = (type, referer) => ({
  type: actionTypes.SIGN_IN_UP_DIALOG_OPEN,
  payload: { type, referer },
});

// Open and close sign out dialog
const fireSignOutClosing = () => ({
  type: actionTypes.SIGNOUT_DIALOG_CLOSING,
  payload: null,
});

const fireSignOutOpening = () => ({
  type: actionTypes.SIGNOUT_DIALOG_OPENING,
  payload: null,
});

// Open and close cookie dialog
const fireCookieDialogClosing = () => ({
  type: actionTypes.COOKIE_DIALOG_CLOSING,
  payload: null,
});

const fireCookieDialogOpening = () => ({
  type: actionTypes.COOKIE_DIALOG_OPENING,
  payload: null,
});

// Show and hide welcome tourtle
const showWelcomeTourtle = () => ({
  type: actionTypes.SHOW_WELCOME_TOURTLE,
  payload: null,
});

const hideWelcomeTourtle = () => ({
  type: actionTypes.HIDE_WELCOME_TOURTLE,
  payload: null,
});

// Dispatch interests
const dispatchUserInterest = key => ({
  type: actionTypes.DISPATCH_USER_INTEREST,
  payload: key,
});

const dispatchFilterInterest = key => ({
  type: actionTypes.DISPATCH_FILTER_INTEREST,
  payload: key,
});

const dispatchInitialInterests = array => ({
  type: actionTypes.DISPATCH_INITIAL_INTERESTS,
  payload: array,
});

// Update user profile
const updateProfileRequest = () => ({
  type: actionTypes.UPDATE_PROFILE_REQUEST,
  payload: null,
});

const updateProfileSuccess = response => ({
  type: actionTypes.UPDATE_PROFILE_SUCCESS,
  payload: response,
});

const updateProfileFailure = err => ({
  type: actionTypes.UPDATE_PROFILE_FAILURE,
  payload: { err: console.error(err) },
});

// Remove all user Interests
const selectAllUserInterests = array => ({
  type: actionTypes.SELECT_ALL_USER_INTERESTS,
  payload: array,
});

const removeAllUserInterests = () => ({
  type: actionTypes.REMOVE_ALL_USER_INTERESTS,
  payload: null,
});

// Add and remove all filter Interests
const selectAllInterests = array => ({
  type: actionTypes.SELECT_ALL_INTERESTS,
  payload: array,
});

const removeAllInterests = () => ({
  type: actionTypes.REMOVE_ALL_INTERESTS,
  payload: null,
});

// Open and close warning dialog
const fireWarningDialogClosing = type => ({
  type: actionTypes.WARNING_DIALOG_CLOSING,
  payload: type,
});

const fireWarningDialogOpening = tourtleId => ({
  type: actionTypes.WARNING_DIALOG_OPENING,
  payload: {
    tourtleId,
  },
});

// Open and close unsaved changes dialog
const fireUnsavedChangesDialogClosing = () => ({
  type: actionTypes.UNSAVED_CHANGES_DIALOG_CLOSING,
  payload: null,
});

const fireUnsavedChangesDialogOpening = tourtleId => ({
  type: actionTypes.UNSAVED_CHANGES_DIALOG_OPENING,
  payload: {
    tourtleId,
  },
});

// Open and close location dialog
const fireLocationServiceClosing = () => ({
  type: actionTypes.LOCATION_SERVICE_DIALOG_CLOSING,
  payload: null,
});

const fireLocationServiceOpening = () => ({
  type: actionTypes.LOCATION_SERVICE_DIALOG_OPENING,
  payload: null,
});

// Update notification settings
const updateNotificationsRequest = () => ({
  type: actionTypes.UPDATE_NOTIFICATIONS_REQUEST,
  payload: null,
});

const updateNotificationsSuccess = response => ({
  type: actionTypes.UPDATE_NOTIFICATIONS_SUCCESS,
  payload: response,
});

const updateNotificationsFailure = err => ({
  type: actionTypes.UPDATE_NOTIFICATIONS_FAILURE,
  payload: { error: err.stack },
});

// Update auth expiration date
const updateAuthExpirationRequest = () => ({
  type: actionTypes.UPDATE_AUTH_EXPIRATION_REQUEST,
  payload: null,
});

const updateAuthExpirationSuccess = response => ({
  type: actionTypes.UPDATE_AUTH_EXPIRATION_SUCCESS,
  payload: response,
});

const updateAuthExpirationFailure = err => ({
  type: actionTypes.UPDATE_AUTH_EXPIRATION_FAILURE,
  payload: { error: err.stack },
});

// Update show landing page
const updateShowLandingPageRequest = () => ({
  type: actionTypes.UPDATE_SHOW_LANDING_PAGE_REQUEST,
  payload: null,
});

const updateShowLandingPageSuccess = response => ({
  type: actionTypes.UPDATE_SHOW_LANDING_PAGE_SUCCESS,
  payload: response,
});

const updateShowLandingPageFailure = err => ({
  type: actionTypes.UPDATE_SHOW_LANDING_PAGE_FAILURE,
  payload: { error: err.stack },
});

// Set verification preferences
const setUserVerificationPrefEmail = response => ({
  type: actionTypes.SET_PREFERENCE_EMAIL,
  payload: response,
});

const setUserVerificationPrefPhone = response => ({
  type: actionTypes.SET_PREFERENCE_PHONE,
  payload: response,
});

// Forget me
const forgetMeThunk = history => async (dispatch, getState) => {
  try {
    const state = getState();
    if (selectors.getIsForgetting(state)) {
      return Promise.resolve();
    }
    dispatch(forgetMeRequest());
    const response = await api.deleteCurrentUser();
    if (get(response, "ok", false)) {
      localStorage.removeItem("token");
      window.location.href = "/home";
    } else {
      throw new Error("Failed to forget user");
    }
  } catch (err) {
    dispatch(forgetMeFailure(err));
  }
};

const forgetMeFailure = err => ({
  type: actionTypes.FORGET_ME_FAILURE,
  payload: { error: err },
});

const forgetMeRequest = () => ({
  type: actionTypes.FORGET_ME_REQUEST,
  payload: {},
});

// Fetch interests list
const interestsListRequest = () => ({
  type: actionTypes.INTERESTS_LIST_REQUEST,
  payload: null,
});

const interestsListSuccess = response => ({
  type: actionTypes.INTERESTS_LIST_SUCCESS,
  payload: response,
});

const interestsListFailure = err => ({
  type: actionTypes.INTERESTS_LIST_FAILURE,
  payload: { error: err.stack },
});

// Fetch interests author list
const interestsAuthorListRequest = () => ({
  type: actionTypes.INTERESTS_AUTHOR_LIST_REQUEST,
  payload: null,
});

const interestsAuthorListSuccess = response => ({
  type: actionTypes.INTERESTS_AUTHOR_LIST_SUCCESS,
  payload: response,
});

const interestsAuthorListFailure = err => ({
  type: actionTypes.INTERESTS_AUTHOR_LIST_FAILURE,
  payload: { error: err.stack },
});

// Clear interest lists
const clearInterestsList = () => ({
  type: actionTypes.CLEAR_INTERESTS_LIST,
  payload: null,
});

const clearInterestsAuthorList = () => ({
  type: actionTypes.CLEAR_INTERESTS_AUTHOR_LIST,
  payload: null,
});

// Fetch tourtle item
const tourtleItemRequest = () => ({
  type: actionTypes.TOURTLE_ITEM_REQUEST,
  payload: null,
});

const tourtleItemSuccess = response => ({
  type: actionTypes.TOURTLE_ITEM_SUCCESS,
  payload: response,
});

const tourtleItemFailure = err => ({
  type: actionTypes.TOURTLE_ITEM_FAILURE,
  payload: { error: err.stack },
});

// Clear tourtle item
const clearTourtleItem = () => ({
  type: actionTypes.TOURTLE_ITEM_CLEAR,
  payload: null,
});

// Open and close jump to step dialog
const fireJumpToStepDialogOpening = () => ({
  type: actionTypes.JUMP_TO_STEP_DIALOG_OPENING,
  payload: null,
});

const fireJumpToStepDialogClosing = () => ({
  type: actionTypes.JUMP_TO_STEP_DIALOG_CLOSING,
  payload: null,
});

// Open and close image gallery
const fireImageGalleryClosing = () => ({
  type: actionTypes.IMAGE_GALLERY_CLOSING,
  payload: null,
});

const fireImageGalleryOpening = (
  type,
  images,
  initialImage,
  stepTitles,
  stepDescriptions,
  videos
) => ({
  type: actionTypes.IMAGE_GALLERY_OPENING,
  payload: { type, images, initialImage, stepTitles, stepDescriptions, videos },
});

// Fetch tourtle comments
const commentsRequest = () => ({
  type: actionTypes.COMMENTS_REQUEST,
  payload: null,
});

const commentsSuccess = response => ({
  type: actionTypes.COMMENTS_SUCCESS,
  payload: response,
});

const commentsFailure = err => ({
  type: actionTypes.COMMENTS_FAILURE,
  payload: { error: err.stack },
});

// Add and remove linked tourtle index
const addLinkedTourtleIndex = (id, index) => ({
  type: actionTypes.ADD_LINKED_TOURTLE_INDEX,
  payload: { id: id, index: index },
});

const addLinkedTourtleIndexCover = (id, index) => ({
  type: actionTypes.ADD_LINKED_TOURTLE_INDEX_COVER,
  payload: { id: id, index: index },
});

const removeLinkedTourtleIndex = () => ({
  type: actionTypes.REMOVE_LINKED_TOURTLE_INDEX,
  payload: null,
});

// Open and close add step dialog
const fireAddStepDialogClosing = () => ({
  type: actionTypes.ADD_STEP_DIALOG_CLOSING,
  payload: null,
});

const fireAddStepDialogOpening = ({
  first = false,
  addBefore = false,
  fromCoverPage = false,
  ...step
}) => ({
  type: actionTypes.ADD_STEP_DIALOG_OPENING,
  payload: {
    step,
    first,
    addBefore,
    fromCoverPage,
  },
});

// Set current step index
const setCurrentStepIndex = index => ({
  type: actionTypes.SET_CURRENT_STEP_INDEX,
  payload: index,
});

const finishStepAdd = add => ({
  type: actionTypes.FINISH_STEP_ADD,
  payload: add,
});

const resetCurrentStepIndex = () => ({
  type: actionTypes.RESET_CURRENT_STEP_INDEX,
  payload: null,
});

const storeTourtleCoverPageTopOffset = offset => ({
  type: actionTypes.SET_COVER_PAGE_TOP_OFFSET,
  payload: offset,
});

// Set active tourtle id
const setActiveTourtleId = tourtleId => ({
  type: actionTypes.SET_ACTIVE_TOURTLE_ID,
  payload: tourtleId,
});

// Logout current user
const logoutCurrentUserFailure = err => ({
  type: actionTypes.LOGOUT_CURRENT_USER_FAILURE,
  payload: err,
});

const logoutCurrentUserRequest = response => ({
  type: actionTypes.LOGOUT_CURRENT_USER_REQUEST,
  payload: response,
});

const logoutCurrentUserSuccess = () => ({
  type: actionTypes.LOGOUT_CURRENT_USER_SUCCESS,
  payload: {},
});

// Add step from coverpage
const fireAddingStepFromCoverPageOn = () => ({
  type: actionTypes.ADD_STEP_INSIDE_TOURTLE_ON,
  payload: null,
});

const fireAddingStepFromCoverPageOff = () => ({
  type: actionTypes.ADD_STEP_INSIDE_TOURTLE_OFF,
  payload: null,
});

// Edit step from coverpage
const fireEditingStepFromCoverPageOn = () => ({
  type: actionTypes.EDIT_STEP_INSIDE_TOURTLE_ON,
  payload: null,
});

const fireEditingStepFromCoverPageOff = () => ({
  type: actionTypes.EDIT_STEP_INSIDE_TOURTLE_OFF,
  payload: null,
});

// Create ref on add item
const createRefOnAddItem = id => ({
  type: actionTypes.ADD_REFERENCE,
  payload: id,
});

const removeRefOnAddItem = () => ({
  type: actionTypes.REMOVE_REFERENCE,
  payload: null,
});

// Cancel adding step
const cancelAddingStep = boolean => ({
  type: actionTypes.CANCEL_ADDING_STEP,
  payload: boolean,
});

// Clear step counter
const clearStepCounter = () => ({
  type: actionTypes.CLEAR_STEP_COUNTER,
  payload: null,
});

// Open and close delete step dialog
const fireDeleteStepDialogClosing = () => ({
  type: actionTypes.DELETE_STEP_DIALOG_CLOSING,
  payload: null,
});

const fireDeleteStepDialogOpening = step => ({
  type: actionTypes.DELETE_STEP_DIALOG_OPENING,
  payload: {
    step,
  },
});

// Open and close duplicate step
const fireDuplicateStepDialogOpening = (step, stepIndex) => ({
  type: actionTypes.DUPLICATE_STEP_DIALOG_OPENING,
  payload: {
    step: step,
    stepIndex: stepIndex,
  },
});

const fireDuplicateStepDialogClosing = () => ({
  type: actionTypes.DUPLICATE_STEP_DIALOG_CLOSING,
  payload: null,
});

// Duplicate step
const duplicateStepRequest = () => ({
  type: actionTypes.DUPLICATE_STEP_REQUEST,
  payload: null,
});

const duplicateStepSuccess = (tourtleId, step, stepIndex) => ({
  type: actionTypes.DUPLICATE_STEP_SUCCESS,
  payload: { tourtleId, step, stepIndex },
});

const duplicateStepFailure = err => ({
  type: actionTypes.DUPLICATE_STEP_FAILURE,
  payload: { error: err.stack },
});

// Open and close move step
const fireMoveStepDialogOpening = (step, stepIndex) => ({
  type: actionTypes.MOVE_STEP_DIALOG_OPENING,
  payload: {
    step,
  },
});

const fireMoveStepDialogClosing = () => ({
  type: actionTypes.MOVE_STEP_DIALOG_CLOSING,
  payload: null,
});

const onDragStep = (id, index) => ({
  type: actionTypes.DRAG_STEP,
  payload: { id, index },
});

// Move step
const moveStepRequest = () => ({
  type: actionTypes.MOVE_STEP_REQUEST,
  payload: null,
});

const moveStepSuccess = response => ({
  type: actionTypes.MOVE_STEP_SUCCESS,
  payload: response,
});

const moveStepFailure = err => ({
  type: actionTypes.MOVE_STEP_FAILURE,
  payload: { error: err.stack },
});

// Update tourtle
const updateTourtleRequest = () => ({
  type: actionTypes.UPDATE_TOURTLE_REQUEST,
  payload: null,
});

const updateTourtleSuccess = response => ({
  type: actionTypes.UPDATE_TOURTLE_SUCCESS,
  payload: response,
});

const updateTourtleFailure = err => ({
  type: actionTypes.UPDATE_TOURTLE_FAILURE,
  payload: { error: err.stack },
});

// Add tourtle
const createTourtleFailure = err => ({
  type: actionTypes.CREATE_TOURTLE_FAILURE,
  payload: { error: err.stack },
});

const createTourtleRequest = tourtleData => ({
  type: actionTypes.CREATE_TOURTLE_REQUEST,
  payload: tourtleData,
});

const createTourtleSuccess = response => ({
  type: actionTypes.CREATE_TOURTLE_SUCCESS,
  payload: response,
});

// Create step
const createStepFailure = err => ({
  type: actionTypes.CREATE_STEP_FAILURE,
  payload: { error: err.stack },
});

const createStepRequest = () => ({
  type: actionTypes.CREATE_STEP_REQUEST,
  payload: null,
});

const createStepAfterSuccess = (response, position, useAsCoverImage) => ({
  type: actionTypes.CREATE_STEP_AFTER_SUCCESS,
  payload: { response, position, useAsCoverImage },
});

// Open and close content moderation dialog
const openContentModerationDialog = (res, type, title, stepNumber) => ({
  type: actionTypes.OPEN_CONTENT_MODERATION_DIALOG,
  payload: { res, type, title, stepNumber },
});

const closeContentModerationDialog = () => ({
  type: actionTypes.CLOSE_CONTENT_MODERATION_DIALOG,
  payload: null,
});

// Update step
const updateStepRequest = () => ({
  type: actionTypes.UPDATE_STEP_REQUEST,
  payload: null,
});

const updateStepSuccess = (response, useAsCoverImage) => ({
  type: actionTypes.UPDATE_STEP_SUCCESS,
  payload: { response, useAsCoverImage },
});

const updateStepFailure = err => ({
  type: actionTypes.UPDATE_STEP_FAILURE,
  payload: { error: err.stack },
});

const setUpdateStepToFalse = () => ({
  type: actionTypes.UPDATE_STEP_IS_TEAPOT,
  payload: null,
});

// Set tabbed list scroll position
const setTabbedListScrollPosition = (tourtleId, list) => ({
  type: actionTypes.SET_TABBED_LIST_SCROLL_POSITION,
  payload: { tourtleId, list },
});

const resetTabbedListScrollPosition = () => ({
  type: actionTypes.RESET_TABBED_LIST_SCROLL_POSITION,
  payload: null,
});

// Fetch welcome tourtle
const fetchWelcomeTourtleRequest = () => ({
  type: actionTypes.FETCH_WELCOME_TOURTLE_REQUEST,
  payload: null,
});

const fetchWelcomeTourtleFailure = err => ({
  type: actionTypes.FETCH_WELCOME_TOURTLE_FAILURE,
  payload: { error: err },
});

const fetchWelcomeTourtle = res => ({
  type: actionTypes.FETCH_WELCOME_TOURTLE,
  payload: res,
});

// Open and close Search dialog
const fireSearchDialogClosing = () => ({
  type: actionTypes.SEARCH_DIALOG_CLOSING,
  payload: null,
});

const fireSearchDialogOpening = () => ({
  type: actionTypes.SEARCH_DIALOG_OPENING,
  payload: null,
});

// Fetch search list
const searchListFailure = err => ({
  type: actionTypes.SEARCH_LIST_FAILURE,
  payload: { error: err.stack },
});

const searchListRequest = () => ({
  type: actionTypes.SEARCH_LIST_REQUEST,
  payload: null,
});

const searchListSuccess = response => ({
  type: actionTypes.SEARCH_LIST_SUCCESS,
  payload: response,
});

// Open and close filter dialog
const fireFilterDialogClosing = () => ({
  type: actionTypes.FILTER_DIALOG_CLOSING,
  payload: null,
});

const fireFilterDialogOpening = () => ({
  type: actionTypes.FILTER_DIALOG_OPENING,
  payload: null,
});

// Reset search filters
const resetSearchFilters = () => ({
  type: actionTypes.RESET_SEARCH_FILTERS,
  payload: null,
});

// Fetch group item
const groupItemRequest = () => ({
  type: actionTypes.GROUP_ITEM_REQUEST,
  payload: null,
});

const groupItemSuccess = response => ({
  type: actionTypes.GROUP_ITEM_SUCCESS,
  payload: response,
});

const groupItemFailure = err => ({
  type: actionTypes.GROUP_ITEM_FAILURE,
  payload: { error: err.stack },
});

// Create group
const createGroupFailure = err => ({
  type: actionTypes.CREATE_GROUP_FAILURE,
  payload: { error: err.stack },
});

const createGroupRequest = () => ({
  type: actionTypes.CREATE_GROUP_REQUEST,
  payload: null,
});

const createGroupSuccess = response => ({
  type: actionTypes.CREATE_GROUP_SUCCESS,
  payload: response,
});

// Update group
const updateGroupFailure = err => ({
  type: actionTypes.UPDATE_GROUP_FAILURE,
  payload: { error: err.stack },
});

const updateGroupRequest = () => ({
  type: actionTypes.UPDATE_GROUP_REQUEST,
  payload: null,
});

const updateGroupSuccess = response => ({
  type: actionTypes.UPDATE_GROUP_SUCCESS,
  payload: response,
});

// Update group visibility
const updateGroupVisibility = (updated, previous) => ({
  type: actionTypes.UPDATE_GROUP_VISIBILITY,
  payload: { updated, previous },
});

const closeGroupVisibility = () => ({
  type: actionTypes.CLOSE_GROUP_VISIBILITY,
  payload: null,
});

// Fetch group tourtle list
const groupTourtleListFailure = err => ({
  type: actionTypes.GROUP_TOURTLE_LIST_FAILURE,
  payload: { error: err.stack },
});

const groupTourtleListRequest = () => ({
  type: actionTypes.GROUP_TOURTLE_LIST_REQUEST,
  payload: null,
});

const groupTourtleListSuccess = response => ({
  type: actionTypes.GROUP_TOURTLE_LIST_SUCCESS,
  payload: {
    data: response.data,
    ctoken: response.ctoken,
    hasNext: response.hasNext,
  },
});

const setGroupTourtleListQuery = query => ({
  type: actionTypes.SET_GROUP_TOURTLE_LIST_QUERY,
  payload: {
    query,
  },
});

const clearGroupTourtleListQuery = query => ({
  type: actionTypes.CLEAR_GROUP_TOURTLE_LIST_QUERY,
  payload: null,
});

// Position change
const fireGroupPositionChangeRequest = () => ({
  type: actionTypes.CHANGE_GROUP_TOURTLE_POSITION_REQUEST,
  payload: null,
});

const fireGroupPositionChangeSuccess = (id, position) => ({
  type: actionTypes.CHANGE_GROUP_TOURTLE_POSITION_SUCCESS,
  payload: { id, position },
});

const fireGroupPositionChangeFailure = err => ({
  type: actionTypes.CHANGE_GROUP_TOURTLE_POSITION_FAILURE,
  payload: { error: err.stack },
});

// Join group
const joinGroupFailure = err => ({
  type: actionTypes.JOIN_GROUP_FAILURE,
  payload: { error: err.stack },
});

const joinGroupRequest = () => ({
  type: actionTypes.JOIN_GROUP_REQUEST,
  payload: null,
});

const joinGroupSuccess = id => ({
  type: actionTypes.JOIN_GROUP_SUCCESS,
  payload: id,
});

// Leave group
const leaveGroupFailure = err => ({
  type: actionTypes.LEAVE_GROUP_FAILURE,
  payload: { error: err.stack },
});

const leaveGroupRequest = () => ({
  type: actionTypes.LEAVE_GROUP_REQUEST,
  payload: null,
});

const leaveGroupSuccess = id => ({
  type: actionTypes.LEAVE_GROUP_SUCCESS,
  payload: id,
});

// Favorite group
const favoriteGroupFailure = err => ({
  type: actionTypes.FAVORITE_GROUP_FAILURE,
  payload: { error: err.stack },
});

const favoriteGroupRequest = () => ({
  type: actionTypes.FAVORITE_GROUP_REQUEST,
  payload: null,
});

const favoriteGroupSuccess = id => ({
  type: actionTypes.FAVORITE_GROUP_SUCCESS,
  payload: id,
});

// Unfavorite group
const unfavoriteGroupFailure = err => ({
  type: actionTypes.UNFAVORITE_GROUP_FAILURE,
  payload: { error: err.stack },
});

const unfavoriteGroupRequest = () => ({
  type: actionTypes.UNFAVORITE_GROUP_REQUEST,
  payload: null,
});

const unfavoriteGroupSuccess = id => ({
  type: actionTypes.UNFAVORITE_GROUP_SUCCESS,
  payload: id,
});

// Toggle group notifications
const toggleGroupNotificationsSuccess = (id, newStatus) => ({
  type: actionTypes.TOGGLE_GROUP_NOTIFICATIONS,
  payload: { id: id, newStatus: newStatus },
});

// Open and close add member menu
const fireAddMemberMenuOpening = () => ({
  type: actionTypes.ADD_MEMBER_MENU_OPENING,
  payload: null,
});

const fireAddViewersOpening = () => ({
  type: actionTypes.ADD_VIEWERS_OPENING,
  payload: null,
});

const fireAddMemberMenuClosing = addedMembers => ({
  type: actionTypes.ADD_MEMBER_MENU_CLOSING,
  payload: { addedMembers },
});

const fireAddViewersClosing = addedMembers => ({
  type: actionTypes.ADD_VIEWERS_CLOSING,
  payload: { addedMembers },
});

// Open and close inivitation dialog
const fireInvitationDialogOpen = () => ({
  type: actionTypes.INVITATION_DIALOG_OPENING,
  payload: null,
});

const fireInvitationDialogClose = () => ({
  type: actionTypes.INVITATION_DIALOG_CLOSING,
  payload: null,
});

// Open and close delete group dialog
const fireDeleteGroupDialogClosing = () => ({
  type: actionTypes.DELETE_GROUP_DIALOG_CLOSING,
  payload: null,
});

const fireDeleteGroupDialogOpening = group => ({
  type: actionTypes.DELETE_GROUP_DIALOG_OPENING,
  payload: {
    group,
  },
});

// Open and close last admin dialog
const fireLastAdminDialogClose = () => ({
  type: actionTypes.LAST_ADMIN_DIALOG_CLOSING,
  payload: null,
});

const fireLastAdminDialogOpen = tourtleId => ({
  type: actionTypes.LAST_ADMIN_DIALOG_OPENING,
  payload: {
    tourtleId,
  },
});

// Open and close leave group dialog
const fireLeaveGroupDialogOpening = () => ({
  type: actionTypes.LEAVE_GROUP_DIALOG_OPENING,
  payload: null,
});

const fireLeaveGroupDialogClosing = () => ({
  type: actionTypes.LEAVE_GROUP_DIALOG_CLOSING,
  payload: null,
});

// Open and close remove tourtle from group dialog
const fireRemoveTourtleFromGroupDialogClose = () => ({
  type: actionTypes.REMOVE_TOURTLE_FROM_GROUP_DIALOG_CLOSING,
  payload: null,
});

const fireRemoveTourtleFromGroupDialogOpen = (tourtle, group) => ({
  type: actionTypes.REMOVE_TOURTLE_FROM_GROUP_DIALOG_OPENING,
  payload: {
    tourtle,
    group,
  },
});

// Fetch group members
const fetchGroupMemberFailure = err => ({
  type: actionTypes.FETCH_GROUP_MEMBER_FAILURE,
  payload: { error: err.stack },
});

const fetchGroupMemberRequest = () => ({
  type: actionTypes.FETCH_GROUP_MEMBER_REQUEST,
  payload: null,
});

const fetchGroupMemberSuccess = response => ({
  type: actionTypes.FETCH_GROUP_MEMBER_SUCCESS,
  payload: response,
});

// Remove group member
const removeMemberFailure = err => ({
  type: actionTypes.REMOVE_MEMBER_FAILURE,
  payload: { error: err.stack },
});

const removeMemberRequest = () => ({
  type: actionTypes.REMOVE_MEMBER_REQUEST,
  payload: null,
});

const removeMemberSuccess = response => ({
  type: actionTypes.REMOVE_MEMBER_SUCCESS,
  payload: response,
});

// Change group member
const changeGroupMemberFailure = err => ({
  type: actionTypes.CHANGE_GROUP_MEMBER_FAILURE,
  payload: { error: err.stack },
});

const changeGroupMemberRequest = () => ({
  type: actionTypes.CHANGE_GROUP_MEMBER_REQUEST,
  payload: null,
});

const changeGroupMemberSuccess = response => ({
  type: actionTypes.CHANGE_GROUP_MEMBER_SUCCESS,
  payload: response,
});

// Set active group id
const setActiveGroupId = groupId => ({
  type: actionTypes.SET_ACTIVE_GROUP_ID,
  payload: groupId,
});

// Open and close revoke invitation dialog
const fireRevokeInvitationDialogOpening = id => ({
  type: actionTypes.REVOKE_INVITATION_DIALOG_OPENING,
  payload: id,
});

const fireRevokeInvitationDialogClosing = () => ({
  type: actionTypes.REVOKE_INVITATION_DIALOG_CLOSING,
  payload: null,
});

// Fetch group invitations
const fetchGroupInvitationsFailure = err => ({
  type: actionTypes.FETCH_GROUP_INVITATIONS_FAILURE,
  payload: { error: err.stack },
});

const fetchGroupInvitationsRequest = () => ({
  type: actionTypes.FETCH_GROUP_INVITATIONS_REQUEST,
  payload: null,
});

const fetchGroupInvitationsSuccess = response => ({
  type: actionTypes.FETCH_GROUP_INVITATIONS_SUCCESS,
  payload: response,
});

// Accept request
const acceptRequestFailure = err => ({
  type: actionTypes.ACCEPT_REQUEST_FAILURE,
  payload: { error: err.stack },
});

const acceptRequestRequest = () => ({
  type: actionTypes.ACCEPT_REQUEST_REQUEST,
  payload: null,
});

const acceptRequestSuccess = response => ({
  type: actionTypes.ACCEPT_REQUEST_SUCCESS,
  payload: response,
});

// Reject request
const rejectRequestFailure = err => ({
  type: actionTypes.REJECT_REQUEST_FAILURE,
  payload: { error: err.stack },
});

const rejectRequestRequest = () => ({
  type: actionTypes.REJECT_REQUEST_REQUEST,
  payload: null,
});

const rejectRequestSuccess = response => ({
  type: actionTypes.REJECT_REQUEST_SUCCESS,
  payload: response,
});

// Accept tourtle
const acceptTourtleRequestFailure = err => ({
  type: actionTypes.ACCEPT_TOURTLE_REQUEST_FAILURE,
  payload: { error: err.stack },
});

const acceptTourtleRequestRequest = () => ({
  type: actionTypes.ACCEPT_TOURTLE_REQUEST_REQUEST,
  payload: null,
});

const acceptTourtleRequestSuccess = response => ({
  type: actionTypes.ACCEPT_TOURTLE_REQUEST_SUCCESS,
  payload: response,
});

// Reject tourtle
const rejectTourtleRequestFailure = err => ({
  type: actionTypes.REJECT_TOURTLE_REQUEST_FAILURE,
  payload: { error: err.stack },
});

const rejectTourtleRequestRequest = () => ({
  type: actionTypes.REJECT_TOURTLE_REQUEST_REQUEST,
  payload: null,
});

const rejectTourtleRequestSuccess = response => ({
  type: actionTypes.REJECT_TOURTLE_REQUEST_SUCCESS,
  payload: response,
});

// Fetch pending tourtles
const fetchPendingTourtleItemsFailure = err => ({
  type: actionTypes.FETCH_PENDING_TOURTLE_FAILURE,
  payload: { error: err.stack },
});

const fetchPendingTourtleItemsRequest = () => ({
  type: actionTypes.FETCH_PENDING_TOURTLE_REQUEST,
  payload: null,
});

const fetchPendingTourtleItemsSuccess = response => ({
  type: actionTypes.FETCH_PENDING_TOURTLE_SUCCESS,
  payload: response,
});

// Open and close move tourtle dialog
const fireMoveTourtleDialogClosing = () => ({
  type: actionTypes.MOVE_TOURTLE_DIALOG_CLOSING,
  payload: null,
});

const fireMoveTourtleDialogOpening = tourtle => ({
  type: actionTypes.MOVE_TOURTLE_DIALOG_OPENING,
  payload: tourtle,
});

// Open and close collection dialog
const fireDeleteCollectionDialogOpening = collection => ({
  type: actionTypes.DELETE_COLLECTION_DIALOG_OPENING,
  payload: collection,
});

const fireDeleteCollectionDialogClosing = () => ({
  type: actionTypes.DELETE_COLLECTION_DIALOG_CLOSING,
  payload: null,
});

// Delete collection
const deleteCollectionFailure = err => ({
  type: actionTypes.DELETE_COLLECTION_FAILURE,
  payload: { error: err.stack },
});

const deleteCollectionRequest = () => ({
  type: actionTypes.DELETE_COLLECTION_REQUEST,
  payload: null,
});

const deleteCollectionSuccess = id => ({
  type: actionTypes.DELETE_COLLECTION_SUCCESS,
  payload: id,
});

// Fetch one collection item
const collectionItemRequest = () => ({
  type: actionTypes.COLLECTION_ITEM_REQUEST,
  payload: null,
});

const collectionItemSuccess = response => ({
  type: actionTypes.COLLECTION_ITEM_SUCCESS,
  payload: response,
});

const collectionItemFailure = err => ({
  type: actionTypes.COLLECTION_ITEM_FAILURE,
  payload: { error: err.stack },
});

// Fetch all tourtles for collection
const fetchTourtlesForCollectionFailure = err => ({
  type: actionTypes.TOURTLES_FOR_COLLECTION_FAILURE,
  payload: { error: err.stack },
});

const fetchTourtlesForCollectionRequest = () => ({
  type: actionTypes.TOURTLES_FOR_COLLECTION_REQUEST,
  payload: null,
});

const fetchTourtlesForCollectionSuccess = (response, id) => ({
  type: actionTypes.TOURTLES_FOR_COLLECTION_SUCCESS,
  payload: {
    id: id,
    data: response.data,
    ctoken: response.ctoken,
    hasNext: response.hasNext,
  },
});

// Update collection list item
const updateCollectionListItem = (response, id) => ({
  type: actionTypes.UPDATE_COLLECTION_AFTER_SUCCESS,
  payload: {
    id: id,
    data: response.data,
    ctoken: response.ctoken,
    hasNext: response.hasNext,
  },
});

// Position change
const firePositionChangeRequest = () => ({
  type: actionTypes.CHANGE_TOURTLE_POSITION_REQUEST,
  payload: null,
});

const firePositionChangeSuccess = (id, position) => ({
  type: actionTypes.CHANGE_TOURTLE_POSITION_SUCCESS,
  payload: { id, position },
});

const firePositionChangeFailure = err => ({
  type: actionTypes.CHANGE_TOURTLE_POSITION_FAILURE,
  payload: { error: err.stack },
});

// Clear collection state
const clearCollectionState = () => ({
  type: actionTypes.CLEAR_COLLECTION_STATE,
  payload: null,
});

// Add tourtles to collection
const addTourtlesToCollectionRequest = () => ({
  type: actionTypes.ADD_TOURTLE_TO_COLLECTION_REQUEST,
  payload: null,
});

const addTourtlesToCollectionSuccess = res => ({
  type: actionTypes.ADD_TOURTLE_TO_COLLECTION_SUCCESS,
  payload: res,
});

const addTourtlesToCollectionFailure = error => ({
  type: actionTypes.ADD_TOURTLE_TO_COLLECTION_FAILURE,
  payload: error,
});

// Remove tourtles from collection
const removeTourtlesToCollectionRequest = () => ({
  type: actionTypes.REMOVE_TOURTLE_TO_COLLECTION_REQUEST,
  payload: null,
});

const removeTourtlesToCollectionSuccess = (tourtleId, collectionId, res) => ({
  type: actionTypes.REMOVE_TOURTLE_TO_COLLECTION_SUCCESS,
  payload: { tourtleId, collectionId, res },
});

const removeTourtlesToCollectionFailure = error => ({
  type: actionTypes.REMOVE_TOURTLE_TO_COLLECTION_FAILURE,
  payload: error,
});

// Update collection

const updateCollectionFailure = err => ({
  type: actionTypes.UPDATE_COLLECTION_FAILURE,
  payload: { error: err.stack },
});

const updateCollectionRequest = () => ({
  type: actionTypes.UPDATE_COLLECTION_REQUEST,
  payload: null,
});

const updateCollectionSuccess = response => ({
  type: actionTypes.UPDATE_COLLECTION_SUCCESS,
  payload: response,
});

// Position Change (collections)
const fireCollectionPositionChangeRequest = () => ({
  type: actionTypes.CHANGE_COLLECTION_POSITION_REQUEST,
  payload: null,
});

const fireCollectionPositionChangeSuccess = (id, position) => ({
  type: actionTypes.CHANGE_COLLECTION_POSITION_SUCCESS,
  payload: { id, position },
});

const fireCollectionPositionChangeFailure = err => ({
  type: actionTypes.CHANGE_COLLECTION_POSITION_FAILURE,
  payload: { error: err.stack },
});

const moveCollectionPositionSuccess = collections => ({
  type: actionTypes.REORDER_COLLECTION_SUCCESS,
  payload: { collections },
});

// Clear list search
const clearListSearch = () => ({
  type: actionTypes.SEARCH_CLEAR_LIST,
  payload: null,
});

// Set search query
const setSearchQuery = query => ({
  type: actionTypes.SET_SEARCH_QUERY,
  payload: query,
});

const setSearchQueryGroup = query => ({
  type: actionTypes.SET_SEARCH_QUERY_GROUPS,
  payload: query,
});

// Initiate clear collection state
const initiateClearCollectionState = () => ({
  type: actionTypes.INITIATE_CLEAR_COLLECTION_STATE,
  payload: null,
});

// Clear selected tourtle list
const clearSelectedTourtleList = () => ({
  type: actionTypes.CLEAR_SELECTED_TOURTLE_LIST,
  payload: null,
});

// Dispatch result length
const dispatchResultLength = (num, type) => ({
  type: actionTypes.DISPATCH_RESULT_VALUES,
  payload: {
    num: num,
    type: type,
  },
});

// Create collection
const createCollectionFailure = err => ({
  type: actionTypes.CREATE_COLLECTION_FAILURE,
  payload: { error: err.stack },
});

const createCollectionRequest = () => ({
  type: actionTypes.CREATE_COLLECTION_REQUEST,
  payload: null,
});

const createCollectionSuccess = response => ({
  type: actionTypes.CREATE_COLLECTION_SUCCESS,
  payload: response,
});

// Fetch collaborators
const fetchCollaboratorsFailure = err => ({
  type: actionTypes.FETCH_COLLABORATORS_FAILURE,
  payload: { error: err.stack },
});

const fetchCollaboratorsRequest = () => ({
  type: actionTypes.FETCH_COLLABORATORS_REQUEST,
  payload: null,
});

const fetchCollaboratorsSuccess = response => ({
  type: actionTypes.FETCH_COLLABORATORS_SUCCESS,
  payload: response,
});

// Remove collaborators
const removeCollaboratorFailure = err => ({
  type: actionTypes.REMOVE_COLLABORATOR_FAILURE,
  payload: { error: err.stack },
});

const removeCollaboratorRequest = () => ({
  type: actionTypes.REMOVE_COLLABORATOR_REQUEST,
  payload: null,
});

const removeCollaboratorSuccess = (userId, tourtleId, self) => ({
  type: actionTypes.REMOVE_COLLABORATOR_SUCCESS,
  payload: { userId, tourtleId, self },
});

// Link tourtle as step
const linkTourtleToTourtleRequest = () => ({
  type: actionTypes.LINK_TOURTLE_TO_TOURTLE_REQUEST,
  payload: null,
});

const linkTourtleToTourtleSuccess = (addedTourtles, stepIndex, tourtleId) => ({
  type: actionTypes.LINK_TOURTLE_TO_TOURTLE_SUCCESS,
  payload: { addedTourtles, stepIndex, tourtleId },
});

const linkTourtleToTourtleFailure = err => ({
  type: actionTypes.LINK_TOURTLE_TO_TOURTLE_FAILURE,
  payload: { error: err.stack },
});

// Upload image
const uploadImageFailure = err => ({
  type: actionTypes.UPLOAD_IMAGE_FAILURE,
  payload: { error: err.stack },
});

const uploadImageRequest = () => ({
  type: actionTypes.UPLOAD_IMAGE_REQUEST,
  payload: null,
});

const uploadImageSuccess = response => ({
  type: actionTypes.UPLOAD_IMAGE_SUCCESS,
  payload: response,
});

// Increase used storage
const increaseUsedStorage = storage => ({
  type: actionTypes.INCREASE_USED_STORAGE,
  payload: storage,
});

const adminAddUserThunk = history => async (dispatch, getState) => {
  try {
    const state = getState();
    if (get(state, "state.root.signUp.isSignUpInProgress", false)) {
      return Promise.resolve();
    }
    dispatch(signUpRequest());
    const username = selectors.getSignUpUsername(state);
    const channel = selectors.getChannelSelection(state);
    const emailAddress = selectors.getSignUpEmail(state);
    const phoneNumber = selectors.getSignUpPhone(state);
    const response = await api.adminAddUser({
      username,
      channel,
      address: channel === "email" ? emailAddress : phoneNumber,
    });
    if (response.statusCode) {
      dispatch(signUpFailure(response));
    } else {
      dispatch(signUpSuccess(response));
      dispatch(history.goBack());
    }
  } catch (err) {
    dispatch(signUpFailure(err));
  }
};

const adminDeleteUserThunk = (history, userId) => async (
  dispatch,
  getState
) => {
  try {
    const state = getState();
    if (selectors.getIsDeletingUser(state)) {
      return Promise.resolve();
    }
    dispatch(adminDeleteUserRequest());
    const response = await api.adminDeleteUser(userId);
    if (response.statusCode && response.statusCode !== 200) {
      throw new Error(`E_DELETE: Deletion failed with ${response.statusCode}`);
    }
    dispatch(adminDeleteUserSuccess(response));
    return true;
  } catch (err) {
    console.log(err);
    dispatch(adminDeleteUserFailure(err));
    return false;
  }
};

const adminDeleteUserFailure = err => ({
  type: actionTypes.ADMIN_DELETE_USER_FAILURE,
  payload: { error: err },
});

const adminDeleteUserRequest = () => ({
  type: actionTypes.ADMIN_DELETE_USER_REQUEST,
  payload: {},
});

const adminDeleteUserSuccess = response => ({
  type: actionTypes.ADMIN_DELETE_USER_SUCCESS,
  payload: { user: response },
});

const adminToggleUserActivationThunk = (userId, isActive) => async (
  dispatch,
  getState
) => {
  try {
    const state = getState();
    if (selectors.getIsFetchingAuthor(state)) {
      return Promise.resolve();
    }
    dispatch(fetchAuthorRequest());
    const response = await api.adminUpdateUser(userId, { isActive });
    dispatch(fetchAuthorSuccess(response));
  } catch (err) {
    dispatch(fetchAuthorFailure(err));
  }
};

const fireShareDialogClosing = () => ({
  type: actionTypes.SHARE_DIALOG_CLOSE,
  payload: null,
});

const fireShareDialogOpening = (item, type) => ({
  type: actionTypes.SHARE_DIALOG_OPEN,
  payload: { item: item, type: type },
});

const fireStepOpening = stepId => ({
  type: actionTypes.STEP_OPENING,
  payload: { stepId },
});

const fireDeleteImageDialogClosing = () => ({
  type: actionTypes.DELETE_IMAGE_DIALOG_CLOSING,
  payload: null,
});

const fireDeleteImageDialogOpening = tourtleId => ({
  type: actionTypes.DELETE_IMAGE_DIALOG_OPENING,
  payload: {
    tourtleId,
  },
});

const fireDeleteAudioDialogClosing = () => ({
  type: actionTypes.DELETE_AUDIO_DIALOG_CLOSING,
  payload: null,
});

const fireDeleteAudioDialogOpening = tourtleId => ({
  type: actionTypes.DELETE_AUDIO_DIALOG_OPENING,
  payload: {
    tourtleId,
  },
});

const fireDeleteVideoDialogClosing = () => ({
  type: actionTypes.DELETE_VIDEO_DIALOG_CLOSING,
  payload: null,
});

const fireDeleteVideoDialogOpening = tourtleId => ({
  type: actionTypes.DELETE_VIDEO_DIALOG_OPENING,
  payload: {
    tourtleId,
  },
});

const loginLinkRequestUnverified = ({ message, username }) => ({
  type: actionTypes.LOGIN_LINK_REQUEST_UNVERIFIED,
  payload: {
    msg: message,
    usr: username,
  },
});

// Remove linked tourtle
const fireRemoveLinkedTourtleDialogOpening = (tourtle, mainTourtle) => ({
  type: actionTypes.REMOVE_LINKED_TOURTLE_DIALOG_OPENING,
  payload: {
    tourtle,
    mainTourtle,
  },
});

const fireRemoveLinkedTourtleDialogClosing = () => ({
  type: actionTypes.REMOVE_LINKED_TOURTLE_DIALOG_CLOSING,
  payload: null,
});

const loginChannelAddressSet = channelAddress => {
  return {
    type: actionTypes.LOGIN_CHANNEL_ADDRESS_CHANGED,
    payload: {
      channelAddress,
    },
  };
};

const loginChannelAddressChanged = evt => {
  const channelAddress = evt.target.value;
  return {
    type: actionTypes.LOGIN_CHANNEL_ADDRESS_CHANGED,
    payload: {
      channelAddress,
    },
  };
};

const loginEmailChanged = evt => ({
  type: actionTypes.LOGIN_EMAIL_CHANGED,
  payload: { email: evt.target.value },
});

const loginCurrentUserFailure = err => ({
  type: actionTypes.LOGIN_CURRENT_USER_FAILURE,
  payload: err,
});

const loginCurrentUserRequest = () => ({
  type: actionTypes.LOGIN_CURRENT_USER_REQUEST,
  payload: {},
});

const loginCurrentUserSuccess = user => ({
  type: actionTypes.LOGIN_CURRENT_USER_SUCCESS,
  payload: user,
});

const loginLinkRequestFailure = err => ({
  type: actionTypes.LOGIN_LINK_REQUEST_FAILURE,
  payload: {
    error: err.stack,
  },
});

const loginLinkRequestInvalid = msg => ({
  type: actionTypes.LOGIN_LINK_REQUEST_INVALID,
  payload: {
    msg,
  },
});
const loginLinkRequestUnauthorized = ({ message, username }) => ({
  type: actionTypes.LOGIN_LINK_REQUEST_UNAUTHORIZED,
  payload: {
    msg: message,
    usr: username,
  },
});

const loginLinkRequestRequest = () => ({
  type: actionTypes.LOGIN_LINK_REQUEST_REQUEST,
  payload: null,
});

const loginLinkRequestSuccess = () => ({
  type: actionTypes.LOGIN_LINK_REQUEST_SUCCESS,
  payload: null,
});

const adminRefreshStatsFailure = err => ({
  type: actionTypes.ADMIN_REFRESH_STATS_FAILURE,
  payload: {
    error: {
      message: err && err.message,
      stack: err && err.stack,
    },
  },
});

const adminRefreshStatsRequest = () => ({
  type: actionTypes.ADMIN_REFRESH_STATS_REQUEST,
  payload: {},
});

const adminRefreshStatsSuccess = () => ({
  type: actionTypes.ADMIN_REFRESH_STATS_SUCCESS,
  payload: {},
});

const adminRefreshStatsThunk = () => async (dispatch, getState) => {
  try {
    const state = getState();
    if (selectors.getIsRefreshingStats(state)) return Promise.resolve();
    dispatch(adminRefreshStatsRequest());
    const result = await api.adminRefreshStats();
    if (!get(result, "ok", false)) {
      throw new Error(`E_REFRESH: Failed with status ${result.statusCode}`);
    }
    dispatch(adminRefreshStatsSuccess());
  } catch (err) {
    dispatch(adminRefreshStatsFailure(err));
  }
};

const adminUpdateIsVerifiedAndIsActiveFailure = err => ({
  type: actionTypes.ADMIN_UPDATE_FLAGS_FAILURE,
  payload: {
    error: {
      message: err && err.message,
      stack: err && err.stack,
    },
  },
});

const adminUpdateIsVerifiedAndIsActiveRequest = () => ({
  type: actionTypes.ADMIN_UPDATE_FLAGS_REQUEST,
  payload: {},
});

const adminUpdateIsVerifiedAndIsActiveSuccess = () => ({
  type: actionTypes.ADMIN_UPDATE_FLAGS_SUCCESS,
  payload: {},
});

const adminUpdateIsVerifiedAndIsActiveThunk = () => async (
  dispatch,
  getState
) => {
  try {
    const state = getState();
    if (selectors.getIsUpdatingIsVerifiedAndIsActive(state))
      return Promise.resolve();
    dispatch(adminUpdateIsVerifiedAndIsActiveRequest());
    const result = await api.adminUpdateIsVerifiedAndIsActive();
    if (!get(result, "ok", false)) {
      throw new Error(`E_REFRESH: Failed with status ${result.statusCode}`);
    }
    dispatch(adminUpdateIsVerifiedAndIsActiveSuccess());
  } catch (err) {
    dispatch(adminUpdateIsVerifiedAndIsActiveFailure(err));
  }
};

const adminUpdateCategoriesFailure = err => ({
  type: actionTypes.ADMIN_UPDATE_CATEGORIES_FAILURE,
  payload: {
    error: {
      message: err && err.message,
      stack: err && err.stack,
    },
  },
});

const adminUpdateCategoriesRequest = () => ({
  type: actionTypes.ADMIN_UPDATE_CATEGORIES_REQUEST,
  payload: {},
});

const adminUpdateCategoriesSuccess = () => ({
  type: actionTypes.ADMIN_UPDATE_CATEGORIES_SUCCESS,
  payload: {},
});

const adminUpdateCategoriesThunk = () => async (dispatch, getState) => {
  try {
    const state = getState();
    if (selectors.getIsUpdatingCategories(state)) return Promise.resolve();
    dispatch(adminUpdateCategoriesRequest());
    const result = await api.adminUpdateCategories();
    if (!get(result, "ok", false)) {
      throw new Error(`E_REFRESH: Failed with status ${result.statusCode}`);
    }
    dispatch(adminUpdateCategoriesSuccess());
  } catch (err) {
    dispatch(adminUpdateCategoriesFailure(err));
  }
};

const adminFetchReportFailure = err => ({
  type: actionTypes.ADMIN_FETCH_REPORT_FAILURE,
  payload: {
    error: {
      message: err && err.message,
      stack: err && err.stack,
    },
  },
});

const adminFetchReportRequest = () => ({
  type: actionTypes.ADMIN_FETCH_REPORT_REQUEST,
  payload: {},
});

const adminFetchReportSuccess = report => ({
  type: actionTypes.ADMIN_FETCH_REPORT_SUCCESS,
  payload: report,
});

const adminFetchReportThunk = () => async (dispatch, getState) => {
  try {
    const state = getState();
    if (selectors.getIsFetchingReport(state)) return Promise.resolve();
    dispatch(adminFetchReportRequest());
    const report = await api.getGeneralReport();
    if (report.error) throw new Error(report.error);
    dispatch(adminFetchReportSuccess(report));
  } catch (err) {
    dispatch(adminFetchReportFailure(err));
  }
};

const adminFetchUsersReportThunk = () => async (dispatch, getState) => {
  try {
    const state = getState();
    if (selectors.getIsFetchingReport(state)) return Promise.resolve();
    dispatch(adminFetchReportRequest());
    const report = await api.getUsersReport();
    if (report.error) throw new Error(report.error);
    dispatch(adminFetchReportSuccess(report));
  } catch (err) {
    dispatch(adminFetchReportFailure(err));
  }
};

const signUpChannelSelected = channel => ({
  type: actionTypes.SIGN_UP_CHANNEL_SELECTED,
  payload: { channel },
});

const signUpEmailChanged = email => ({
  type: actionTypes.SIGN_UP_EMAIL_CHANGED,
  payload: { email: email },
});

const signUpPhoneChanged = evt => ({
  type: actionTypes.SIGN_UP_PHONE_CHANGED,
  payload: { phone: evt.target.value },
});

const signUpFailure = err => ({
  type: actionTypes.SIGN_UP_FAILURE,
  payload: { error: err.stack },
});

const signUpRequest = () => ({
  type: actionTypes.SIGN_UP_REQUEST,
  payload: null,
});

// TODO: this needs to change based on what real api returns
const signUpSuccess = response => ({
  type: actionTypes.SIGN_UP_SUCCESS,
  payload: { ...response },
});

const signUpFirstNameChanged = evt => ({
  type: actionTypes.SIGN_UP_FIRST_NAME_CHANGED,
  payload: { firstName: evt.target.value },
});

const signUpLastNameChanged = evt => ({
  type: actionTypes.SIGN_UP_LAST_NAME_CHANGED,
  payload: { lastName: evt.target.value },
});

const signUpCompanyChanged = evt => ({
  type: actionTypes.SIGN_UP_COMPANY_CHANGED,
  payload: { company: evt.target.value },
});

const signUpFirstNameCheckBadInput = error => ({
  type: actionTypes.SIGN_UP_FIRST_NAME_CHECK_BAD_INPUT,
  payload: error,
});

const signUpLastNameCheckBadInput = error => ({
  type: actionTypes.SIGN_UP_LAST_NAME_CHECK_BAD_INPUT,
  payload: error,
});

const signUpCompanyCheckBadInput = error => ({
  type: actionTypes.SIGN_UP_COMPANY_CHECK_BAD_INPUT,
  payload: error,
});

const signUpCheckIncompleteInput = error => ({
  type: actionTypes.SIGN_UP_CHECK_INCOMPLETE_INPUT,
  payload: error,
});

const signUpUsernameChanged = evt => ({
  type: actionTypes.SIGN_UP_USERNAME_CHANGED,
  payload: { username: evt.target.value },
});

const signUpUsernameCheckRequest = () => ({
  type: actionTypes.SIGN_UP_USERNAME_CHECK_REQUEST,
  payload: null,
});

const signUpUsernameCheckSuccess = response => ({
  type: actionTypes.SIGN_UP_USERNAME_CHECK_SUCCESS,
  payload: {
    ...response,
  },
});

const signUpUsernameCheckBadInput = value => ({
  type: actionTypes.SIGN_UP_USERNAME_CHECK_BAD_INPUT,
  payload: value,
});

const signUpUsernameCheckFailure = error => ({
  type: actionTypes.SIGN_UP_USERNAME_CHECK_FAILURE,
  payload: error,
});

const signUpUsernameCheckForbiddenInput = error => ({
  type: actionTypes.SIGN_UP_USERNAME_CHECK_FORBIDDEN_INPUT,
  payload: error,
});

const fireSignUpAddressTakenDialogOpening = () => ({
  type: actionTypes.SIGN_UP_ADDRESS_TAKEN_DIALOG_OPENING,
  payload: null,
});

const fireSignUpAddressTakenDialogClosing = () => ({
  type: actionTypes.SIGN_UP_ADDRESS_TAKEN_DIALOG_CLOSING,
  payload: null,
});

const startCommenting = () => ({
  type: actionTypes.ON_START_COMMENTING,
  payload: null,
});

const endCommenting = () => ({
  type: actionTypes.ON_END_COMMENTING,
  payload: null,
});

const uploadAudioFailure = err => ({
  type: actionTypes.UPLOAD_AUDIO_FAILURE,
  payload: { error: err.stack },
});

const uploadAudioRequest = () => ({
  type: actionTypes.UPLOAD_AUDIO_REQUEST,
  payload: null,
});

const loginGetCurrentUser = (login = false) => async (dispatch, getState) => {
  try {
    if (selectors.getIsFetchingCurrentUser(getState())) {
      return Promise.resolve();
    }

    dispatch(loginCurrentUserRequest());
    const response = await api.fetchMyUserInfo();
    await dispatch(loginCurrentUserSuccess(response));

    if (get(response, "statusCode", 200) === 200) {
      if (login) {
        window.localStorage.setItem(
          "signed_in_timestamp",
          JSON.stringify(Math.floor(Date.now() / 1000))
        );
      }
      window.localStorage.setItem(
        "auth_expiration_timestamp",
        response.credentials.settings.authExpiration
      );
    }
  } catch (err) {
    dispatch(loginCurrentUserFailure(err));
  }
};
const uploadAudioSuccess = response => ({
  type: actionTypes.UPLOAD_AUDIO_SUCCESS,
  payload: response,
});

const uploadVideoFailure = err => ({
  type: actionTypes.UPLOAD_VIDEO_FAILURE,
  payload: { error: err.stack },
});

const uploadVideoRequest = () => ({
  type: actionTypes.UPLOAD_VIDEO_REQUEST,
  payload: null,
});

const uploadVideoSuccess = response => ({
  type: actionTypes.UPLOAD_VIDEO_SUCCESS,
  payload: response,
});

const clearList = () => ({
  type: actionTypes.CLEAR_LIST,
  payload: null,
});

const clearListAuthor = () => ({
  type: actionTypes.CLEAR_LIST_AUTHOR,
  payload: null,
});

const addCollaboratorsFailure = err => ({
  type: actionTypes.ADD_COLLABORATORS_FAILURE,
  payload: { error: err.stack },
});

const addCollaboratorsRequest = () => ({
  type: actionTypes.ADD_COLLABORATORS_REQUEST,
  payload: null,
});

const addCollaboratorsSuccess = (tourtleId, response) => ({
  type: actionTypes.ADD_COLLABORATORS_SUCCESS,
  payload: { tourtleId: tourtleId, response: response },
});

// Update collaborator
const updateCollaboratorFailure = err => ({
  type: actionTypes.UPDATE_COLLABORATOR_FAILURE,
  paylore: { error: err.stack },
});

const updateCollaboratorRequest = () => ({
  type: actionTypes.UPDATE_COLLABORATOR_REQUEST,
  payload: null,
});

const updateCollaboratorSuccess = (collaboratorId, updatedInfo) => ({
  type: actionTypes.UPDATE_COLLABORATOR_SUCCESS,
  payload: {
    updatedCollaborator: {
      id: collaboratorId,
      ...updatedInfo,
    },
  },
});

// Switch collaborator type
const switchCollaboratorTypeFailure = err => ({
  type: actionTypes.SWITCH_COLLABORATOR_TYPE_FAILURE,
  payload: { error: err.stack },
});

const switchCollaboratorTypeRequest = () => ({
  type: actionTypes.SWITCH_COLLABORATOR_TYPE_REQUEST,
  payload: null,
});

const switchCollaboratorTypeSuccess = (collaboratorId, updatedInfo) => ({
  type: actionTypes.SWITCH_COLLABORATOR_TYPE_SUCCESS,
  payload: {
    updatedCollaborator: {
      id: collaboratorId,
      ...updatedInfo,
    },
  },
});

const openUpdateStepScreen = () => ({
  type: actionTypes.OPEN_UPDATE_STEP_SCREEN,
  payload: null,
});

const closeUpdateStepScreen = () => ({
  type: actionTypes.CLOSE_UPDATE_STEP_SCREEN,
  payload: null,
});

const deleteStepFailure = err => ({
  type: actionTypes.DELETE_STEP_FAILURE,
  payload: { error: err.stack },
});

const deleteStepRequest = () => ({
  type: actionTypes.DELETE_STEP_REQUEST,
  payload: null,
});

const deleteStepSuccess = (stepId, tourtleId) => ({
  type: actionTypes.DELETE_STEP_SUCCESS,
  payload: { stepId, tourtleId },
});

const deleteGroupFailure = err => ({
  type: actionTypes.DELETE_GROUP_FAILURE,
  payload: { error: err.stack },
});

const deleteGroupRequest = () => ({
  type: actionTypes.DELETE_GROUP_REQUEST,
  payload: null,
});

const deleteGroupSuccess = response => ({
  type: actionTypes.DELETE_GROUP_SUCCESS,
  payload: response.id,
});

const invitationDeclineFailure = err => ({
  type: actionTypes.INVITATION_DECLINE_FAILURE,
  payload: { error: err.stack },
});

const invitationDeclineRequest = () => ({
  type: actionTypes.INVITATION_DECLINE_REQUEST,
  payload: null,
});

const invitationDeclineSuccess = id => ({
  type: actionTypes.INVITATION_DECLINE_SUCCESS,
  payload: id,
});

const invitationAcceptFailure = err => ({
  type: actionTypes.INVITATION_ACCEPT_FAILURE,
  payload: { error: err.stack },
});

const invitationAcceptRequest = () => ({
  type: actionTypes.INVITATION_ACCEPT_REQUEST,
  payload: null,
});

const invitationAcceptSuccess = (id, res) => ({
  type: actionTypes.INVITATION_ACCEPT_SUCCESS,
  payload: { id: id, res: res },
});

const groupListFailure = err => ({
  type: actionTypes.GROUP_LIST_FAILURE,
  payload: { error: err.stack },
});

const groupListRequest = () => ({
  type: actionTypes.GROUP_LIST_REQUEST,
  payload: null,
});

const groupListSuccess = response => ({
  type: actionTypes.GROUP_LIST_SUCCESS,
  payload: {
    data: response.data,
    ctoken: response.ctoken,
    hasNext: response.hasNext,
    id: response.id,
  },
});

const inviteUsersFailure = err => ({
  type: actionTypes.INVITE_USERS_FAILURE,
  payload: { error: err.stack },
});

const inviteUsersRequest = () => ({
  type: actionTypes.INVITE_USERS_REQUEST,
  payload: null,
});

const inviteUsersSuccess = response => ({
  type: actionTypes.INVITE_USERS_SUCCESS,
  payload: response,
});

const revokeInvitationFailure = err => ({
  type: actionTypes.REVOKE_INVITATION_FAILURE,
  payload: { error: err.stack },
});

const revokeInvitationRequest = () => ({
  type: actionTypes.REVOKE_INVITATION_REQUEST,
  payload: null,
});

const revokeInvitationSuccess = response => ({
  type: actionTypes.REVOKE_INVITATION_SUCCESS,
  payload: response,
});

const clearGroupItem = () => ({
  type: actionTypes.GROUP_ITEM_CLEAR,
  payload: null,
});

const setShowInitialGroups = boolean => ({
  type: actionTypes.SET_SHOW_INITIAL_GROUPS,
  payload: boolean,
});

const createCommentFailure = err => ({
  type: actionTypes.CREATE_COMMENT_FAILURE,
  payload: { error: err.stack },
});

const createCommentRequest = () => ({
  type: actionTypes.CREATE_COMMENT_REQUEST,
  payload: null,
});

const createCommentSuccess = response => ({
  type: actionTypes.CREATE_COMMENT_SUCCESS,
  payload: response,
});

const updateCommentFailure = err => ({
  type: actionTypes.UPDATE_COMMENT_FAILURE,
  payload: { error: err.stack },
});

const updateCommentRequest = () => ({
  type: actionTypes.UPDATE_COMMENT_REQUEST,
  payload: null,
});

const updateCommentSuccess = (response, prevOwnRating) => ({
  type: actionTypes.UPDATE_COMMENT_SUCCESS,
  payload: { data: response, prevOwnRating: prevOwnRating },
});

const deleteCommentFailure = err => ({
  type: actionTypes.DELETE_COMMENT_FAILURE,
  payload: { error: err.stack },
});

const deleteCommentRequest = () => ({
  type: actionTypes.DELETE_COMMENT_REQUEST,
  payload: null,
});

const deleteCommentSuccess = response => ({
  type: actionTypes.DELETE_COMMENT_SUCCESS,
  payload: response,
});

const createReplyFailure = err => ({
  type: actionTypes.CREATE_REPLY_FAILURE,
  payload: { error: err.stack },
});

const createReplyRequest = () => ({
  type: actionTypes.CREATE_REPLY_REQUEST,
  payload: null,
});

const createReplySuccess = response => ({
  type: actionTypes.CREATE_REPLY_SUCCESS,
  payload: response,
});

const updateReplyFailure = err => ({
  type: actionTypes.UPDATE_REPLY_FAILURE,
  payload: { error: err.stack },
});

const updateReplyRequest = () => ({
  type: actionTypes.UPDATE_REPLY_REQUEST,
  payload: null,
});

const updateReplySuccess = response => ({
  type: actionTypes.UPDATE_REPLY_SUCCESS,
  payload: response,
});

const deleteReplyFailure = err => ({
  type: actionTypes.DELETE_REPLY_FAILURE,
  payload: { error: err.stack },
});

const deleteReplyRequest = () => ({
  type: actionTypes.DELETE_REPLY_REQUEST,
  payload: null,
});

const deleteReplySuccess = response => ({
  type: actionTypes.DELETE_REPLY_SUCCESS,
  payload: response,
});

const createRatingFailure = err => ({
  type: actionTypes.CREATE_RATING_FAILURE,
  payload: { error: err.stack },
});

const createRatingRequest = () => ({
  type: actionTypes.CREATE_RATING_REQUEST,
  payload: null,
});

const createRatingSuccess = response => ({
  type: actionTypes.CREATE_RATING_SUCCESS,
  payload: response,
});

const updateRatingFailure = err => ({
  type: actionTypes.UPDATE_RATING_FAILURE,
  payload: { error: err.stack },
});

const updateRatingRequest = () => ({
  type: actionTypes.UPDATE_RATING_REQUEST,
  payload: null,
});

const updateRatingSuccess = response => ({
  type: actionTypes.UPDATE_RATING_SUCCESS,
  payload: response,
});

const deleteRatingFailure = err => ({
  type: actionTypes.DELETE_RATING_FAILURE,
  payload: { error: err.stack },
});

const deleteRatingRequest = () => ({
  type: actionTypes.DELETE_RATING_REQUEST,
  payload: null,
});

const deleteRatingSuccess = response => ({
  type: actionTypes.DELETE_RATING_SUCCESS,
  payload: response,
});

const setActiveStepId = stepId => ({
  type: actionTypes.SET_ACTIVE_STEP_ID,
  payload: stepId,
});

const getProfileForUpdate = () => (dispatch, getState) => {
  if (selectors.getIsFetchingUserData(getState())) {
    return Promise.resolve();
  }

  dispatch(fetchUserDataRequest());

  return api
    .fetchMyUserData()
    .then(response => {
      dispatch(fetchUserDataSuccess(response));
    })
    .catch(err => {
      dispatch(fetchUserDataFailure(err));
    });
};

const fetchUserDataRequest = () => ({
  type: actionTypes.FETCH_USER_DATA_REQUEST,
  payload: null,
});

const fetchUserDataSuccess = response => ({
  type: actionTypes.FETCH_USER_DATA_SUCCESS,
  payload: response,
});

const fetchUserDataFailure = err => ({
  type: actionTypes.FETCH_USER_DATA_FAILURE,
  payload: { error: err.stack },
});

const fireUpdateTourtleOpening = tourtle => ({
  type: actionTypes.UPDATE_TOURTLE_OPENING,
  payload: { tourtle },
});

const fireUpdateTourtleClosing = () => ({
  type: actionTypes.UPDATE_TOURTLE_CLOSING,
  payload: null,
});

const searchAuthorListFailure = err => ({
  type: actionTypes.SEARCH_AUTHOR_LIST_FAILURE,
  payload: { error: err.stack },
});

const searchAuthorListRequest = () => ({
  type: actionTypes.SEARCH_AUTHOR_LIST_REQUEST,
  payload: null,
});

const searchAuthorListSuccess = response => ({
  type: actionTypes.SEARCH_AUTHOR_LIST_SUCCESS,
  payload: response,
});

const loadTransfereeThunk = tourtleId => async (dispatch, getState) => {
  try {
    const state = getState();
    if (get(state, "root.transfer.isFetching", false)) {
      return Promise.resolve();
    }
    dispatch(loadTransfereeRequest());
    const transferee = await api.fetchTourtleItem(tourtleId);
    dispatch(loadTransfereeSuccess(transferee));
  } catch (err) {
    dispatch(loadTransfereeFailure(err));
  }
};

const loadTransfereeFailure = err => ({
  type: actionTypes.LOAD_TRANSFEREE_FAILURE,
  payload: { err },
});

const loadTransfereeRequest = () => ({
  type: actionTypes.LOAD_TRANSFEREE_REQUEST,
  payload: {},
});

const loadTransfereeSuccess = transferee => ({
  type: actionTypes.LOAD_TRANSFEREE_SUCCESS,
  payload: { transferee },
});

const transferSearchPatternChanged = evt => ({
  type: actionTypes.TRANSFER_SEARCH_PATTERN_CHANGED,
  payload: { username: evt.target.value },
});

const transferSearchForUserThunk = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const searchPattern = get(state, "root.transfer.searchPattern", "");
    if (
      get(state, "root.transfer.isFetching", false) ||
      isEmpty(searchPattern)
    ) {
      return Promise.resolve();
    }
    dispatch(adminUserSearchRequest());
    const users = await api.fetchAdminUserSearch(searchPattern);
    if (users.statusCode === 404) throw new Error(users.message);
    dispatch(adminUserSearchSuccess(users));
  } catch (err) {
    dispatch(adminUserSearchFailure(err));
  }
};

const adminUserSearchFailure = err => ({
  type: actionTypes.ADMIN_USER_SEARCH_FAILURE,
  payload: { error: err },
});

const adminUserSearchRequest = () => ({
  type: actionTypes.ADMIN_USER_SEARCH_REQUEST,
  payload: {},
});

const adminUserSearchSuccess = users => ({
  type: actionTypes.ADMIN_USER_SEARCH_SUCCESS,
  payload: {
    users,
  },
});

const transferTourtleThunk = history => async (dispatch, getState) => {
  try {
    const state = getState();
    if (get(state, "root.transfer.isFetching", false)) {
      return Promise.resolve();
    }
    dispatch(transferTourtleRequest());
    const { id: tourtleId } = selectors.getTransferee(state);
    const { id: userId } = selectors.getTransferSelectedUser(state);
    const response = await api.transferTourtle(tourtleId, userId);
    if (response.statusCode) {
      dispatch(transferTourtleFailure(response));
    } else {
      dispatch(transferTourtleSuccess());
      dispatch(history.goBack());
    }
  } catch (err) {
    dispatch(transferTourtleFailure(err));
  }
};

const transferTourtleFailure = err => ({
  type: actionTypes.ADMIN_TRANSFER_TOURTLE_FAILURE,
  payload: { error: err },
});

const transferTourtleRequest = () => ({
  type: actionTypes.ADMIN_TRANSFER_TOURTLE_REQUEST,
  payload: {},
});

const transferTourtleSuccess = () => ({
  type: actionTypes.ADMIN_TRANSFER_TOURTLE_SUCCESS,
  payload: {},
});

const transferUserSelected = user => ({
  type: actionTypes.ADMIN_TRANSFER_USER_SELECTED,
  payload: { user },
});

const fireAboutAuthorOpening = (userId, response) => ({
  type: actionTypes.ABOUT_AUTHOR_OPENING,
  payload: { userId, ...response },
});

const fireAuthorMenuOpening = userId => ({
  type: actionTypes.ABOUT_AUTHOR_MENU_OPENING,
  payload: userId,
});

const fireAuthorMenuClosing = () => ({
  type: actionTypes.ABOUT_AUTHOR_MENU_CLOSING,
  payload: null,
});

const tabBarSearchState = tab => ({
  type: actionTypes.TAB_BAR_SEARCH_STATE,
  payload: tab,
});

const startMediaSlider = () => ({
  type: actionTypes.START_MEDIA_SLIDER,
  payload: {},
});

const endMediaSlider = () => ({
  type: actionTypes.END_MEDIA_SLIDER,
  payload: {},
});

const fireFilterDialogUpdate = newState => ({
  type: actionTypes.FILTER_DIALOG_UPDATE,
  payload: newState,
});

const setFilterDialogIsOpen = isOpen => ({
  type: actionTypes.FILTER_DIALOG_IS_OPEN,
  payload: isOpen,
});

const removeTourtleFromGroupFailure = err => ({
  type: actionTypes.REMOVE_TOURTLE_FROM_GROUP_FAILURE,
  payload: { error: err.stack },
});

const removeTourtleFromGroupRequest = () => ({
  type: actionTypes.REMOVE_TOURTLE_FROM_GROUP_REQUEST,
  payload: null,
});

const removeTourtleFromGroupSuccess = (tourtleId, groupId) => ({
  type: actionTypes.REMOVE_TOURTLE_FROM_GROUP_SUCCESS,
  payload: { tourtleId, groupId },
});

const toggleSubscribeNotificationFailure = err => ({
  type: actionTypes.TOGGLE_SUB_NOTIFICATION_FAILURE,
  payload: { error: err.stack },
});

const toggleSubscribeNotificationRequest = () => ({
  type: actionTypes.TOGGLE_SUB_NOTIFICATION_REQUEST,
  payload: null,
});

const toggleSubscribeNotificationSuccess = (userId, notifications) => ({
  type: actionTypes.TOGGLE_SUB_NOTIFICATION_SUCCESS,
  payload: {
    userId: userId,
    notifications: notifications,
  },
});

const clearSubscriptionsList = () => ({
  type: actionTypes.TOURTLE_SUBS_CLEAR,
  payload: null,
});

const fireCollectionOpening = (collectionId, response) => ({
  type: actionTypes.COLLECTION_OPENING,
  payload: { collectionId, response },
});

const addSelectedTourtleId = tourtle => ({
  type: actionTypes.ADD_SELECTED_TOURTLE_TO_ARRAY,
  payload: {
    tourtle,
  },
});

const removeSelectedTourtleId = tourtle => ({
  type: actionTypes.REMOVE_SELECTED_TOURTLE_FROM_ARRAY,
  payload: {
    tourtle,
  },
});

const fireDiscardChangesDialogClosing = () => ({
  type: actionTypes.DISCARD_CHANGES_DIALOG_CLOSING,
  payload: null,
});

const fireDiscardChangesDialogOpening = collectionId => ({
  type: actionTypes.DISCARD_CHANGES_DIALOG_OPENING,
  payload: {
    collectionId,
  },
});

const fireCollectionPageOpening = tourtles => ({
  type: actionTypes.DISPATCH_SELECTED_TOURTLES,
  payload: tourtles,
});

const fetchSuggestedTourtlesRequest = () => ({
  type: actionTypes.FETCH_SUGGESTED_TOURTLES_REQUEST,
  payload: null,
});

const fetchSuggestedTourtlesSuccess = res => ({
  type: actionTypes.FETCH_SUGGESTED_TOURTLES_SUCCESS,
  payload: res,
});
const fetchSuggestedTourtlesFailure = err => ({
  type: actionTypes.FETCH_SUGGESTED_TOURTLES_FAILURE,
  payload: { error: err.stack },
});

const removeLinkedTourtleRequest = () => ({
  type: actionTypes.REMOVE_LINKED_TOURTLE_REQUEST,
  payload: null,
});

const removeLinkedTourtleSuccess = (res, mainTourtleId) => ({
  type: actionTypes.REMOVE_LINKED_TOURTLE_SUCCESS,
  payload: { res, mainTourtleId },
});

const removeLinkedTourtleFailure = err => ({
  type: actionTypes.REMOVE_LINKED_TOURTLE_FAILURE,
  payload: { error: err.stack },
});

const moveTourtlePositionSuccess = (newImgOrder, collectionItemId) => ({
  type: actionTypes.REORDER_TOURTLES_IN_COLLECTION_SUCCESS,
  payload: { newImgOrder, collectionItemId },
});

const fireSearchWithEmptyQuery = () => ({
  type: actionTypes.SEARCH_WITH_EMPTY_QUERY,
  payload: null,
});

const fireSearchWithoutEmptyQuery = () => ({
  type: actionTypes.EXIT_SEARCH_WITH_EMPTY_QUERY,
  payload: null,
});

const setOriginOfRoute = type => ({
  type: actionTypes.SET_ORIGIN_OF_ROUTE,
  payload: type,
});

const clearOriginOfRoute = type => ({
  type: actionTypes.CLEAR_ORIGIN_OF_ROUTE,
  payload: type,
});

const clearResultLength = () => ({
  type: actionTypes.CLEAR_RESULT_VALUES,
  payload: null,
});

const preventClearCollectionState = () => ({
  type: actionTypes.PREVENT_CLEAR_COLLECTION_STATE,
  payload: null,
});

const clearHomeList = () => ({
  type: actionTypes.HOME_CLEAR_LIST,
  payload: null,
});

const setFirstUse = (isFirstUse, url) => ({
  type: actionTypes.SET_FIRST_USE,
  payload: { isFirstUse, url },
});
const getBlackAndWhiteListRequest = () => ({
  type: actionTypes.GET_BLACK_WHITE_LIST_REQUEST,
  payload: null,
});

const getBlackAndWhiteListFailure = () => ({
  type: actionTypes.GET_BLACK_WHITE_LIST_FAILURE,
  payload: null,
});

const getBlackAndWhiteListSuccess = res => ({
  type: actionTypes.GET_BLACK_WHITE_LIST_SUCCESS,
  payload: res,
});

const manageBlackAndWhiteListRequest = () => ({
  type: actionTypes.MANAGE_BLACK_WHITE_LIST_REQUEST,
  payload: null,
});

const manageBlackAndWhiteListAddSuccess = (word, type) => ({
  type: actionTypes.MANAGE_BLACK_WHITE_LIST_ADD_SUCCESS,
  payload: { word, type },
});

const manageBlackAndWhiteListRemoveSuccess = (word, type) => ({
  type: actionTypes.MANAGE_BLACK_WHITE_LIST_REMOVE_SUCCESS,
  payload: { word, type },
});

const manageBlackAndWhiteListFinish = () => ({
  type: actionTypes.MANAGE_BLACK_WHITE_LIST_FINISH,
  payload: null,
});

const manageBlackAndWhiteListFailure = () => ({
  type: actionTypes.MANAGE_BLACK_WHITE_LIST_FAILURE,
  payload: null,
});

const fireAddWordsDialogOpening = type => ({
  type: actionTypes.OPEN_ADD_WORDS_DIALOG,
  payload: type,
});

const fireAddWordsDialogClosing = () => ({
  type: actionTypes.CLOSE_ADD_WORDS_DIALOG,
  payload: null,
});

const fireRemoveWordsDialogOpening = (word, type) => ({
  type: actionTypes.OPEN_REMOVE_WORDS_DIALOG,
  payload: { word, type },
});

const fireRemoveWordsDialogClosing = () => ({
  type: actionTypes.CLOSE_REMOVE_WORDS_DIALOG,
  payload: null,
});

const updateUserGroupsRequest = () => ({
  type: actionTypes.UPDATE_USER_GROUPS_REQUEST,
  payload: null,
});

const updateUserGroupsSuccess = groups => ({
  type: actionTypes.UPDATE_USER_GROUPS_SUCCESS,
  payload: groups,
});

const updateUserGroupsFailure = err => ({
  type: actionTypes.UPDATE_USER_GROUPS_FAILURE,
  payload: { error: err.stack },
});

const resetNewTourtleCountSubscriber = id => ({
  type: actionTypes.RESET_NEW_TOURTLE_COUNT_SUBSCRIBER,
  payload: id,
});

// const getUserInfoSection = section => ({
//   type: actionTypes.GET_USER_INFO_SECTION,
//   payload: section
// })
const getUserInfoSectionRequest = () => ({
  type: actionTypes.GET_USER_INFO_SECTION_REQUEST,
  payload: null,
});

const getUserInfoSectionSuccess = res => ({
  type: actionTypes.GET_USER_INFO_SECTION_SUCCESS,
  payload: res,
});

const getUserInfoSectionFailure = err => ({
  type: actionTypes.GET_USER_INFO_SECTION_FAILURE,
  payload: { error: err.stack },
});

const emailTestRequest = () => ({
  type: actionTypes.SIGN_UP_EMAIL_CHECK_REQUEST,
  payload: null,
});

const emailTestSuccess = () => ({
  type: actionTypes.SIGN_UP_EMAIL_CHECK_SUCCESS,
  payload: null,
});

const emailTestFailure = err => ({
  type: actionTypes.SIGN_UP_EMAIL_CHECK_FALSE,
  payload: { error: err.stack },
});

const dispatchCognitoUser = (user, email, password = "") => ({
  type: actionTypes.DISPATCH_COGNITO_USER,
  payload: { user, email, password },
});

const dispatchLandingPageSettings = (category = "") => {
  return {
    type: actionTypes.DISPATCH_LANDING_PAGE_SETTINGS,
    payload: { category },
  };
};

const clearCognitoLogin = () => ({
  type: actionTypes.CLEAR_COGNITO_LOGIN,
  payload: null,
});

// const searchListAuthorSuccess = (response, publisherId) => ({
//   type: actionTypes.SEARCH_LIST_AUTHOR_SUCCESS,
//   payload: {
//     data: response.data,
//     ctoken: response.ctoken,
//     hasNext: response.hasNext,
//     id: response.id,
//     publisherId,
//   },
// });

// const setAdditionsByAdminOnly = () => ({
//   type: actionTypes.SET_GROUP_MEMBERSHIP_APPROVAL,
//   payload: null,
// });

// const setAdditionsNeedAdminApproval = () => ({
//   type: actionTypes.SET_GROUP_PUBLISHING_PERMISSION,
//   payload: null,
// });

// const setMembersNeedAdminApproval = () => ({
//   type: actionTypes.SET_GROUP_PUBLISHING_APPROVAL,
//   payload: null,
// });

// END interests filter

// const adminFetchUsersReportThunk = () => async (dispatch, getState) => {
//   try {
//     const state = getState();
//     if (selectors.getIsFetchingReport(state)) return Promise.resolve();
//     dispatch(adminFetchReportRequest());
//     const report = await api.getUsersReport();
//     if (report.error) throw new Error(report.error);
//     dispatch(adminFetchReportSuccess(report));
//   } catch (err) {
//     dispatch(adminFetchReportFailure(err));
//   }
// };

// //!! Profanity Check.............................................
// const profanityCheckThunk = () => async (dispatch, getState) => {
//   try {
//     const state = getState();
//     if (selectors.getIsProcessingModeration(state)) return Promise.resolve();
//     dispatch(adminFetchReportRequest());
//     const result = await api.requestModeration();
//     dispatch(profanityCheckSuccess(result))
//   } catch (err) {
//     dispatch(profanityCheckFailure(err));
//   }
// };

// const profanityCheckRequest = text => ({
//   type: actionTypes.PROFANITY_CHECK_REQUEST,
//   payload: text,
// });

// const profanityCheckSuccess = () => ({
//   type: actionTypes.PROFANITY_CHECK_SUCCESS,
//   payload: null,
// });

// const profanityCheckFailure = err => ({
//   type: actionTypes.PROFANITY_CHECK_FAILURE,
//   payload: {
//     error: {
//       message: err && err.message,
//       stack: err && err.stack,
//     },
//   },
// });

const clearSignUp = () => ({
  type: actionTypes.CLEAR_SIGN_UP,
  payload: null,
});

const setGroupTabValue = value => ({
  type: actionTypes.SET_GROUP_TAP_VALUE,
  payload: value,
});

const fireOpenAddTourtleDialog = group => {
  return {
    type: actionTypes.SET_ADD_TOURTLE_TO_GROUP_DIALOG_OPEN,
    payload: group,
  };
};

const fireCloseAddTourtleDialog = () => ({
  type: actionTypes.SET_ADD_TOURTLE_TO_GROUP_DIALOG_CLOSE,
  payload: null,
});

const addTourtlesToGroupRequest = () => ({
  type: actionTypes.ADD_TOURTLES_TO_GROUPS_REQUEST,
  payload: null,
});
const addTourtlesToGroupSuccess = response => ({
  type: actionTypes.ADD_TOURTLES_TO_GROUPS_SUCCESS,
  payload: response,
});
const addTourtlesToGroupFailure = () => ({
  type: actionTypes.ADD_TOURTLES_TO_GROUPS_FAILURE,
  payload: null,
});

const dispatchNewEmailAddress = email => ({
  type: actionTypes.STORE_NEW_EMAIL_ADDRESS,
  payload: email,
});

// Upload file
const uploadFileFailure = err => ({
  type: actionTypes.UPLOAD_FILE_FAILURE,
  payload: { error: err.stack },
});

const uploadFile = () => ({
  type: actionTypes.UPLOAD_FILE_REQUEST,
  payload: null,
});

const uploadFileSuccess = response => ({
  type: actionTypes.UPLOAD_FILE_SUCCESS,
  payload: response,
});

const dispatchSelectedAttachment = attachment => ({
  type: actionTypes.SET_SELECTED_ATTACHMENT,
  payload: attachment,
});

const openAttachmentPreview = () => ({
  type: actionTypes.OPEN_SELECTED_ATTACHMENT,
  payload: null,
});
const closeAttachmentPreview = () => ({
  type: actionTypes.CLOSE_SELECTED_ATTACHMENT,
  payload: null,
});

const fireHandleSendEmailToMembersDialogClosing = () => ({
  type: actionTypes.CLOSE_SEND_EMAIL_TO_GROUP_MEMBERS_DIALOG,
  payload: null,
});

const fireHandleSendEmailToMembersDialogOpening = () => ({
  type: actionTypes.OPEN_SEND_EMAIL_TO_GROUP_MEMBERS_DIALOG,
  payload: null,
});

const sendEmailToMembersRequest = () => ({
  type: actionTypes.REQUEST_SEND_EMAIL_TO_MEMBERS,
  payload: null,
});
const sendEmailToMembersSuccess = res => ({
  type: actionTypes.SUCCESS_SEND_EMAIL_TO_MEMBERS,
  payload: res,
});
const sendEmailToMembersReject = () => ({
  type: actionTypes.REJECT_SEND_EMAIL_TO_MEMBERS,
  payload: null,
});

const updateStepCheck = () => ({
  type: actionTypes.UPDATE_STEP_CHECK,
  payload: null,
});
const updateStepCheckSuccess = id => ({
  type: actionTypes.UPDATE_STEP_CHECK_SUCCESS,
  payload: id,
});
const updateStepCheckFailure = () => ({
  type: actionTypes.UPDATE_STEP_CHECK_FAILURE,
  payload: null,
});

const saveSubscription = response => ({
  type: actionTypes.SAVE_SUBSCRIPTION,
  payload: response,
});

const updateStorageUsed = response => ({
  type: actionTypes.UPDATE_STORAGE_USED,
  payload: response,
});

export default {
  dispatchNewEmailAddress,
  fireLeftNavOpening,
  fireLeftNavClosing,
  homeListFailure,
  homeListSuccess,
  homeListRequest,
  homeListClear,
  trendingListRequest,
  trendingListSuccess,
  trendingListFailure,
  subsTourtleListRequest,
  subsTourtleListSuccess,
  subsTourtleListFailure,
  subscriptionsRequest,
  subscriptionsSuccess,
  subscriptionsFailure,
  fetchAuthorRequest,
  fetchAuthorSuccess,
  fetchAuthorFailure,
  subscribeRequest,
  subscribeSuccess,
  subscribeFailure,
  unSubscribeRequest,
  unSubscribeSuccess,
  unSubscribeFailure,
  authorTourtleListRequest,
  authorTourtleListSuccess,
  authorTourtleListFailure,
  clearAuthorTourtleList,
  clearAuthor,
  currentUserTourtleListRequest,
  currentUserTourtleListSuccess,
  currentUserTourtleListFailure,
  clearCurrentUserTourtleList,
  recentlyVisitedTourtleListRequest,
  recentlyVisitedTourtleListSuccess,
  recentlyVisitedTourtleListFailure,
  currentUserCollectionListRequest,
  currentUserCollectionListSuccess,
  currentUserCollectionListFailure,
  favoriteTourtleRequest,
  favoriteTourtleFailure,
  favoriteTourtleSuccess,
  reactOnTourtleRequest,
  reactOnTourtleFailure,
  reactOnTourtleSuccess,
  removeReactionOnTourtleRequest,
  removeReactionOnTourtleFailure,
  removeReactionOnTourtleSuccess,
  currentUserFavoritesListRequest,
  currentUserFavoritesListSuccess,
  currentUserFavoritesListFailure,
  fireDeleteTourtleDialogClosing,
  fireDeleteTourtleDialogOpening,
  deleteTourtleRequest,
  deleteTourtleSuccess,
  deleteTourtleFailure,
  fireReportDialogClosing,
  fireReportDialogOpening,
  reportRequest,
  reportSuccess,
  reportFailure,
  fireSnackbarClosing,
  fireSnackbarOpening,
  openAddTourtleToGroupsConfirmationDialog,
  closeAddTourtleToGroupsConfirmationDialog,
  fireAddToGroupDialogClosing,
  fireAddToGroupDialogOpening,
  fireAddToGroupDialogHiding,
  fireAddToGroupDialogShowing,
  setPrivateLinkInformationStep,
  unsetPrivateLinkInformationStep,
  addTourtleToGroupsRequest,
  addTourtleToGroupsSuccess,
  addTourtleToGroupsFailure,
  fireAddToCollectionDialogClosing,
  fireAddToCollectionDialogOpening,
  addTourtleToCollectionsRequest,
  addTourtleToCollectionsSuccess,
  addTourtleToCollectionsFailure,
  searchGroupListItems,
  searchGroupListItemsFailure,
  searchGroupListItemsSuccess,
  clearGroupListItems,
  fireLoginDialogClosing,
  fireLoginDialogOpening,
  fireSignInUpDialogClosing,
  fireSignInUpDialogOpening,
  fireSignOutClosing,
  fireSignOutOpening,
  fireCookieDialogClosing,
  fireCookieDialogOpening,
  showWelcomeTourtle,
  hideWelcomeTourtle,
  dispatchUserInterest,
  dispatchFilterInterest,
  dispatchInitialInterests,
  updateProfileRequest,
  updateProfileSuccess,
  updateProfileFailure,
  selectAllUserInterests,
  removeAllUserInterests,
  selectAllInterests,
  removeAllInterests,
  fireWarningDialogOpening,
  fireWarningDialogClosing,
  fireUnsavedChangesDialogOpening,
  fireUnsavedChangesDialogClosing,
  fireLocationServiceClosing,
  fireLocationServiceOpening,
  updateNotificationsRequest,
  updateNotificationsSuccess,
  updateNotificationsFailure,
  updateAuthExpirationRequest,
  updateAuthExpirationSuccess,
  updateAuthExpirationFailure,
  updateShowLandingPageRequest,
  updateShowLandingPageSuccess,
  updateShowLandingPageFailure,
  setUserVerificationPrefEmail,
  setUserVerificationPrefPhone,
  forgetMeThunk,
  interestsListRequest,
  interestsListSuccess,
  interestsListFailure,
  interestsAuthorListRequest,
  interestsAuthorListSuccess,
  interestsAuthorListFailure,
  clearInterestsList,
  clearInterestsAuthorList,
  tourtleItemRequest,
  tourtleItemSuccess,
  tourtleItemFailure,
  clearTourtleItem,
  fireJumpToStepDialogOpening,
  fireJumpToStepDialogClosing,
  fireImageGalleryClosing,
  fireImageGalleryOpening,
  commentsRequest,
  commentsSuccess,
  commentsFailure,
  addLinkedTourtleIndex,
  addLinkedTourtleIndexCover,
  removeLinkedTourtleIndex,
  fireAddStepDialogOpening,
  fireAddStepDialogClosing,
  setCurrentStepIndex,
  finishStepAdd,
  resetCurrentStepIndex,
  storeTourtleCoverPageTopOffset,
  setActiveTourtleId,
  logoutCurrentUserFailure,
  logoutCurrentUserRequest,
  logoutCurrentUserSuccess,
  fireAddingStepFromCoverPageOn,
  fireAddingStepFromCoverPageOff,
  fireEditingStepFromCoverPageOn,
  fireEditingStepFromCoverPageOff,
  createRefOnAddItem,
  removeRefOnAddItem,
  cancelAddingStep,
  clearStepCounter,
  fireDeleteStepDialogOpening,
  fireDeleteStepDialogClosing,
  fireDuplicateStepDialogOpening,
  fireDuplicateStepDialogClosing,
  duplicateStepFailure,
  duplicateStepRequest,
  duplicateStepSuccess,
  fireMoveStepDialogOpening,
  fireMoveStepDialogClosing,
  onDragStep,
  moveStepRequest,
  moveStepFailure,
  moveStepSuccess,
  updateTourtleFailure,
  updateTourtleSuccess,
  updateTourtleRequest,
  createTourtleFailure,
  createTourtleRequest,
  createTourtleSuccess,
  createStepFailure,
  createStepRequest,
  createStepAfterSuccess,
  openContentModerationDialog,
  closeContentModerationDialog,
  updateStepRequest,
  updateStepSuccess,
  updateStepFailure,
  setUpdateStepToFalse,
  setTabbedListScrollPosition,
  resetTabbedListScrollPosition,
  fetchWelcomeTourtle,
  fetchWelcomeTourtleRequest,
  fetchWelcomeTourtleFailure,
  fireSearchDialogClosing,
  fireSearchDialogOpening,
  searchListRequest,
  searchListFailure,
  searchListSuccess,
  fireFilterDialogClosing,
  fireFilterDialogOpening,
  resetSearchFilters,
  groupItemRequest,
  groupItemSuccess,
  groupItemFailure,
  createGroupRequest,
  createGroupSuccess,
  createGroupFailure,
  updateGroupRequest,
  updateGroupSuccess,
  updateGroupFailure,
  updateGroupVisibility,
  closeGroupVisibility,
  groupTourtleListFailure,
  groupTourtleListRequest,
  groupTourtleListSuccess,
  setGroupTourtleListQuery,
  clearGroupTourtleListQuery,
  fireGroupPositionChangeRequest,
  fireGroupPositionChangeSuccess,
  fireGroupPositionChangeFailure,
  joinGroupRequest,
  joinGroupFailure,
  joinGroupSuccess,
  leaveGroupRequest,
  leaveGroupFailure,
  leaveGroupSuccess,
  favoriteGroupRequest,
  favoriteGroupFailure,
  favoriteGroupSuccess,
  unfavoriteGroupRequest,
  unfavoriteGroupFailure,
  unfavoriteGroupSuccess,
  toggleGroupNotificationsSuccess,
  fireAddMemberMenuOpening,
  fireAddMemberMenuClosing,
  fireAddViewersOpening,
  fireAddViewersClosing,
  fireInvitationDialogOpen,
  fireInvitationDialogClose,
  fireDeleteGroupDialogOpening,
  fireDeleteGroupDialogClosing,
  fireLastAdminDialogClose,
  fireLastAdminDialogOpen,
  fireLeaveGroupDialogOpening,
  fireLeaveGroupDialogClosing,
  fireRemoveTourtleFromGroupDialogClose,
  fireRemoveTourtleFromGroupDialogOpen,
  fetchGroupMemberRequest,
  fetchGroupMemberFailure,
  fetchGroupMemberSuccess,
  removeMemberRequest,
  removeMemberFailure,
  removeMemberSuccess,
  changeGroupMemberRequest,
  changeGroupMemberFailure,
  changeGroupMemberSuccess,
  setActiveGroupId,
  fireRevokeInvitationDialogOpening,
  fireRevokeInvitationDialogClosing,
  fetchGroupInvitationsRequest,
  fetchGroupInvitationsFailure,
  fetchGroupInvitationsSuccess,
  acceptRequestRequest,
  acceptRequestFailure,
  acceptRequestSuccess,
  rejectRequestRequest,
  rejectRequestFailure,
  rejectRequestSuccess,
  acceptTourtleRequestFailure,
  acceptTourtleRequestRequest,
  acceptTourtleRequestSuccess,
  rejectTourtleRequestFailure,
  rejectTourtleRequestRequest,
  rejectTourtleRequestSuccess,
  fetchPendingTourtleItemsRequest,
  fetchPendingTourtleItemsFailure,
  fetchPendingTourtleItemsSuccess,
  fireMoveTourtleDialogClosing,
  fireMoveTourtleDialogOpening,
  fireDeleteCollectionDialogClosing,
  fireDeleteCollectionDialogOpening,
  deleteCollectionFailure,
  deleteCollectionRequest,
  deleteCollectionSuccess,
  collectionItemRequest,
  collectionItemSuccess,
  collectionItemFailure,
  fetchTourtlesForCollectionRequest,
  fetchTourtlesForCollectionSuccess,
  fetchTourtlesForCollectionFailure,
  updateCollectionListItem,
  firePositionChangeRequest,
  firePositionChangeSuccess,
  firePositionChangeFailure,
  clearCollectionState,
  addTourtlesToCollectionRequest,
  addTourtlesToCollectionSuccess,
  addTourtlesToCollectionFailure,
  removeTourtlesToCollectionRequest,
  removeTourtlesToCollectionSuccess,
  removeTourtlesToCollectionFailure,
  updateCollectionFailure,
  updateCollectionRequest,
  updateCollectionSuccess,
  fireCollectionPositionChangeRequest,
  fireCollectionPositionChangeSuccess,
  fireCollectionPositionChangeFailure,
  moveCollectionPositionSuccess,
  clearListSearch,
  setSearchQuery,
  setSearchQueryGroup,
  initiateClearCollectionState,
  clearSelectedTourtleList,
  dispatchResultLength,
  createCollectionFailure,
  createCollectionRequest,
  createCollectionSuccess,
  fetchCollaboratorsRequest,
  fetchCollaboratorsFailure,
  fetchCollaboratorsSuccess,
  removeCollaboratorFailure,
  removeCollaboratorRequest,
  removeCollaboratorSuccess,
  switchCollaboratorTypeFailure,
  switchCollaboratorTypeRequest,
  switchCollaboratorTypeSuccess,
  linkTourtleToTourtleRequest,
  linkTourtleToTourtleSuccess,
  linkTourtleToTourtleFailure,
  uploadImageFailure,
  uploadImageRequest,
  uploadImageSuccess,
  increaseUsedStorage,
  adminAddUserThunk,
  adminDeleteUserThunk,
  adminToggleUserActivationThunk,
  fireShareDialogOpening,
  fireShareDialogClosing,
  fireStepOpening,
  fireDeleteImageDialogOpening,
  fireDeleteImageDialogClosing,
  fireDeleteAudioDialogOpening,
  fireDeleteAudioDialogClosing,
  fireDeleteVideoDialogOpening,
  fireDeleteVideoDialogClosing,
  fireRemoveLinkedTourtleDialogOpening,
  fireRemoveLinkedTourtleDialogClosing,
  loginChannelAddressSet,
  loginChannelAddressChanged,
  loginEmailChanged,
  loginGetCurrentUser,
  loginLinkRequestFailure,
  loginLinkRequestInvalid,
  loginLinkRequestUnauthorized,
  loginLinkRequestUnverified,
  loginLinkRequestRequest,
  loginLinkRequestSuccess,
  adminRefreshStatsRequest,
  adminRefreshStatsThunk,
  adminUpdateIsVerifiedAndIsActiveRequest,
  adminUpdateIsVerifiedAndIsActiveThunk,
  adminUpdateCategoriesRequest,
  adminUpdateCategoriesThunk,
  adminFetchReportRequest,
  adminFetchReportThunk,
  adminFetchUsersReportThunk,
  signUpChannelSelected,
  signUpEmailChanged,
  signUpPhoneChanged,
  signUpFailure,
  signUpRequest,
  signUpSuccess,
  signUpFirstNameChanged,
  signUpLastNameChanged,
  signUpCompanyChanged,
  signUpFirstNameCheckBadInput,
  signUpLastNameCheckBadInput,
  signUpCompanyCheckBadInput,
  signUpCheckIncompleteInput,
  signUpUsernameChanged,
  signUpUsernameCheckRequest,
  signUpUsernameCheckSuccess,
  signUpUsernameCheckFailure,
  signUpUsernameCheckBadInput,
  signUpUsernameCheckForbiddenInput,
  fireSignUpAddressTakenDialogOpening,
  fireSignUpAddressTakenDialogClosing,
  startCommenting,
  endCommenting,
  uploadAudioFailure,
  uploadAudioRequest,
  uploadAudioSuccess,
  uploadVideoFailure,
  uploadVideoRequest,
  uploadVideoSuccess,
  clearList,
  clearListAuthor,
  addCollaboratorsFailure,
  addCollaboratorsRequest,
  addCollaboratorsSuccess,
  updateCollaboratorFailure,
  updateCollaboratorRequest,
  updateCollaboratorSuccess,
  openUpdateStepScreen,
  closeUpdateStepScreen,
  deleteStepFailure,
  deleteStepRequest,
  deleteStepSuccess,
  deleteGroupRequest,
  deleteGroupSuccess,
  deleteGroupFailure,
  invitationDeclineFailure,
  invitationDeclineRequest,
  invitationDeclineSuccess,
  invitationAcceptFailure,
  invitationAcceptRequest,
  invitationAcceptSuccess,
  groupListFailure,
  groupListRequest,
  groupListSuccess,
  inviteUsersRequest,
  inviteUsersFailure,
  inviteUsersSuccess,
  revokeInvitationRequest,
  revokeInvitationFailure,
  revokeInvitationSuccess,
  clearGroupItem,
  setShowInitialGroups,
  createCommentFailure,
  createCommentRequest,
  createCommentSuccess,
  updateCommentFailure,
  updateCommentRequest,
  updateCommentSuccess,
  deleteCommentFailure,
  deleteCommentRequest,
  deleteCommentSuccess,
  createReplyFailure,
  createReplyRequest,
  createReplySuccess,
  updateReplyFailure,
  updateReplyRequest,
  updateReplySuccess,
  deleteReplyFailure,
  deleteReplyRequest,
  deleteReplySuccess,
  createRatingFailure,
  createRatingRequest,
  createRatingSuccess,
  updateRatingFailure,
  updateRatingRequest,
  updateRatingSuccess,
  deleteRatingFailure,
  deleteRatingRequest,
  deleteRatingSuccess,
  setActiveStepId,
  getProfileForUpdate,
  fetchUserDataRequest,
  fetchUserDataSuccess,
  fetchUserDataFailure,
  fireUpdateTourtleOpening,
  fireUpdateTourtleClosing,
  searchAuthorListFailure,
  searchAuthorListRequest,
  searchAuthorListSuccess,
  loadTransfereeThunk,
  transferSearchPatternChanged,
  transferSearchForUserThunk,
  transferTourtleThunk,
  transferUserSelected,
  fireAboutAuthorOpening,
  fireAuthorMenuOpening,
  fireAuthorMenuClosing,
  tabBarSearchState,
  startMediaSlider,
  endMediaSlider,
  fireFilterDialogUpdate,
  setFilterDialogIsOpen,
  removeTourtleFromGroupFailure,
  removeTourtleFromGroupRequest,
  removeTourtleFromGroupSuccess,
  toggleSubscribeNotificationFailure,
  toggleSubscribeNotificationRequest,
  toggleSubscribeNotificationSuccess,
  clearSubscriptionsList,
  fireCollectionOpening,
  addSelectedTourtleId,
  removeSelectedTourtleId,
  fireDiscardChangesDialogClosing,
  fireDiscardChangesDialogOpening,
  fireCollectionPageOpening,
  fetchSuggestedTourtlesRequest,
  fetchSuggestedTourtlesSuccess,
  fetchSuggestedTourtlesFailure,
  removeLinkedTourtleRequest,
  removeLinkedTourtleSuccess,
  removeLinkedTourtleFailure,
  moveTourtlePositionSuccess,
  fireSearchWithEmptyQuery,
  fireSearchWithoutEmptyQuery,
  setOriginOfRoute,
  clearOriginOfRoute,
  clearResultLength,
  preventClearCollectionState,
  clearHomeList,
  setFirstUse,
  getBlackAndWhiteListRequest,
  getBlackAndWhiteListSuccess,
  getBlackAndWhiteListFailure,
  manageBlackAndWhiteListRequest,
  manageBlackAndWhiteListAddSuccess,
  manageBlackAndWhiteListRemoveSuccess,
  manageBlackAndWhiteListFinish,
  manageBlackAndWhiteListFailure,
  fireAddWordsDialogOpening,
  fireAddWordsDialogClosing,
  fireRemoveWordsDialogOpening,
  fireRemoveWordsDialogClosing,
  updateUserGroupsRequest,
  updateUserGroupsSuccess,
  updateUserGroupsFailure,
  resetNewTourtleCountSubscriber,
  getUserInfoSectionRequest,
  getUserInfoSectionSuccess,
  getUserInfoSectionFailure,
  emailTestRequest,
  emailTestSuccess,
  emailTestFailure,
  dispatchCognitoUser,
  dispatchLandingPageSettings,
  clearCognitoLogin,
  clearSignUp,
  groupDiscoveryListRequest,
  groupDiscoveryListSuccess,
  groupDiscoveryListFailure,
  groupDiscoveryListClear,
  // profanityCheckThunk,
  // profanityCheckRequest,
  // profanityCheckSuccess,
  // profanityCheckFailure,
  clearGroupListItemsCToken,
  setGroupTabValue,
  fireOpenAddTourtleDialog,
  fireCloseAddTourtleDialog,
  addTourtlesToGroupRequest,
  addTourtlesToGroupSuccess,
  addTourtlesToGroupFailure,
  uploadFile,
  uploadFileFailure,
  uploadFileSuccess,
  dispatchSelectedAttachment,
  openAttachmentPreview,
  closeAttachmentPreview,
  fireHandleSendEmailToMembersDialogClosing,
  fireHandleSendEmailToMembersDialogOpening,
  sendEmailToMembersRequest,
  sendEmailToMembersSuccess,
  sendEmailToMembersReject,
  updateStepCheck,
  updateStepCheckSuccess,
  updateStepCheckFailure,
  saveSubscription,
  updateStorageUsed,
};
