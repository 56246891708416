/* cSpell:ignore ctoken */
import pick from "lodash/pick";
// import defaults from "lodash/defaults";
import {
  getAccessToken,
  signOut,
  refreshSessionIfNeeded,
} from "../utils/cognito.js";

const apiUrl = process.env.REACT_APP_URL_API_BASE;
const usersPath = process.env.REACT_APP_API_USERS_PATH;
const tourtlesPath = process.env.REACT_APP_API_TOURTLES_PATH;
const groupsPath = process.env.REACT_APP_API_GROUPS_PATH;
const mediaPath = process.env.REACT_APP_API_MEDIA_PATH;

const setHeaders = async () => {
  let headers = new Headers();
  headers.append("Content-Type", "application/json");

  const token = await getAccessToken();
  if (token) {
    headers.append("Authorization", token);
  }

  return headers;
};

function unauthorizedCheck(status) {
  if (status === 401) localStorage.removeItem("token");
}

const post = async (url, data) => {
  const res = await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: await setHeaders(),
    credentials: "include",
  });
  unauthorizedCheck(res.status);
  return res.json();
};

const get = async url => {
  try {
    await refreshSessionIfNeeded();

    const res = await fetch(url, {
      headers: await setHeaders(),
      credentials: "include",
    });
    if (res.status === 401) signOut();
    if (
      res.status !== 200 &&
      res.status !== 418 &&
      res.status !== 403 &&
      res.status !== 404
    )
      return false;
    return res.json();
  } catch (error) {
    console.log(error);
  }
};

const patch = async (url, data) => {
  const res = await fetch(url, {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: await setHeaders(),
    credentials: "include",
  });
  if (res.status === 401) signOut();
  return res.json();
};

const put = async object => {
  const res = await fetch(object.url, {
    method: "PUT",
    body: object.file,
    headers: await setHeaders(),
  });
  if (res.status === 401) signOut();
  return res;
};

const remove = async url => {
  const res = await fetch(url, {
    method: "DELETE",
    headers: await setHeaders(),
    credentials: "include",
  });
  if (res.status === 401) signOut();
  return res.json();
};

const removeWithData = async (url, data) => {
  const res = await fetch(url, {
    method: "DELETE",
    body: JSON.stringify(data),
    headers: await setHeaders(),
    credentials: "include",
  });
  if (res.status === 401) signOut();
  return res.json();
};

const fetchConfigurationTourtle = async tourtleId => {
  return get(
    `${apiUrl}/${tourtlesPath}/configuration${tourtleId ? "/" + tourtleId : ""}`
  );
};

const fetchExampleTourtles = async tourtleId => {
  return get(`${apiUrl}/${tourtlesPath}/${tourtleId}/examples`);
};

// Fetch Home List
const fetchTourtleListItems = async (ctoken, sectionCount) => {
  if (sectionCount === 0) {
    return get(`${apiUrl}/${tourtlesPath}?ctoken=${ctoken}`);
  } else {
    return get(
      `${apiUrl}/${tourtlesPath}?ctoken=${ctoken}&exclude_interests=true`
    );
  }
};

// Fetch Trending List
const fetchTrendingTourtleListItems = async (ctoken, sectionCount) => {
  if (sectionCount === 0) {
    return get(`${apiUrl}/tourtles/trending?ctoken=${ctoken}&limit=20`);
  } else if (sectionCount === 1) {
    return get(
      `${apiUrl}/tourtles/trending?ctoken=${ctoken}&limit=20&exclude_interests=true`
    );
  }
};

// Fetch Subscription Tourtle List
const fetchSubsTourtleListItems = async ctoken =>
  get(`${apiUrl}/tourtles/subscriptions?ctoken=${ctoken}`);

// Fetch Subscriptions
const fetchSubscriptions = async id =>
  get(`${apiUrl}/users/${id}/subscriptions`);

// Fetch Author
const fetchUserData = async id => get(`${apiUrl}/users/${id}`);

// Fetch Recently Visited Tourtle List
const fetchRecentlyVisitedTourtleListItems = async () =>
  get(`${apiUrl}/users/history/tourtles`);

// Fetch User Collection List
const fetchUserCollections = async (id, ctoken) =>
  get(`${apiUrl}/users/${id}/collections?ctoken=${ctoken}`);

// Fetch Collection Tourtle List
const fetchTourtlesForCollection = async (id, ctoken, limit = 25) =>
  get(`${apiUrl}/collections/${id}/tourtles?ctoken=${ctoken}&limit=${limit}`);

// Dubplicate Tourtle
const duplicateTourtle = async id =>
  post(`${apiUrl}/${tourtlesPath}/${id}/duplicate`);

// Report Tourtle
const reportTourtle = async (tourtleId, object) =>
  post(`${apiUrl}/${tourtlesPath}/${tourtleId}/report`, object);

// Add tourtles to group
const addTourtleToGroups = async (tourtleId, ids, notification) =>
  post(`${apiUrl}/${tourtlesPath}/${tourtleId}/${groupsPath}/add`, {
    ids,
    notification,
  });

// Add tourtle to collections
const addTourtleToCollections = async (tourtleId, array) =>
  post(`${apiUrl}/tourtles/${tourtleId}/collections/add`, array);

// Search group list items
const searchGroupListItems = async (searchQuery, ctoken) =>
  get(`${apiUrl}/groups?limit=30&ctoken=${ctoken}&search=${searchQuery}`);

//!Sent cToken and paginate
//? Data will be the same as discovery page call

// Fetch interests tourtle list
const searchTourtleListItems = async (
  searchQuery,
  ctoken,
  order,
  privacy,
  period,
  sort,
  type,
  users,
  include,
  exclude,
  categories,
  categories_exclude,
  categorie_filter,
  by,
  listType,
  listTypeId,
  limit = 10,
  extractPublishers = false
) => {
  let url = `${apiUrl}/${tourtlesPath}/search?limit=${limit}&ctoken=${ctoken}&search=${searchQuery}&order=${order}&privacy=${privacy}&period=${period}&sort=${sort}&type=${type}`;

  if (users) {
    url = url.concat(`&users=${users}`);
  }
  if (include) {
    url = url.concat(`&include=${include}`);
  }
  if (exclude) {
    url = url.concat(`&exclude=${exclude}`);
  }
  if (categories) {
    url = url.concat(`&categories=${categories}`);
    if (categories_exclude)
      url = url.concat(`&categories_exclude=${categories_exclude}`);
  }
  if (by) {
    url = url.concat(`&by=${by}`);
  }
  if (listType) {
    url = url.concat(`&listType=${listType}`);
  }
  if (listTypeId) {
    url = url.concat(`&listTypeId=${listTypeId}`);
  }
  if (extractPublishers) {
    url = url.concat(`&extractPublishers=${extractPublishers}`);
  }

  return get(url);
};

// Fetch tourtle item
const fetchTourtleItem = async (id, fields) => {
  let url = `${apiUrl}/${tourtlesPath}/${id}`;
  if (fields) {
    url = url.concat(`?fields=${fields}`);
  }
  return get(url);
};

// Fetch comments for tourtle
const fetchCommentsForTourtle = async tourtleId =>
  get(`${apiUrl}/${tourtlesPath}/${tourtleId}/reviews`);

// Search authors
const searchAuthorListItem = async (searchQuery, categories, by, sort) => {
  let url = `${apiUrl}/users/search?search=${searchQuery}`;

  if (categories) {
    url = url.concat(`&categories=${categories}`);
  }

  if (by) {
    url = url.concat(`&by=${by}`);
  }

  if (sort) {
    url = url.concat(`&sort=${sort}`);
  }

  return get(url);
};

// Delete current user
const deleteCurrentUser = async () => remove(`${apiUrl}/users/me`);

// Upload file
const uploadFile = async object => post(`${apiUrl}/${mediaPath}/file`, object);

// Real upload
const realUpload = async object => put(object);

// Get signed get url to download file
const requestSignedGetUrl = async object =>
  post(`${apiUrl}/${mediaPath}/file/download`, object);

// Fetch admin user search
const fetchAdminUserSearch = async searchPattern =>
  get(`${apiUrl}/admin/users?username=${searchPattern}`);

const searchUsers = async searchQuery =>
  get(`${apiUrl}/users/complete?search=${encodeURIComponent(searchQuery)}`);

const fetchAuthorTourtleListItems = async (userId, ctoken, exclude) =>
  get(
    `${apiUrl}/users/${userId}/tourtles?limit=10&ctoken=${ctoken}&exclude=${exclude}`
  );

const fetchTourtleItemHelpBasic = async id =>
  get(`${apiUrl}/${tourtlesPath}/help/basic`);

const fetchTourtleItemHelpAdvanced = async id =>
  get(`${apiUrl}/${tourtlesPath}/help/advanced`);

const fetchTourtleItemConfiguration = async id =>
  get(`${apiUrl}/${tourtlesPath}/671a40ab6d2bbfc42013dc1d`);

const getTourtleId = async => get(`${apiUrl}/${tourtlesPath}/id`);

const createTourtle = async object => post(`${apiUrl}/${tourtlesPath}`, object);

const deleteTourtle = async id => remove(`${apiUrl}/${tourtlesPath}/${id}`);

const updateTourtle = async (
  id,
  coverImage,
  coverAudio,
  title,
  description,
  category,
  visibility,
  commentNotifications,
  tags,
  source,
  groups,
  allowOthersToAddToGroup,
  allowOthersToDuplicate,
  showCheckboxOnSteps,
  cleanup = false
) =>
  patch(`${apiUrl}/${tourtlesPath}/${id}`, {
    coverImage,
    coverAudio,
    title,
    description,
    category,
    visibility,
    commentNotifications,
    tags,
    source,
    groups,
    allowOthersToAddToGroup,
    allowOthersToDuplicate,
    showCheckboxOnSteps,
    cleanup,
  });

const fetchCollaborators = async id =>
  get(`${apiUrl}/${tourtlesPath}/${id}/collaborators`);

const linkTourtleToTourtle = async (tourtleId, object) =>
  post(`${apiUrl}/${tourtlesPath}/${tourtleId}/tourtles/add`, object);

const removeLinkedTourtle = async (parentTourtleId, childTourtleId) =>
  remove(
    `${apiUrl}/${tourtlesPath}/${parentTourtleId}/tourtles/${childTourtleId}`
  );

const addTourtleToGroup = async (tourtleId, groupId) =>
  get(`${apiUrl}/${tourtlesPath}/${tourtleId}/${groupsPath}/${groupId}/add`);

const removeTourtleFromGroup = async (tourtleId, groupId) =>
  get(`${apiUrl}/${tourtlesPath}/${tourtleId}/${groupsPath}/${groupId}/remove`);

const updateUser = async (id, object) => patch(`${apiUrl}/users/${id}`, object);

const createStep = async (tourtleId, object) =>
  post(`${apiUrl}/${tourtlesPath}/${tourtleId}/steps`, object);

const updatePreference = async (id, preference) =>
  patch(`${apiUrl}/users/${id}`, {
    preference,
  });

const updateStep = async (tourtleId, id, object) =>
  patch(`${apiUrl}/${tourtlesPath}/${tourtleId}/steps/${id}`, object);

const moveStep = async (tourtleId, id, object) =>
  patch(`${apiUrl}/${tourtlesPath}/${tourtleId}/steps/${id}/move`, object);

const duplicateStep = async (tourtleId, id, object) =>
  post(`${apiUrl}/${tourtlesPath}/${tourtleId}/steps/${id}/duplicate`, object);

const reportStep = async (tourtleId, id, object) =>
  post(`${apiUrl}/${tourtlesPath}/${tourtleId}/steps/${id}/report`, object);

const fetchGroupItem = async id => get(`${apiUrl}/${groupsPath}/${id}`);

const createGroup = async object => post(`${apiUrl}/${groupsPath}`, object);

const updateGroup = async (id, object) =>
  patch(`${apiUrl}/${groupsPath}/${id}`, object);

const joinGroup = async id => get(`${apiUrl}/${groupsPath}/${id}/join`);

const leaveGroup = async id => get(`${apiUrl}/${groupsPath}/${id}/leave`);

const favoriteGroup = async id => get(`${apiUrl}/${groupsPath}/${id}/favorite`);

const unfavoriteGroup = async id =>
  get(`${apiUrl}/${groupsPath}/${id}/unfavorite`);

const toggleGroupNotifications = async (id, object) =>
  patch(`${apiUrl}/${groupsPath}/${id}/notifications`, object);

const reportGroup = async (id, object) =>
  post(`${apiUrl}/${groupsPath}/${id}/report`, object);

const fetchGroupMembers = async id =>
  get(`${apiUrl}/${groupsPath}/${id}/members`);

const inviteUsers = async (groupId, object) =>
  post(`${apiUrl}/${groupsPath}/${groupId}/invitations`, object);

const addCollaborators = async (tourtleId, object) =>
  post(`${apiUrl}/${tourtlesPath}/${tourtleId}/collaborators/add`, object);

const updateCollaborator = async (tourtleId, userId, object) =>
  patch(
    `${apiUrl}/${tourtlesPath}/${tourtleId}/collaborators/${userId}`,
    object
  );

const switchCollaboratorType = async (tourtleId, userId, object) =>
  patch(
    `${apiUrl}/${tourtlesPath}/${tourtleId}/collaborators/${userId}/type`,
    object
  );

const removeCollaborator = async (tourtleId, userId) =>
  remove(`${apiUrl}/${tourtlesPath}/${tourtleId}/collaborators/${userId}`);

const revokeInvitation = async (id, object) =>
  removeWithData(`${apiUrl}/${groupsPath}/${id}/invitations`, object);

const acceptRequest = async (groupId, userId, email) => {
  let object = {};
  if (userId) {
    object.userId = userId;
  } else if (email) {
    object.email = email;
  }
  return post(`${apiUrl}/${groupsPath}/${groupId}/members/accept`, object);
};

const rejectRequest = async (groupId, userId, email) => {
  let object = {};
  if (userId) {
    object.userId = userId;
  } else if (email) {
    object.email = email;
  }
  return post(`${apiUrl}/${groupsPath}/${groupId}/members/reject`, object);
};

const declineInvitation = async (groupId, userId) =>
  get(`${apiUrl}/${groupsPath}/${groupId}/invitations/${userId}/reject`);

const acceptInvitation = async (groupId, userId) =>
  get(`${apiUrl}/${groupsPath}/${groupId}/invitations/${userId}/accept`);

const removeMember = async (groupId, userId) =>
  remove(`${apiUrl}/${groupsPath}/${groupId}/members/${userId}`);

const changeGroupMember = async (groupId, userId, object) =>
  patch(`${apiUrl}/${groupsPath}/${groupId}/members/${userId}`, object);

const fetchGroupInvitations = async id =>
  get(`${apiUrl}/${groupsPath}/${id}/invitations`);

const deleteGroup = async id => remove(`${apiUrl}/${groupsPath}/${id}`);

const fetchTourtleListItemsForGroup = async (id, searchQuery, ctoken) => {
  if (searchQuery === "") {
    return get(`${apiUrl}/${groupsPath}/${id}/tourtles?ctoken=${ctoken}`);
  } else {
    return get(
      `${apiUrl}/${groupsPath}/${id}/tourtles?ctoken=${ctoken}&search=${searchQuery}`
    );
  }
};

const fetchPendingTourtlesForGroup = async id =>
  get(`${apiUrl}/${groupsPath}/${id}/pending-tourtles`);

const acceptPendingTourtle = async (id, tourtleId) =>
  get(`${apiUrl}/${groupsPath}/${id}/${tourtlesPath}/${tourtleId}/accept`);

const rejectPendingTourtle = async (id, tourtleId) =>
  get(`${apiUrl}/${groupsPath}/${id}/${tourtlesPath}/${tourtleId}/reject`);

const createComment = async (tourtleId, object) =>
  post(`${apiUrl}/${tourtlesPath}/${tourtleId}/reviews`, object);

const updateComment = async (tourtleId, reviewId, object) =>
  patch(`${apiUrl}/${tourtlesPath}/${tourtleId}/reviews/${reviewId}`, object);

const deleteComment = async (tourtleId, reviewId) =>
  remove(`${apiUrl}/${tourtlesPath}/${tourtleId}/reviews/${reviewId}`);

const reportReview = async (tourtleId, id, object) =>
  post(`${apiUrl}/${tourtlesPath}/${tourtleId}/reviews/${id}/report`, object);

const deleteRating = async (tourtleId, reviewId) =>
  remove(`${apiUrl}/${tourtlesPath}/${tourtleId}/reviews/${reviewId}`);

const deleteStep = async (tourtleId, stepId) =>
  remove(`${apiUrl}/${tourtlesPath}/${tourtleId}/steps/${stepId}`);

const fetchSuggestedTourtles = async tourtleId =>
  get(`${apiUrl}/${tourtlesPath}/${tourtleId}/suggestions`);

const fetchMyUserInfo = async () => get(`${apiUrl}/users/me`);

const fetchMyUserInfoSection = async section =>
  get(`${apiUrl}/users/me/${section}`);

const fetchMyUserData = async id => get(`${apiUrl}/users/${id}`);

const fetchUsernameAvailability = async username =>
  get(`${apiUrl}/${usersPath}?username=${username}`);

const fetchUserByName = async username => {
  const user = await get(`${apiUrl}/${usersPath}?username=${username}`);
  return pick(user, ["username", "channels", "preference"]);
};

const postSignUpRequest = async (
  username,
  firstName,
  lastName,
  company,
  channel,
  address,
  targetUrl,
  cognitoId,
  landingPageCategory
) =>
  post(`${apiUrl}/${usersPath}`, {
    username,
    firstName,
    lastName,
    company,
    channel,
    address,
    targetUrl,
    cognitoId,
    landingPageCategory,
  });

const adminRefreshStats = async () =>
  post(`${apiUrl}/admin/tasks`, {
    task: "refresh-stats",
  });

const adminUpdateIsVerifiedAndIsActive = async () =>
  get(`${apiUrl}/admin/users/verified`);

const adminUpdateCategories = async () =>
  get(`${apiUrl}/admin/ratings/convert`);

const adminAddUser = async ({ username, channel, address }) =>
  post(`${apiUrl}/admin/users`, {
    username,
    channel,
    address,
  });

const adminDeleteUser = async userId =>
  remove(`${apiUrl}/admin/users/${userId}`);

const adminUpdateUser = async (id, object) =>
  patch(`${apiUrl}/admin/users/${id}`, object);

const transferTourtle = async (tourtleId, userId) =>
  post(`${apiUrl}/admin/tourtle-transfer`, {
    tourtleId,
    userId,
  });

const getUserSubscribers = async userId =>
  get(`${apiUrl}/users/${userId}/subscribers`);

const subscribe = async userId => get(`${apiUrl}/users/${userId}/subscribe`);

const unsubscribe = async userId =>
  get(`${apiUrl}/users/${userId}/unsubscribe`);

const handleSubscriptionNotification = async (userId, notifications) =>
  patch(`${apiUrl}/users/${userId}/subscription`, {
    notifications,
  });

const updateNotifications = async (
  userId,
  subscribe,
  unsubscribe,
  subscriptions
) =>
  patch(`${apiUrl}/users/${userId}/notifications`, {
    subscribe,
    unsubscribe,
    subscriptions,
  });

const updateAuthExpiration = async (userId, authExpiration) =>
  patch(`${apiUrl}/users/${userId}/auth-expiration`, { authExpiration });

const updateShowLandingPage = async (userId, showLandingPage) =>
  patch(`${apiUrl}/users/${userId}/landing-page`, { showLandingPage });

const fetchCollection = async id => get(`${apiUrl}/collections/${id}`);

const createCollection = async object => post(`${apiUrl}/collections`, object);

const changeTourtlePositionInCollection = async (
  collectionId,
  tourtleId,
  position
) =>
  patch(
    `${apiUrl}/collections/${collectionId}/tourtles/${tourtleId}/move`,
    position
  );

const changeCollectionPosition = async (userId, collectionId, position) =>
  patch(`${apiUrl}/users/${userId}/collections/${collectionId}/move`, position);

const updateCollection = async (
  id,
  name,
  description,
  category,
  coverImage,
  visibility,
  tags
) =>
  patch(`${apiUrl}/collections/${id}`, {
    category,
    description,
    name,
    coverImage,
    // visibility,
    tags,
  });

const deleteCollection = async id => remove(`${apiUrl}/collections/${id}`);

const addTourtlesToCollection = async (id, array) =>
  post(`${apiUrl}/collections/${id}/add`, array);

const removeTourtlesToCollection = async (id, array) =>
  post(`${apiUrl}/collections/${id}/remove`, array);

const reportCollection = async (id, object) =>
  post(`${apiUrl}/collections/${id}/report`, object);

const logoutUser = async () => get(`${apiUrl}/users/logout`);

const getCollectionIdsForTourtle = async tourtleId =>
  get(`${apiUrl}/tourtles/${tourtleId}/collections`);

const handleReactOnTourtle = async (tourtleId, object) =>
  post(`${apiUrl}/tourtles/${tourtleId}/reactions`, object);

const handleRemoveReactionOnTourtle = async tourtleId =>
  remove(`${apiUrl}/tourtles/${tourtleId}/reactions`);

const handleFavoriteTourtles = async (tourtleId, favorite) =>
  get(`${apiUrl}/tourtles/${tourtleId}/favorite/${favorite}`);

const getAllFavorites = async collectionId =>
  get(`${apiUrl}/collections/${collectionId}/tourtles`);

const getGeneralReport = async () => get(`${apiUrl}/admin/statistics`);

const getUsersReport = async () => get(`${apiUrl}/admin/statistics/users`);

const requestTextModeration = async () =>
  get(`${apiUrl}/admin/statistics/text-moderation`);

const requestModerationReport = async id => get(`${apiUrl}/violation/${id}`);

const getBlackAndWhiteList = async () =>
  get(`${apiUrl}/admin/moderation/lists`);

const manageBlackAndWhiteList = async (word, list, action) =>
  post(`${apiUrl}/admin/moderation/${list}/${action}`, word);

const getUserGroups = async userId => get(`${apiUrl}/users/${userId}/groups`);

const testEmailAddress = async email =>
  get(`${apiUrl}/users/channel/email?email=${encodeURIComponent(email)}`);

const verifySignUp = async user => post(`${apiUrl}/users/verify-sign-up`, user);

const getFailedSignIn = async credential =>
  post(`${apiUrl}/users/failed-sign-in`, { address: credential });

const forgotPassword = async credential =>
  post(`${apiUrl}/users/forgot-password`, { address: credential });

const resetPassword = async credential =>
  post(`${apiUrl}/users/reset-password`, credential);

const groupDiscovery = async ctoken =>
  get(`${apiUrl}/groups?limit=10&ctoken=${ctoken}`);

const changeTourtlePositionInGroup = async (groupId, tourtleId, position) =>
  patch(`${apiUrl}/groups/${groupId}/tourtles/${tourtleId}/move`, position);

const addTourtlesToGroup = async (groupId, tourtles) => {
  return post(`${apiUrl}/groups/${groupId}/add`, { ids: tourtles });
};

const sendEmailToGroupMembers = async (groupId, data) => {
  return post(`${apiUrl}/groups/${groupId}/send-email`, data);
};

const getSubscriptions = async () => get(`${apiUrl}/subscriptions`);

const updateSubscriptionLevel = async (subscriptionId, data) =>
  patch(`${apiUrl}/subscriptions/${subscriptionId}`, data);

const updateUserSubscription = async (userId, data) =>
  patch(`${apiUrl}/users/${userId}/subscription-package`, data);

const getSubscriptionLevelsTourtle = async () =>
  get(`${apiUrl}/tourtles/help/subscriptions`);

const getStorageUsed = async userId =>
  get(`${apiUrl}/users/${userId}/stats/totalStorageUsed`);

const getTourtleHistory = async tourtleId =>
  get(`${apiUrl}/tourtles/${tourtleId}/history`);

const getStepSize = async (tourtleId, stepId) =>
  get(`${apiUrl}/tourtles/${tourtleId}/steps/${stepId}/size`);

export default {
  fetchConfigurationTourtle,
  fetchExampleTourtles,
  fetchTourtleListItems,
  fetchTrendingTourtleListItems,
  fetchSubsTourtleListItems,
  fetchSubscriptions,
  fetchAuthorTourtleListItems,
  fetchUserData,
  fetchRecentlyVisitedTourtleListItems,
  fetchUserCollections,
  fetchTourtlesForCollection,
  duplicateTourtle,
  reportTourtle,
  addTourtleToGroups,
  addTourtleToCollections,
  searchGroupListItems,
  searchTourtleListItems,
  fetchTourtleItem,
  fetchCommentsForTourtle,
  searchAuthorListItem,
  deleteCurrentUser,
  adminAddUser,
  adminDeleteUser,
  adminUpdateUser,
  adminRefreshStats,
  adminUpdateIsVerifiedAndIsActive,
  adminUpdateCategories,
  fetchAdminUserSearch,
  fetchMyUserInfo,
  uploadFile,
  realUpload,
  requestSignedGetUrl,
  fetchMyUserData,
  updateUser,
  getTourtleId,
  createTourtle,
  deleteTourtle,
  updateTourtle,
  fetchCollaborators,
  fetchGroupItem,
  createGroup,
  updateGroup,
  joinGroup,
  leaveGroup,
  favoriteGroup,
  unfavoriteGroup,
  toggleGroupNotifications,
  reportGroup,
  fetchGroupInvitations,
  fetchTourtleListItemsForGroup,
  addTourtleToGroup,
  removeTourtleFromGroup,
  changeGroupMember,
  deleteGroup,
  inviteUsers,
  addCollaborators,
  updateCollaborator,
  switchCollaboratorType,
  removeCollaborator,
  fetchGroupMembers,
  acceptPendingTourtle,
  rejectPendingTourtle,
  revokeInvitation,
  acceptRequest,
  rejectRequest,
  removeMember,
  searchUsers,
  createStep,
  updateStep,
  moveStep,
  duplicateStep,
  reportStep,
  createComment,
  updateComment,
  deleteComment,
  reportReview,
  deleteRating,
  fetchUserByName,
  fetchUsernameAvailability,
  postSignUpRequest,
  deleteStep,
  logoutUser,
  updatePreference,
  transferTourtle,
  declineInvitation,
  acceptInvitation,
  fetchPendingTourtlesForGroup,
  getUserSubscribers,
  subscribe,
  unsubscribe,
  handleSubscriptionNotification,
  updateNotifications,
  updateAuthExpiration,
  updateShowLandingPage,
  createCollection,
  updateCollection,
  deleteCollection,
  addTourtlesToCollection,
  removeTourtlesToCollection,
  reportCollection,
  fetchCollection,
  changeTourtlePositionInCollection,
  changeCollectionPosition,
  getCollectionIdsForTourtle,
  fetchSuggestedTourtles,
  linkTourtleToTourtle,
  removeLinkedTourtle,
  fetchTourtleItemHelpBasic,
  fetchTourtleItemHelpAdvanced,
  fetchTourtleItemConfiguration,
  handleFavoriteTourtles,
  getAllFavorites,
  getGeneralReport,
  getUsersReport,
  requestTextModeration,
  requestModerationReport,
  getBlackAndWhiteList,
  manageBlackAndWhiteList,
  getUserGroups,
  fetchMyUserInfoSection,
  testEmailAddress,
  verifySignUp,
  getFailedSignIn,
  forgotPassword,
  resetPassword,
  groupDiscovery,
  changeTourtlePositionInGroup,
  addTourtlesToGroup,
  sendEmailToGroupMembers,
  handleReactOnTourtle,
  handleRemoveReactionOnTourtle,
  getSubscriptions,
  updateSubscriptionLevel,
  updateUserSubscription,
  getSubscriptionLevelsTourtle,
  getStorageUsed,
  getTourtleHistory,
  getStepSize,
};
