import React, { Component } from "react";

// Material UI
import { Typography, Grid } from "@material-ui/core";

import "./WarningMessage.css";

class WarningMessage extends Component {
  render() {
    const {
      classes,
      type,
      shouldHighlightFilterButton,
    } = this.props;

    return (
      <Grid container justify="center">
        <Grid item xs={12} sm={8} md={6} lg={4}>
          {type === "aboutAuthor" && (
            <Typography className="non-found">
              This tourtler does not have any Public tourtles yet.
            </Typography>
          )}
          {type === "recentlyVisited" && (
            <Typography className="non-found">
              You have not visited any tourtles yet.
            </Typography>
          )}
          {type === "noSteps" && (
            <Typography className="non-found">
              This tourtle does not contain any items/steps yet.
            </Typography>
          )}
          {(type === "userTourtles" || type === "currentUser") && (
            <div>
              <Typography className="non-found">
                This page shows tourtles you have created, 
                but you do not have any tourtles yet. Create your first tourtle by 
                selecting <strong>Create</strong> below.
              </Typography>
              <div
                style= {{margin:"auto", marginTop: "20px", width:"55%"}}
              >
                <Typography className="non-found">
                  Your tourtle can be anything you want it to be:
                  <ul>
                    <li>To-do list</li>
                    <li>Travel plans</li>
                    <li>Planning an Event</li>
                    <li>Instructions</li>
                    <li>Photos</li>
                    <li>Links to web sites</li>
                    <li>Shopping lists</li>
                    <li>Bucket list</li>
                  </ul>
                </Typography>
              </div>
              <Typography className="non-found">
                  <div style={{textAlign:"center"}}>
                    Select <b>Explore</b> below to see tourtles others have created, 
                    or Groups to join a group.
                  </div>
              </Typography>
            </div>
          )}
          {type === "manageTourtles" && (
            <Typography className="non-found">
              You currently do not have any tourtles that can be added. To add
              tourtles from other users, find them using “Search for tourtles”
              above.
            </Typography>
          )}
          {type === "create-collection" && (
            <Typography
              className="non-found"
              style={{ marginTop: "0px", width: "100%" }}
            >
              You do not have any tourtles yet. To add tourtles from other
              users, find them using “Search for tourtles” above.
            </Typography>
          )}
          {type === "collection" && (
            <Typography
              className="non-found"
              style={{ marginTop: "0px", width: "100%" }}
            >
              There are no tourtles in this collection.
            </Typography>
          )}
          {type === "collectionList" && (
            <div>
              <Typography className="non-found">
                You do not have any Collections yet. To create a new Collection,
                click on the “New Collection” button below.
              </Typography>
              <div
                style= {{margin:"auto", marginTop: "20px", width:"75%"}}
              >
                <Typography className="non-found">
                  A Collection is like a playlist and contains tourtles grouped however you want.
                  <ul>
                    <li>Photo albums</li>
                    <li>Recipes</li>
                    <li>How-to's</li>
                    <li>Business articles</li>
                    <li>Home improvement</li>
                    <li>Fitness</li>
                    <li>Travel</li>
                  </ul>
                </Typography>
              </div>
            </div>
          )}
          {type === "groupsManaged" && (
            <Typography
              className="non-found"
            >
              You don’t manage any Groups yet. You can create a Group by selecting 
              the “Create group” button below and invite people to your Group using 
              their email address or username.
            </Typography>
          )}
          {type === "subscription" && (
            <Typography
              className="non-found"
              style={{ paddingTop: "10px", width: "100%" }}
            >
              You are not following anyone yet. You can follow people by
              searching for them and finding them under TOURTLERS tab, or by
              clicking on their names on their tourtles.
            </Typography>
          )}
          {type === "subscriptionList" && (
            <Typography className="non-found">
              The tourtlers you follow to do not have any tourtles yet.
            </Typography>
          )}
          {type === "withInterests" && (
            <Typography className="non-found">
              No results found for the selected interests.
            </Typography>
          )}
          {(type === "withoutFilter" || type === "manageTourtlesSearch") && (
            <Typography className="non-found">
              No results found for this search criteria
              {!shouldHighlightFilterButton ? "." : ""}{" "}
              {shouldHighlightFilterButton ? "and filter setting." : ""}
            </Typography>
          )}
          {(type === "originInterests" ||
            type === "interestFilter" ||
            type === "interestAuthorFilter") && (
            <Typography className="non-found">
              No results found for this filter setting.
            </Typography>
          )}
          {type === "home" && (
            <Typography className="non-found">
              No tourtles found based on your current Preference settings.
            </Typography>
          )}
          {type === "caseOne" && (
            <Typography className="non-found">
              No results found for this search criteria
              {!shouldHighlightFilterButton ? "." : ""}{" "}
              {shouldHighlightFilterButton ? "and filter setting." : ""}
            </Typography>
          )}
          {type === "caseTwo" && (
            <Typography className="non-found">
              No results found for this search criteria
              {!shouldHighlightFilterButton ? "." : ""}{" "}
              {shouldHighlightFilterButton ? "and filter setting." : ""}
            </Typography>
          )}
          {type === "caseThree" && (
            <Typography
              className={classes.startSearch}
              style={
                this.props.showAppBar === true
                  ? { marginTop: "45px" }
                  : { marginTop: "0px" }
              }
            >
              No results found for this filter setting.{" "}
            </Typography>
          )}
          {type === "groupPage" && (
            <Typography className="non-found">
              You are not a member of any Group. To create a new Group, click on
              the “Create Group” button below.
            </Typography>
          )}
          {type === "groupPageWithInvites" && (
            <Typography className="non-found">
              Click on the group(s) shown above to Accept or Decline the
              invitation(s).
            </Typography>
          )}
        </Grid>
      </Grid>
    );
  }
}
export default WarningMessage;
