const CATEGORIES = {
  uncategorized: "Uncategorized",
  arts: "Arts & Culture",
  business: "Business",
  vehicles: "Cars & Vehicles",
  neighborhood: "Community",
  education: "Education",
  entertainment: "Entertainment",
  environment: "Environment",
  family: "Family",
  fashion: "Fashion & Style",
  food: "Food & Drink",
  friends: "Friends",
  games: "Games & Gaming",
  health: "Health & Fitness",
  history: "History",
  hobbies: "Hobby & Leisure",
  home: "Home & Garden",
  style: "How-to",
  humor: "Humor",
  literature: "Literature",
  movie: "Movies & TV",
  music: "Music",
  nonprofits: "Nonprofits",
  people: "People & Blogs",
  pets: "Pets & Animals",
  photography: "Photography",
  politics: "Politics & News",
  psychology: "Psychology",
  science: "Science & Tech",
  shopping: "Shopping",
  spiritual: "Spiritual",
  sport: "Sports & Outdoor",
  travel: "Travel & Events",
  world: "World",
};

export default CATEGORIES;