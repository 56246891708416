// import loadable from "@loadable/component";
import React, { Component } from "react";
import { Provider } from "react-redux";
import { Route } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import { connect } from "react-redux";
import ms from "ms";
import { signOut } from "../utils/cognito.js";

import "./scss/app.scss";

import actions from "../ducks/actions";
import selectors from "../ducks/selectors";

import api from "../api";

// Material UI
import { MuiThemeProvider } from "@material-ui/core/styles";

// Theme
import TourtleTheme from "../theme";

// Components
import NewLandingPage from "./LandingPage/NewLandingPage";
import HomeContainer from "./HomeContainer/HomeContainer";
import ManageCollaboration from "./TourtleScreens/ManageCollaboration";
import LinkTourtle from "./TourtleScreens/LinkTourtle";
import UploadMultipleImages from "./common/UploadMultipleImages/UploadMultipleImages";
import { TourtleContainer, AddTourtleNew, AddStep } from "./TourtleScreens";
import {
  SignInUp,
  TermsOfService,
  PrivacyPolicy,
  CopyrightPolicy,
  AcceptableUsePolicy,
  CookiePolicy,
  SignInUpDialog,
  ResetPassword,
} from "./SignInUpScreens";
import {
  AddUser,
  AdminMainMenu,
  ReportingActivity,
  ReportingCollections,
  ReportingGroups,
  ReportingTourtles,
  ReportingUsers,
  TransferTourtle,
  SubscriptionParameters,
} from "./AdminScreens";
import {
  GroupPage,
  CreateUpdateGroup,
  Members,
  AdminTools,
  MemberRequestList,
  Settings,
  MonitorTourtles,
} from "./GroupScreens";
import Collection from "./Collections/CollectionPage";
import ManageTourtles from "./Collections/ManageTourtles";
import UpdateCollection from "./Collections/UpdateCollection";
import CreateCollection from "./Collections/CreateCollection";
import MySubscribers from "./SubscriptionsScreens/MySubscribers";

import TextModeration from "./AdminScreens/Reporting/TextModeration";
import BlackAndWhiteList from "./AdminScreens/Reporting/BlackAndWhiteList";
import MainMenu from "./common/MainMenu";
import AuthorPage from "./Author/AuthorContainer";
import AllSubscriptions from "./SubscriptionsPage/AllSubscriptions";
import EditProfile from "./Settings/EditProfile";
import SubscriptionLevel from "./Settings/SubscriptionLevel";
import MainSettingsPage from "./Settings/MainSettingsPage";
import Policies from "./Settings/Policies";
import Preferences from "./Settings/Preferences";
import LogoutPage from "./Settings/LogoutPage";
import Help from "./HelpScreens/Help";
import InterestsFilter from "./common/Interests/InterestsFilter";
import InterestResultsPage from "./common/FilterResultDialog";
import SearchPage from "./common/SearchDialog";
import InterestsDialog from "./common/Interests/InterestsDialog";
import DeleteCollectionDialog from "./Collections/Dialogs/DeleteCollectionDialog";
import ContentModerationDialog from "./common/ContentModerationDialog";

import ShareDialog from "./common/ShareDialog";
import DeleteTourtleDialog from "./common/DeleteTourtleDialog";
import ReportDialog from "./common/ReportDialog";
import AddTourtleToCollectionDialog from "./common/AddTourtleToCollectionDialog";
import AddTourtleToGroupDialog from "./common/AddTourtleToGroupDialog";
import LoginDialog from "./common/LoginDialog";
import SignOutDialog from "./common/SignOutDialog";
import Snackbar from "./common/SnackBar";
import CookiesDialog from "./common/CookiesDialog";
import FilterDialog from "./common/FilterDialog";

import "./Root.css";
import { isInstalled } from "../utils/pwa.js";
import { isIOS } from "react-device-detect";

// const NewLandingPage = loadable(() => import("./LandingPage/NewLandingPage"));
// const HomeContainer = loadable(() => import("./HomeContainer/HomeContainer"));
// const ManageCollaboration = loadable(() =>
//   import("./TourtleScreens/ManageCollaboration")
// );
// const LinkTourtle = loadable(() => import("./TourtleScreens/LinkTourtle"));
// const UploadMultipleImages = loadable(() =>
//   import("./common/UploadMultipleImages/UploadMultipleImages")
// );
// const TourtleContainer = loadable(() =>
//   import("./TourtleScreens/TourtleContainer")
// );
// const AddTourtleNew = loadable(() => import("./TourtleScreens/AddTourtleNew"));
// const AddStep = loadable(() => import("./TourtleScreens/AddStep"));
// const SignInUp = loadable(() => import("./SignInUpScreens/SignInUp"));
// const TermsOfService = loadable(() =>
//   import("./SignInUpScreens/TermsOfService")
// );
// const PrivacyPolicy = loadable(() => import("./SignInUpScreens/PrivacyPolicy"));
// const CopyrightPolicy = loadable(() =>
//   import("./SignInUpScreens/CopyrightPolicy")
// );
// const AcceptableUsePolicy = loadable(() =>
//   import("./SignInUpScreens/AcceptableUsePolicy")
// );
// const CookiePolicy = loadable(() => import("./SignInUpScreens/CookiePolicy"));
// const SignInUpDialog = loadable(() =>
//   import("./SignInUpScreens/SignInUpDialog")
// );
// const ResetPassword = loadable(() => import("./SignInUpScreens/ResetPassword"));
// const AddUser = loadable(() => import("./AdminScreens/AddUser"));
// const AdminMainMenu = loadable(() => import("./AdminScreens/AdminMainMenu"));
// const ReportingActivity = loadable(() =>
//   import("./AdminScreens/Reporting/Activity")
// );
// const ReportingCollections = loadable(() =>
//   import("./AdminScreens/Reporting/Collections")
// );
// const ReportingGroups = loadable(() =>
//   import("./AdminScreens/Reporting/Groups")
// );
// const ReportingTourtles = loadable(() =>
//   import("./AdminScreens/Reporting/Tourtles")
// );
// const ReportingUsers = loadable(() => import("./AdminScreens/Reporting/Users"));
// const TransferTourtle = loadable(() =>
//   import("./AdminScreens/TransferTourtle")
// );
// const GroupPage = loadable(() => import("./GroupScreens/GroupPage"));
// const CreateUpdateGroup = loadable(() =>
//   import("./GroupScreens/CreateUpdateGroup")
// );
// const Members = loadable(() => import("./GroupScreens/Members"));
// const AdminTools = loadable(() => import("./GroupScreens/AdminTools"));
// const MemberRequestList = loadable(() =>
//   import("./GroupScreens/MemberRequestList")
// );
// const Settings = loadable(() => import("./GroupScreens/Settings"));
// const MonitorTourtles = loadable(() =>
//   import("./GroupScreens/MonitorTourtles")
// );
// const Collection = loadable(() => import("./Collections/CollectionPage"));
// const ManageTourtles = loadable(() => import("./Collections/ManageTourtles"));
// const UpdateCollection = loadable(() =>
//   import("./Collections/UpdateCollection")
// );
// const CreateCollection = loadable(() =>
//   import("./Collections/CreateCollection")
// );
// const MySubscribers = loadable(() =>
//   import("./SubscriptionsScreens/MySubscribers")
// );

// const TextModeration = loadable(() =>
//   import("./AdminScreens/Reporting/TextModeration")
// );
// const BlackAndWhiteList = loadable(() =>
//   import("./AdminScreens/Reporting/BlackAndWhiteList")
// );
// const MainMenu = loadable(() => import("./common/MainMenu"));
// const AuthorPage = loadable(() => import("./Author/AuthorContainer"));
// const AllSubscriptions = loadable(() =>
//   import("./SubscriptionsPage/AllSubscriptions")
// );
// const EditProfile = loadable(() => import("./Settings/EditProfile"));
// const MainSettingsPage = loadable(() => import("./Settings/MainSettingsPage"));
// const Policies = loadable(() => import("./Settings/Policies"));
// const Preferences = loadable(() => import("./Settings/Preferences"));
// const LogoutPage = loadable(() => import("./Settings/LogoutPage"));
// const Help = loadable(() => import("./HelpScreens/Help"));
// const InterestsFilter = loadable(() =>
//   import("./common/Interests/InterestsFilter")
// );
// const InterestResultsPage = loadable(() =>
//   import("./common/FilterResultDialog")
// );
// const SearchPage = loadable(() => import("./common/SearchDialog"));
// const InterestsDialog = loadable(() =>
//   import("./common/Interests/InterestsDialog")
// );
// const DeleteCollectionDialog = loadable(() =>
//   import("./Collections/Dialogs/DeleteCollectionDialog")
// );
// const ContentModerationDialog = loadable(() =>
//   import("./common/ContentModerationDialog")
// );

// const ShareDialog = loadable(() => import("./common/ShareDialog"));
// const DeleteTourtleDialog = loadable(() =>
//   import("./common/DeleteTourtleDialog")
// );
// const ReportDialog = loadable(() => import("./common/ReportDialog"));
// const AddTourtleToCollectionDialog = loadable(() =>
//   import("./common/AddTourtleToCollectionDialog")
// );
// const AddTourtleToGroupDialog = loadable(() =>
//   import("./common/AddTourtleToGroupDialog")
// );
// const LoginDialog = loadable(() => import("./common/LoginDialog"));
// const SignOutDialog = loadable(() => import("./common/SignOutDialog"));
// const Snackbar = loadable(() => import("./common/SnackBar"));
// const CookiesDialog = loadable(() => import("./common/CookiesDialog"));
// const FilterDialog = loadable(() => import("./common/FilterDialog"));

class Root extends Component {
  state = {
    ready: false,
  };

  async componentDidMount() {
    const { loginGetCurrentUser } = this.props;
    await this.checkForAuthExpiration();
    await loginGetCurrentUser();
    this.accountCanNotBeVerified();
    this.fetchCurrentUserCollectionList();

    if (isInstalled && isIOS) {
      document.addEventListener("touchend", this.handleScroll);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userInfo === null && this.props.userInfo !== null) {
      this.fetchCurrentUserCollectionList();
    }
  }

  handleScroll = e => {
    if (0 - window.scrollY > 150) {
      window.location.reload();
    }
  };

  async checkForAuthExpiration() {
    const signedInTimestamp = window.localStorage.getItem(
      "signed_in_timestamp"
    );
    const authExpirationTimestamp = window.localStorage.getItem(
      "auth_expiration_timestamp"
    );

    if (!signedInTimestamp || !authExpirationTimestamp) {
      return;
    }

    try {
      if (
        Date.now() / 1000 >
        parseInt(signedInTimestamp) + ms(authExpirationTimestamp) / 1000
      ) {
        await signOut();
      }
    } catch (error) {
      console.log(error);
    }
  }

  accountCanNotBeVerified() {
    const { history, openSnackbar } = this.props;
    if (history.location.search.includes("verified=false")) {
      openSnackbar("root_error", "This account can not be verified.");
    }
  }

  fetchCurrentUserCollectionList = async () => {
    const {
      getCurrentUserCollectionList,
      getCurrentUserCollectionListSuccess,
      getCurrentUserCollectionListFailure,
      collectionIsFetching,
      collectionHasNext,
      collectionCToken,
      userInfo,
    } = this.props;
    try {
      if (!collectionIsFetching && collectionHasNext) {
        getCurrentUserCollectionList();
        const response = await api.fetchUserCollections(
          userInfo.id,
          collectionCToken
        );
        getCurrentUserCollectionListSuccess(response);
        await this.checkLandingPage();
        this.setState({ ready: true });
        this.fetchCurrentUserFavoriteTourtles();
      }
    } catch (error) {
      await this.checkLandingPage();
      this.setState({ ready: true });
      getCurrentUserCollectionListFailure(error);
    } finally {
    }
  };

  fetchCurrentUserFavoriteTourtles = async () => {
    const {
      getCurrentUserFavoriteList,
      getCurrentUserFavoriteListSuccess,
      getCurrentUserFavoriteListFailure,
      favoritesIsFetching,
      favoritesHasNext,
      favoritesCToken,
      collectionItems,
    } = this.props;
    try {
      if (!favoritesIsFetching && favoritesHasNext) {
        const favoriteCollectionId = collectionItems
          ? collectionItems.find(x => x.type === "FAVORITES").id
          : "";
        getCurrentUserFavoriteList();
        const response = await api.fetchTourtlesForCollection(
          favoriteCollectionId,
          favoritesCToken,
          1000
        );
        getCurrentUserFavoriteListSuccess(response);
      }
    } catch (error) {
      getCurrentUserFavoriteListFailure(error);
    }
  };

  checkLandingPage = async () => {
    const {
      userInfo,
      history,
      cookieDialog,
      setFirstUse,
      openSnackbar,
    } = this.props;
    if (history.location.search.includes("expired=true")) {
      openSnackbar("root_error", "Sorry, that link has expired.");
    }

    if (userInfo && userInfo.id) {
      if (
        (history.location.state && history.location.state.referer) ||
        history.location.referer
      ) {
        if (
          ((history.location.state &&
            history.location.state.referer === "/groups") ||
            history.location.referer === "/groups") &&
          (userInfo.groups.length > 0 ||
            (userInfo.groupMembershipRequests &&
              userInfo.groupMembershipRequests.filter(
                item => item.status === "INVITED"
              ).length > 0))
        ) {
          this.props.setGroupTabValue(1);
        }
        history.replace(
          `${
            history.location.state
              ? history.location.state.referer
                ? history.location.state.referer
                : history.location.referer
              : history.location.referer
          }`
        );
      } else if (
        (history.location.search.includes("first_visit=true") ||
          (!userInfo.lastActiveAt && userInfo.interests.length === 0)) &&
        !cookieDialog.open
      ) {
        if (history.location.pathname.includes("/group-page/")) {
          setFirstUse(true, history.location.pathname);
        } else {
          setFirstUse(true, "");
        }
        history.replace("/interests");
      } else if (!history.location.search.includes("referer")) {
        // if (
        //   history.location.pathname === "/" &&
        //   userInfo.settings.showLandingPage
        // ) {
        //   history.replace("/library");
        // } else if (history.location.pathname === "/sign-in-up/login") {
        //   if (userInfo.settings.showLandingPage) {
        //     history.replace("/library");
        //   } else {
        //     history.replace("/library");
        //   }
        // }
        if (
          history.location.pathname === "/" ||
          history.location.pathname.includes("/sign-in-up")
        ) {
          if (
            userInfo.settings.startPage &&
            userInfo.settings.startPage !== "default"
          ) {
            history.replace(`/${userInfo.settings.startPage}`);
            return;
          }

          history.replace("/library/mine");
        }
      }
    } else if (userInfo === null || userInfo === undefined) {
      if (history.location.pathname === "/") {
        history.replace({ pathname: "/landing-page" });
      }

      // let cookies = await Cookies.getJSON();

      // if (
      //   !keys(cookies).includes("CONSENT") &&
      //   !history.location.pathname.includes("/sign-in-up")
      // ) {
      //   // cookieDialogOpening(); // Enable if cookie dialog needs to be shown on any page

      //   if (
      //     history.location.pathname === "/" ||
      //     history.location.pathname === "/landing-page"
      //   ) {
      //     cookieDialogOpening(); // Remove if cookie dialog needs to be shown on any page

      //     // history.push({
      //     //   pathname: "/tourtle/5fa8451aff2044d2a6f2856c/what-is-tourtle",
      //     // });
      //   }
      // }
    }
  };

  render() {
    const { history, store, ...props } = this.props;
    return (
      <Provider store={store}>
        <MuiThemeProvider theme={TourtleTheme}>
          <ConnectedRouter history={history}>
            {this.state.ready && (
              <div>
                <MainMenu history={history} />

                <Route exact path="/landing-page/:configurationName?" render={NewLandingPage} />
                <Route
                  exact
                  path="/:page(home|groups|groups/discover|groups/mine|trending|subscriptions|library/mine|library/recent|library/collections)"
                  component={HomeContainer}
                />
                <Route
                  path="/tourtle/:id?/:slug?"
                  component={TourtleContainer}
                />
                <Route path="/addtourtle" component={AddTourtleNew} />
                <Route
                  exact
                  path="/update-tourtle/:id"
                  component={AddTourtleNew}
                />
                <Route path="/addstep" component={AddStep} />
                <Route
                  exact
                  path="/update-tourtle/:tourtleId/update-step/:id"
                  component={AddStep}
                />
                <Route path="/link-tourtle" component={LinkTourtle} />
                <Route
                  path="/add-multiple-images"
                  component={UploadMultipleImages}
                />
                <Route path="/transfer/:id" component={TransferTourtle} />
                <Route
                  path="/manage-collaboration/:id"
                  component={ManageCollaboration}
                />

                <Route
                  exact
                  path="/group-page/:id?/:slug?"
                  component={GroupPage}
                />
                <Route path="/create-group" component={CreateUpdateGroup} />
                <Route
                  exact
                  path="/update-group/:id"
                  component={CreateUpdateGroup}
                />
                <Route path="/user/:id/subscribers" component={MySubscribers} />
                <Route
                  path="/group-page/:id/:slug/group-members"
                  component={Members}
                />
                <Route
                  exact
                  path="/group-page/:id/:slug/admin-tools"
                  component={AdminTools}
                />
                <Route
                  path="/group-page/:id/:slug/member-requests"
                  component={MemberRequestList}
                />
                <Route
                  path="/group-page/:id/:slug/group-settings"
                  component={Settings}
                />
                <Route
                  path="/group-page/:id/:slug/moderate-tourtles"
                  component={MonitorTourtles}
                />
                <Route
                  path="/collection-page/:id([a-f0-9]{24})"
                  component={Collection}
                />
                <Route
                  exact
                  path="/:type/add-tourtles"
                  component={ManageTourtles}
                />
                <Route
                  exact
                  path="/update-collection/:id([a-f0-9]{24})"
                  component={UpdateCollection}
                />
                <Route
                  exact
                  path="/create-collection"
                  component={CreateCollection}
                />

                <Route path="/sign-in-up" component={SignInUp} />
                <Route
                  exact
                  path="/author/:id?/:slug?"
                  component={AuthorPage}
                />
                <Route
                  exact
                  path="/subscriptions/all"
                  component={AllSubscriptions}
                />
                <Route
                  exact
                  path="/filter-interests"
                  component={InterestsFilter}
                />
                <Route
                  path="/interests-results"
                  component={InterestResultsPage}
                />
                <Route path="/search" component={SearchPage} />

                <Route path="/interests" component={InterestsDialog} />
                <Route exact path="/settings" component={MainSettingsPage} />
                <Route exact path="/settings/profile" component={EditProfile} />
                <Route
                  exact
                  path="/settings/subscription-level"
                  component={SubscriptionLevel}
                />
                <Route exact path="/policies" component={Policies} />
                <Route path="/admin" exact component={AdminMainMenu} />
                <Route path="/admin/add-user" component={AddUser} />
                <Route
                  path="/admin/subscription-parameters"
                  component={SubscriptionParameters}
                />
                <Route
                  path="/admin/reporting/activity"
                  component={ReportingActivity}
                />
                <Route
                  path="/admin/reporting/collections"
                  component={ReportingCollections}
                />
                <Route
                  path="/admin/reporting/groups"
                  component={ReportingGroups}
                />
                <Route
                  path="/admin/reporting/tourtles"
                  component={ReportingTourtles}
                />
                <Route
                  path="/admin/reporting/users"
                  component={ReportingUsers}
                />
                <Route
                  path="/admin/reporting/moderateText"
                  component={TextModeration}
                />
                <Route
                  path="/admin/reporting/black-whitelist"
                  component={BlackAndWhiteList}
                />
                <Route
                  path="/legal-docs/terms-of-service"
                  component={TermsOfService}
                />
                <Route
                  path="/legal-docs/privacy-policy"
                  component={PrivacyPolicy}
                />
                <Route
                  path="/legal-docs/copyright-policy"
                  component={CopyrightPolicy}
                />
                <Route
                  path="/legal-docs/acceptable-use"
                  component={AcceptableUsePolicy}
                />
                <Route
                  path="/legal-docs/cookie-policy"
                  component={CookiePolicy}
                />
                <Route
                  exact
                  path="/settings/preferences"
                  component={Preferences}
                />
                <Route
                  path="/sign-in-up/reset-password"
                  component={ResetPassword}
                />
                <Route path="/help" component={Help} />
                <Route path="/logout" component={LogoutPage} />

                {props.shareDialog.open && <ShareDialog />}
                {props.reportDialog.open && <ReportDialog />}
                {props.deleteTourtleDialog.open && <DeleteTourtleDialog />}
                {props.addTourtleToCollectionDialog.open && (
                  <AddTourtleToCollectionDialog />
                )}
                {props.loginDialog.open && <LoginDialog />}
                {props.signInUpDialog.open && <SignInUpDialog />}
                {props.signOutDialog.open && <SignOutDialog />}
                {props.cookieDialog.open && <CookiesDialog />}
                <FilterDialog />
                {props.deleteCollectionDialog.open && (
                  <DeleteCollectionDialog />
                )}
                {props.violationState.open && <ContentModerationDialog />}
                {props.addTourtleToGroupDialog.open && (
                  <AddTourtleToGroupDialog />
                )}

                <Snackbar
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  open={this.props.areStatisticsFresh}
                  ContentProps={{
                    "aria-describedby": "message-id",
                  }}
                  message={<span id="message-id">Refresh complete</span>}
                  onClick={this.props.onClearFreshStatsIndication}
                />
                <Snackbar />
                <Snackbar
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  open={this.props.snackbar.open}
                  ContentProps={{
                    "aria-describedby": "message-id",
                  }}
                  message={
                    <span id="message-id">this.props.snackbar.message</span>
                  }
                />
              </div>
            )}
          </ConnectedRouter>
        </MuiThemeProvider>
      </Provider>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),

  shareDialog: selectors.getShareDialog(state),
  deleteTourtleDialog: selectors.getDeleteTourtleDialog(state),
  reportDialog: selectors.getReportDialog(state),
  addTourtleToCollectionDialog: selectors.getAddTourtleToCollectionDialog(
    state
  ),
  addTourtleToGroupDialog: selectors.getAddTourtleToGroupDialog(state),
  loginDialog: selectors.getLoginDialog(state),
  signInUpDialog: selectors.getSignInUpDialog(state),
  signOutDialog: selectors.getSignOutDialog(state),
  cookieDialog: selectors.getCookieDialog(state),
  filterDialogOpen: selectors.filterDialogOpen(state),
  areStatisticsFresh: selectors.getAreStatisticsFresh(state),
  deleteCollectionDialog: selectors.getDeleteCollectionDialog(state),
  violationState: selectors.getContentModerationDialogState(state),

  collectionItems: selectors.getCurrentUserCollectionItems(state),
  collectionIsFetching: selectors.getCurrentUserCollectionIsFetching(state),
  collectionCToken: selectors.getCurrentUserCollectionCToken(state),
  collectionHasNext: selectors.getCurrentUserCollectionHasNext(state),

  favoritesIsFetching: selectors.getCurrentUserFavoritesIsFetching(state),
  favoritesCToken: selectors.getCurrentUserFavoritesCToken(state),
  favoritesHasNext: selectors.getCurrentUserFavoritesHasNext(state),
  snackbar: selectors.getSnackbar(state),
});

const mapDispatchToProps = {
  loginGetCurrentUser: actions.loginGetCurrentUser,

  getCurrentUserCollectionList: actions.currentUserCollectionListRequest,
  getCurrentUserCollectionListSuccess: actions.currentUserCollectionListSuccess,
  getCurrentUserCollectionListFailure: actions.currentUserCollectionListFailure,

  getCurrentUserFavoriteList: actions.currentUserFavoritesListRequest,
  getCurrentUserFavoriteListSuccess: actions.currentUserFavoritesListSuccess,
  getCurrentUserFavoriteListFailure: actions.currentUserFavoritesListFailure,

  cookieDialogOpening: actions.fireCookieDialogOpening,
  setFirstUse: actions.setFirstUse,
  onClearFreshStatsIndication: actions.adminRefreshStatsRequest,
  openSnackbar: actions.fireSnackbarOpening,
  setGroupTabValue: actions.setGroupTabValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
