import React, { Component } from "react";

// Material UI
import { Tooltip, Chip, Grid } from "@material-ui/core";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import JumpIcon from "@material-ui/icons/LowPriority";
import LeftArrow from "@material-ui/icons/KeyboardArrowLeft";
import RightArrow from "@material-ui/icons/KeyboardArrowRight";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CheckedIcon from "@material-ui/icons/CheckBox";
import UnCheckedIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import ShareIcon from "@material-ui/icons/Share";

import "./tourtle.css";
import { Checkbox } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import moment from "moment";
import { isIOS } from "../../utils/isIOS.js";
import muiIconIOSShare from "../../icons/mui-icon-ios_share.svg";

class StepNavigation extends Component {
  state = {
    dateInfo: "",
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.index !== this.props.index ||
      prevProps.tourtleSteps !== this.props.tourtleSteps
    ) {
      this.handleDate();
    }
  }

  handleCheck = () => {
    this.props.handleSelectTourtle();
  };

  handleDate = () => {
    var txt = "";

    if (
      this.props.index > 0 &&
      this.props.index <= this.props.tourtleSteps.length &&
      this.props.tourtleSteps[this.props.index - 1].type !== "TOURTLE" &&
      this.props.tourtleSteps[this.props.index - 1].updatedAt
    ) {
      if (
        this.props.tourtleSteps[this.props.index - 1].updatedAt ===
        this.props.tourtleSteps[this.props.index - 1].createdAt
      ) {
        txt += "Added: ";
      } else {
        txt += "Updated: ";
      }

      txt += moment(
        this.props.tourtleSteps[this.props.index - 1].updatedAt,
        "x"
      ).format("MMM D, YYYY (HH:mm)");

      if (this.props.tourtleSteps[this.props.index - 1].updatedBy) {
        txt +=
          " by " +
          this.props.tourtleSteps[this.props.index - 1].updatedBy.username;
      }
    } else if (
      this.props.index > 0 &&
      this.props.index <= this.props.tourtleSteps.length &&
      this.props.tourtleSteps[this.props.index - 1].type === "TOURTLE" &&
      this.props.tourtleSteps[this.props.index - 1].addedAt
    ) {
      txt =
        "Added: " +
        moment(
          this.props.tourtleSteps[this.props.index - 1].addedAt,
          "x"
        ).format("MMM D, YYYY (HH:mm)");

      if (this.props.tourtleSteps[this.props.index - 1].addedBy) {
        txt +=
          " by " +
          this.props.tourtleSteps[this.props.index - 1].addedBy.username;
      }
    }

    this.setState({ dateInfo: txt });
  };

  render() {
    const { classes, ...props } = this.props;
    return (
      <div
        className={
          "tourtle-step-navigation" +
          " " +
          (props.type === "step"
            ? "tourtle-step-navigation-shown"
            : "tourtle-step-navigation-hidden")
        }
      >
        <Grid container justify="center">
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={true}>
                <Grid container justify="flex-end" alignItems="center">
                  <Grid item xs="auto">
                    <Tooltip
                      title="Share step"
                      className="tourtle-share-button"
                    >
                      <div>
                        {isIOS() && (
                          <img
                            src={muiIconIOSShare}
                            alt=""
                            width="20"
                            height="20"
                            onClick={props.shareStep}
                          />
                        )}
                        {!isIOS() && <ShareIcon onClick={props.shareStep} />}
                      </div>
                    </Tooltip>
                  </Grid>

                  {props.allowCheckbox && (
                    <Grid item xs="auto">
                      <FormControlLabel
                        className="step__checkbox"
                        control={
                          <Checkbox
                            style={{
                              opacity:
                                this.props.isMine || this.props.isCollaborator
                                  ? 1
                                  : 0.5,
                            }}
                            checked={
                              this.props.tourtleSteps.length > 0 &&
                              this.props.index > 0 &&
                              this.props.tourtleSteps.length >= this.props.index
                                ? this.props.tourtleSteps[this.props.index - 1]
                                    .checkbox
                                : false
                            }
                            onChange={this.handleCheck}
                            onClick={e => e.stopPropagation()}
                            value="performance"
                            uncheckedIcon={
                              <UnCheckedIcon style={{ fill: "#00cc99" }} />
                            }
                            checkedIcon={
                              <CheckedIcon style={{ fill: "#00cc99" }} />
                            }
                            disabled={
                              this.props.isMine || this.props.isCollaborator
                                ? false
                                : true
                            }
                          />
                        }
                      />
                    </Grid>
                  )}

                  {props.tourtleSteps.length > 10 && (
                    <Grid item xs="auto">
                      <Tooltip title="Jump to step">
                        <JumpIcon
                          className="tourtle-jump-button"
                          onClick={props.onJumpToStepClick}
                        />
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid item xs="auto">
                <Grid container alignItems="center">
                  <Grid item xs="auto">
                    <Tooltip title="Previous" aria-label="previous">
                      <LeftArrow
                        className="tourtle-arrow-left"
                        onClick={props.onPrevStepClick}
                      />
                    </Tooltip>
                  </Grid>

                  <Grid item xs="auto">
                    <Tooltip title={this.state.dateInfo} aria-label="date">
                      <Chip
                        color="primary"
                        label={
                          parseInt(props.index, 10) +
                          " / " +
                          props.tourtleSteps.length
                        }
                        className="tourtle-step-navigation-badge"
                      />
                    </Tooltip>
                  </Grid>

                  <Grid item xs="auto">
                    <Tooltip title="Next" aria-label="next">
                      <RightArrow
                        className="tourtle-arrow-right"
                        onClick={props.onNextStepClick}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={true}>
                <Grid container justify="flex-start" alignItems="center">
                  {(props.isMine || props.isCollaborator) &&
                    (props.tourtleSteps.length < this.props.stepLimit ||
                      props.isAdmin) && (
                      <Grid item xs="auto">
                        <Tooltip
                          title="Add new item after this one"
                          aria-label="Add"
                        >
                          <AddCircleIcon
                            className="tourtle-step-navigation-add-icon"
                            onClick={props.onAddStepClick}
                          />
                        </Tooltip>
                      </Grid>
                    )}

                  {(props.isMine || props.isCollaborator) &&
                    props.index > 0 &&
                    props.index <= props.tourtleSteps.length &&
                    props.tourtleSteps[props.index - 1].type !== "TOURTLE" && (
                      <Grid item xs="auto">
                        <Tooltip title="Edit this item" aria-label="Edit">
                          <EditIcon
                            className="tourtle-step-navigation-edit-icon"
                            onClick={props.onEditStepClick}
                          />
                        </Tooltip>
                      </Grid>
                    )}
                  {(props.isMine || props.isCollaborator) &&
                    props.index > 0 &&
                    props.index <= props.tourtleSteps.length &&
                    props.tourtleSteps[props.index - 1].type !== "TOURTLE" && (
                      <Grid item xs="auto">
                        <Tooltip title="Delete this step" aria-label="Delete">
                          <DeleteIcon
                            className="tourtle-step-navigation-delete-icon"
                            onClick={props.onDeleteStepClick}
                          />
                        </Tooltip>
                      </Grid>
                    )}
                  {(props.isMine || props.isCollaborator) &&
                    props.index > 0 &&
                    props.index <= props.tourtleSteps.length &&
                    props.tourtleSteps[props.index - 1].type === "TOURTLE" && (
                      <Grid item xs="auto">
                        <Tooltip
                          title="Remove this linked tourtle step"
                          aria-label="Delete"
                        >
                          <DeleteOutlineIcon
                            className="tourtle-step-navigation-remove-linked-icon"
                            onClick={props.onRemoveLinkedClick}
                          />
                        </Tooltip>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default StepNavigation;
