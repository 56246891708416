import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";

// Material UI
import Chip from "@material-ui/core/Chip";

// Icons
import DoneIcon from "@material-ui/icons/Done";

import CATEGORIES from "../../Utils/constants";

const styles = theme => ({
  chipBlock: {
    marginTop: 0,
    marginBottom: 15,
    flexDirection: "column",
    columnCount: 2,
    columnGap: theme.spacing.unit,
  },
  chipContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 5,
  },
  uncategorized: {
    width: "50%",
    paddingRight: 5,
    marginBottom: 10,
  },
  hiddenChip: {
    opacity: 0,
    pointerEvents: "none",
  },
});

class Interests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: CATEGORIES,
      interests: [],
    };
  }

  componentDidMount = () => {
    const {
      userInfo,
      type,
      dispatchInitialInterests,
      filterSettings,
    } = this.props;
    if (
      (userInfo && type === "preferences") ||
      (type === "filter" &&
        filterSettings &&
        filterSettings.interests.length === 0)
    ) {
      dispatchInitialInterests(userInfo ? userInfo.interests : []);
    }
  };

  handleInterests = event => {
    if (this.props.type !== "filter" && this.props.type !== "search") {
      this.props.dispatchUserInterest(event);
    } else {
      this.props.dispatchFilterInterest(event);
    }
  };

  render() {
    const {
      classes,
      userInterests,
      filterSettings,
      type,
      includeUncategorized,
    } = this.props;
    const { categories } = this.state;
    const selectedCategories =
      type === "preferences" ? userInterests : filterSettings.interests;
    return (
      <div>
        {includeUncategorized && (
          <div
            className={`${classes.chipContainer} ${classes.uncategorized}`}
            key={"uncategorized"}
          >
            <Chip
              key={"uncategorized"}
              value={"uncategorized"}
              label={"Uncategorized"}
              variant={
                selectedCategories.includes("uncategorized") === true
                  ? "default"
                  : "outlined"
              }
              clickable
              onClick={e => this.handleInterests("uncategorized")}
              color="primary"
              icon={
                selectedCategories.includes("uncategorized") === true ? (
                  <DoneIcon />
                ) : null
              }
              deleteIcon={
                selectedCategories.includes("uncategorized") === true ? (
                  <DoneIcon />
                ) : null
              }
            />
          </div>
        )}
        <div className={classes.chipBlock}>
          {Object.entries(categories)
            .filter(([key, value]) => key !== "uncategorized") // shown separately
            .map(([key, value]) => (
              <div className={classes.chipContainer} key={key}>
                <Chip
                  className={classes.chip}
                  key={key}
                  value={key}
                  label={value}
                  variant={
                    selectedCategories.includes(key) === true
                      ? "default"
                      : "outlined"
                  }
                  clickable
                  onClick={e => this.handleInterests(key)}
                  color="primary"
                  icon={
                    selectedCategories.includes(key) === true ? (
                      <DoneIcon />
                    ) : null
                  }
                  deleteIcon={
                    selectedCategories.includes(key) === true ? (
                      <DoneIcon />
                    ) : null
                  }
                />
              </div>
            ))}

          {Object.entries(categories).filter(
            ([key, value]) => key !== "uncategorized"
          ).length %
            2 ===
            1 && (
            <Chip
              className={`${classes.chip} ${classes.hiddenChip}`}
              key={""}
              value={""}
              label={""}
              variant={"outlined"}
              clickable
              color="primary"
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
  userInterests: selectors.getUserInterests(state),
  filterSettings: selectors.getFilterSettings(state),
});

const mapDispatchToProps = {
  dispatchInitialInterests: actions.dispatchInitialInterests,
  dispatchUserInterest: actions.dispatchUserInterest,
  dispatchFilterInterest: actions.dispatchFilterInterest,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Interests))
);
