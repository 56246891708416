import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import StepItem from "./StepItem";
import LinkedTourtleListItem from "../TourtleListScreen/LinkedTourtleListItem";

import { Grid, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

import AddCircleIcon from "@material-ui/icons/AddCircle";

const styles = theme => ({
  addIcon: {
    color: "#00cc99",
    float: "left",
  },
  addText: {
    fontWeight: 700,
  },
  addIconContainer: {
    border: "2px solid #DCDCDC",
    borderRadius: "10px",
  },
  addIconContainerDense: {
    border: "2px solid #DCDCDC",
    borderRadius: "10px",
    paddingTop: "3px",
    paddingBottom: "3px",
  },
});

class StepList extends Component {
  render() {
    const { classes, ...props } = this.props;
    const over10Steps = props.tourtleSteps.length > 10;
    return (
      <div
        className={
          props.tourtleSteps.length < 11 && props.tourtleSteps.length > 0
            ? "tourtle-extra-margin"
            : ""
        }
      >
        <Grid container spacing={8}>
          <Grid item xs={over10Steps ? 8 : 12}>
            {props.tourtleSteps.length > 0 &&
              (props.isMine || props.isCollaborator) && (
                <div className="tourtle-add-step-front">
                  <ListItem
                    button
                    dense={over10Steps}
                    onClick={props.onAddStepToFrontClick}
                    classes={{
                      root: over10Steps
                        ? classes.addIconContainerDense
                        : classes.addIconContainer,
                    }}
                  >
                    <ListItemIcon>
                      <AddCircleIcon className={classes.addIcon} />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.addText }}
                      primary="Add new item"
                    />
                  </ListItem>
                </div>
              )}
          </Grid>
        </Grid>
        <div className="tourtle-steps-container">
          {props.tourtleSteps.map((stepItem, index) =>
            stepItem.type === "TOURTLE" ? (
              <LinkedTourtleListItem
                id={stepItem.id}
                key={stepItem.id}
                tourtle={stepItem}
                favoriteTourtles={props.favoriteTourtles}
                index={index}
                onCoverPage={true}
                handleGotoStep={() => props.handleStepClick(stepItem.id)}
                isMine={props.isMine}
                isCollaborator={props.isCollaborator}
              />
            ) : (
              <StepItem
                key={stepItem.id}
                ref={props.refProp}
                anchor={stepItem.title}
                id={stepItem.id}
                tourtleId={props.tourtleItem.id}
                title={stepItem.title}
                description={stepItem.description}
                image={stepItem.image}
                audio={stepItem.audio ? true : false}
                video={stepItem.video ? true : false}
                index={index}
                isSelected={stepItem.checkbox}
                attachments={stepItem.attachments}
                isMine={props.isMine}
                isCollaborator={props.isCollaborator}
                handleGotoStep={() => props.handleStepClick(stepItem.id)}
              />
            )
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(StepList);
